// ResponsiveScrollContainer.tsx
import React from 'react';
import { Box } from '@mui/system';

interface ResponsiveScrollContainerProps {
    children: React.ReactNode;
}



const ResponsiveScrollContainer: React.FC<ResponsiveScrollContainerProps> = ({ children }) => {
    return <Box className="overflow-y-scroll h-full"
        style={{ maxHeight: "calc(70vh - 1rem)" }}>{children}</Box>;
};

export default ResponsiveScrollContainer;
