// ConsultantCard.tsx
import React, { useEffect, useState } from "react";
import Card from "./Card";
import {
  ConsultantDetails,
  ConsultantMeeting,
} from "../../types/consultantTypes";
import { ScoreCircle } from "./score/ScoreCircle";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { extractRoleFromJWT } from "../../utils/JwtExtraction";
import ConsultantProfileProgress from "../Admin/ConsultantProfileProgress";

interface ConsultantCardProps {
  consultant: ConsultantDetails | ConsultantMeeting;
  onClick: () => any;
  isActive: boolean;
}

const ConsultantCard: React.FC<ConsultantCardProps> = ({
  consultant,
  onClick,
  isActive,
}) => {
  const handleClick = () => {
    onClick();
  };

  const token = useSelector((state: RootState) => state.auth.accessToken);
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    const role = extractRoleFromJWT(token);
    setRole(role);
  }, []);
  
  return (
    <Card
      key={consultant.consultantId}
      consultant={consultant}
      onClick={handleClick}
      isActive={isActive}
    >
  <div className="grid grid-flow-col items-center absolute bottom-0" style={{ paddingBottom: '10px', columnGap: '35px' }}>
        {role === "Customer" && "interview" in consultant && consultant.interview.score && (
          <div className="flex justify-end ">
            <ScoreCircle
              textSize="text-xl "
              customSize="w-9 h-9"
              number={consultant.interview.score}
              disabled
            />
          </div>
        )}

        {role === "Admin" &&
          "customers" in consultant &&
          "avgScore" in consultant &&
          consultant.avgScore && (
            <div className="flex justify-end">
              <ScoreCircle
                number={consultant.avgScore}
                disabled
                floatNum
                customStyle="rounded-[3px] font-semibold"
                customSize="w-[45px] h-[25px] pb-6"
                textSize="text-4"
              />
            </div>
          )}

        {role === "Admin" && "progress" in consultant && (
          <div className="flex justify-end">
            <ConsultantProfileProgress progress={consultant.progress || 0} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ConsultantCard;
