import React, { useRef, useEffect, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Button } from "../../common/Button";
import { exportToExcel } from "../../../utils/ExportScoreToXLSX";

export interface ScoreTableProps {
  clients: { id: number; name: string }[];
  consultants: {
    id: number;
    name: string;
    specialization: string;
    interviews: { customerId: number; score: number }[];
    averageScore?: number;
  }[];
}
enum NumberToText {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

const ScoreTable: React.FC<ScoreTableProps> = ({ clients, consultants }) => {
  const [consultantWithAverageScore, setConsultantWithAverageScore] =
    useState<any>([]);

  const columnValues = ["Score", "1", "2", "3", "4", "5"];
  const tableRefs = useRef<Array<HTMLTableElement | null>>(
    Array(consultants.length).fill(null)
  );

  useEffect(() => {
    tableRefs.current = tableRefs.current.map(
      (_, index) => tableRefs.current[index] || null
    );
  }, [consultantWithAverageScore]);

  useEffect(() => {
    const consultantsWithAverageScore = consultants.map((consultant) => {
      const averageScore = calculateAverageScore(consultant);
      return { ...consultant, averageScore };
    });
    setConsultantWithAverageScore(consultantsWithAverageScore);
  }, [consultants]);

  const countScores = (candidate: any, score: number) => {
    return candidate.interviews.filter(
      (interview: any) => interview.score === score
    ).length;
  };

  const calculateAverageScore = (candidate: any) => {
    const validInterviews = candidate.interviews.filter(
      (interview: any) => interview.score !== null
    );

    const totalScore = validInterviews.reduce(
      (sum: number, interview: any) => sum + interview.score,
      0
    );

    const numberOfClients = validInterviews.length;
    const averageScore =
      numberOfClients === 0 ? 0 : totalScore / numberOfClients;

    return parseFloat(averageScore.toFixed(1));
  };

  // Group consultants by specialization
  const consultantsBySpecialization: Record<string, any[]> = {};
  consultantWithAverageScore.forEach((consultant: any) => {
    if (!consultant.specialization) {
      return;
    }
    const consultantSpecialization = consultant.specialization.toLowerCase();
    if (!consultantsBySpecialization[consultantSpecialization]) {
      consultantsBySpecialization[consultantSpecialization] = [];
    }
    consultantsBySpecialization[consultantSpecialization].push(consultant);
  });

  if (
    clients.length === 0 ||
    consultantWithAverageScore.length === 0 ||
    !consultantsBySpecialization
  ) {
    return <div>Loading...</div>;
  }
  return (
    <div className="overflow-auto ">
      {Object.entries(consultantsBySpecialization).map(
        ([specialization, consultantsInSpecialization], index) => (
          <div key={specialization} className="mb-12">
            <div className="overflow-auto rounded-t-md mt-4 max-h-96">
              <table
                ref={(el) => (tableRefs.current[index] = el)}
                className="table-auto w-full border-collapse text-center border mb-3"
              >
                <thead>
                  <tr className="bg-brandBlueDark text-white">
                    <th
                      className="sticky left-0 top-0 w-10 min-w-10 bg-brandBlueDark z-10 font-normal text-base"
                      style={{ minWidth: "10rem" }}
                    >
                      {specialization}
                    </th>
                    {clients.map((client) => (
                      <th
                        key={client.id}
                        className="font-normal text-base sticky top-0"
                      >
                        {client.name}
                      </th>
                    ))}
                    {columnValues.map((col, columnIndex) => (
                      <th
                        key={columnIndex}
                        className="p-5 font-normal text-base sticky top-0"
                      >
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {consultantsInSpecialization.map((consultant, rowIndex) => (
                    <tr key={consultant.id}>
                      <td
                        className={`sticky left-0 font-semibold  ${
                          rowIndex % 2 === 0
                            ? "bg-brandGray-light"
                            : "bg-brandBG"
                        }`}
                      >
                        {consultant.name}
                        {/* Border after name somehow normal border would not work with the scrolling */}
                        <div className="absolute top-0 right-0 h-full border-r border-brandGray-dark"></div>
                      </td>
                      {clients.map((client, key) => {
                        const interview = consultant.interviews.find(
                          (interview: any) => interview.customerId === client.id
                        );
                        const scoreToDisplay = interview?.score
                          ? interview.score
                          : "-";

                        return (
                          <td key={key} className="">
                            <div
                              className={`bg-score-${
                                NumberToText[scoreToDisplay]
                              } w-fit px-3 rounded-md mx-auto ${
                                scoreToDisplay === 1 || scoreToDisplay === 5
                                  ? "text-white"
                                  : ""
                              }`}
                            >
                              {scoreToDisplay}
                            </div>
                          </td>
                        );
                      })}
                      <td>
                        <div
                          className={`bg-score-${
                            NumberToText[Math.round(consultant.averageScore)]
                          } w-fit px-3 rounded-md  mx-auto ${
                            Math.round(consultant.averageScore) === 1 ||
                            Math.round(consultant.averageScore) === 5
                              ? "text-white"
                              : ""
                          } `}
                        >
                          {consultant.averageScore}
                        </div>
                      </td>
                      {columnValues.slice(1).map((col, columnIndex) => {
                        return (
                          <td key={columnIndex + 1}>
                            {countScores(consultant, columnIndex + 1)}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
      <div className="w-fill flex justify-end mb-12">
        <div className="w-[162px]">
          <Button
            text={`Export excel`}
            onClick={() => {
              exportToExcel({
                clients: clients,
                consultants: consultantWithAverageScore,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ScoreTable;
