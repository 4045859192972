import { combineReducers } from "redux";
import authReducer from "../slices/authSlice";
import selectedCustomerReducer from "../slices/SelectedCustomerSlice";
import selectedConsultantReducer from "../slices/SelectedConsulentSlice";
import selectedAdminReducer from "../slices/SelectedAdminSlice";
/**
 * Root Reducer
 *
 * Combines individual reducers into a single root reducer.
 *
 * @function
 * @type {Reducer<CombinedState<RootState>>}
 * @example
 * import { createStore } from 'redux';
 * import rootReducer from './path-to-your-rootReducer';
 * const store = createStore(rootReducer);
 */
const rootReducer = combineReducers({
  auth: authReducer,
  selectedCustomer: selectedCustomerReducer,
  selectedConsultant: selectedConsultantReducer,
  selectedAdmin: selectedAdminReducer
});

/**
 * Root State Type
 *
 * Represents the root state of the Redux store.
 *
 * @type {RootState}
 */
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
