import * as ExcelJS from "exceljs";
import { ScoreTableProps } from "../components/Admin/schedule/ScoreTable";

function generateExcelData(data: ScoreTableProps): any[][] {
  const uniqueTechnologies = Array.from(
    new Set(data.consultants.map((consultant) => consultant.specialization))
  );
  const rows: any[][] = [];

  uniqueTechnologies.forEach((technology) => {
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE6E6E6" },
      },
    };
    const headerRow = [
      {
        value: technology,
        style: headerStyle,
      },
      ...data.clients.map((customer) => ({
        value: customer.name,
        style: headerStyle,
      })),
      {
        value: "Average",
        style: headerStyle,
      },
      {
        value: "1",
        style: headerStyle,
      },
      {
        value: "2",
        style: headerStyle,
      },
      {
        value: "3",
        style: headerStyle,
      },
      {
        value: "4",
        style: headerStyle,
      },
      {
        value: "5",
        style: headerStyle,
      },
    ];
    rows.push(headerRow);

    data.consultants
      .filter((consultant) => consultant.specialization === technology)
      .forEach((consultant) => {
        const consultantRow: any = [`${consultant.name}`];

        const scoreCounter: { [key: number]: number } = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
        };

        data.clients.forEach((client) => {
          const interview = consultant.interviews.find(
            (interview) => interview.customerId === client.id
          );
          const score = interview?.score ? interview.score : null;

          if (score !== null) {
            scoreCounter[score] += 1;
            const color = scoreToColor(score);
            const cell = {
              value: score,
              style: {
                fill: {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: color },
                },
              },
            };

            consultantRow.push(cell);
          } else {
            consultantRow.push("-");
          }
        });

        const avgScore = consultant.averageScore;
        if (avgScore) {
          scoreCounter[avgScore] += 1;
          const color = scoreToColor(avgScore);
          const cell = {
            value: avgScore,
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: color },
              },
            },
          };

          consultantRow.push(cell);
        } else {
          consultantRow.push("-");
        }

        consultantRow.push(scoreCounter[1]);
        consultantRow.push(scoreCounter[2]);
        consultantRow.push(scoreCounter[3]);
        consultantRow.push(scoreCounter[4]);
        consultantRow.push(scoreCounter[5]);

        rows.push(consultantRow);
      });

    rows.push([]);
  });

  return rows;
}

export function exportToExcel(data: ScoreTableProps): void {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("ConsultantData");
  const rows = generateExcelData(data);

  rows.forEach((row, rowIndex) => {
    row.forEach((cell, columnIndex) => {
      if (typeof cell === "object" && cell.style) {
        const excelCell = worksheet.getCell(rowIndex + 1, columnIndex + 1);
        excelCell.value = cell.value;
        excelCell.style = cell.style;
      } else {
        worksheet.getCell(rowIndex + 1, columnIndex + 1).value = cell;
      }
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "ConsultantData.xlsx";
    link.click();
  });
}

function scoreToColor(score: number): string {
  const colorMapping: { [key: number]: string } = {
    1: "FF8B2C2C",
    2: "FFFCC3C3",
    3: "FFFCFAC3",
    4: "FFC7FCC3",
    5: "FF6E8E81",
  };
  console.log(colorMapping[score]);

  return colorMapping[score] || "FFFFFFFF";
}
