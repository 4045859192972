import React from "react";
import { ConsultantMeeting } from "../../types/consultantTypes";
import { IoIosArrowForward } from "react-icons/io";
interface InterviewTileProps {
  consultant: ConsultantMeeting;
  onClick: () => void;
  isActive: boolean;
}
const InterviewTile: React.FC<InterviewTileProps & {}> = ({
  consultant,
  onClick,
  isActive,
}) => {
  const hasScore = consultant.interview.score;
  // Get the start time as a Date object
  const startTime = new Date(consultant.interview.time);
  // Calculate the end time by adding 10 minutes to the start time
  const endTime = new Date(startTime.getTime() + 10 * 60000);
  return (
    <button
      className={`w-full grid grid-cols-7 items-center justify-center h-fit pr-3 rounded-md relative transition-colors duration-700 leading-tight ease-in overflow-hidden shadow-lg 
        ${hasScore && "bg-[#1E3046] text-white"}
        ${isActive && "bg-[#E77C22] text-white"}
        ${!isActive && !hasScore && "bg-white text-black"}
      `}
      onClick={onClick}
    >
      <div
        className={`h-full w-1/2 transition-all duration-700 ${
          !isActive && !hasScore ? "bg-[#E77C22]" : "bg-transparent"
        }`}
      ></div>

      {/* The content of the button */}
      <div
        className={`translate-y-0 h-full col-span-5 justify-center p-4 ${
          isActive || hasScore ? "text-white" : "text-black"
        }`}
      >
        <h3>{consultant.consultantName}</h3>
        <small>
          {startTime.toLocaleTimeString("no-NO", {
            hour: "2-digit",
            minute: "2-digit",
          })}
          -
        </small>
        <small>
          {endTime.toLocaleTimeString("no-NO", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </small>
      </div>

      {/* Arrow icon */}
      <div
        className={`col-span-1 justify-start h-full flex items-center w-full`}
      >
        <IoIosArrowForward
          className={`h-full w-full max-h-10 ${
            isActive || hasScore ? "text-white" : "text-black"
          } h-6 w-4 ${isActive && "rotate-180"} h-6 w-4`}
        />
      </div>
    </button>
  );
};
export default InterviewTile;
