import * as React from "react";
import ScheduleContainer from "../../components/Admin/schedule/ScheduleContainer";

const SchedulePage: React.FC = () => {
  return (
    <div className="px-10 pt-10 h-full w-full">
      <ScheduleContainer />
    </div>
  );
};

export default SchedulePage;
