import { ConsultantDetails } from "../../types/consultantTypes";
import ConsultantScoreCard from "./ConsultantScoreCard";

interface ConsultantScoreCardGroupProps {
  consultant: ConsultantDetails;
}

const ConsultantScoreCardGroup: React.FC<ConsultantScoreCardGroupProps> = ({
  consultant,
}) => {
  const sortedCustomers = consultant.customers?.sort((a, b) =>
    a.customerScore > b.customerScore ? -1 : 1
  );

  const customersWithScore = sortedCustomers?.filter(
    (c) => c.customerScore !== null
  );
  const customersWithoutScore = sortedCustomers?.filter(
    (c) => c.customerScore === null
  );

  return (
    <div className=" space-y-16">
      {customersWithScore && customersWithScore.length > 0 && (
        <div className="flex flex-wrap items-start sm:gap-4 gap-5">
          {customersWithScore.map((customer, index) => (
            <ConsultantScoreCard key={index} customer={customer} />
          ))}
        </div>
      )}

      {customersWithoutScore && customersWithoutScore.length > 0 && (
        <div className="flex flex-wrap items-start sm:gap-4 gap-5 ">
          {customersWithoutScore.map((customer, index) => (
            <ConsultantScoreCard key={index} customer={customer} />
          ))}
        </div>
      )}
    </div>
  );
};
export default ConsultantScoreCardGroup;
