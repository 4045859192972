import React from 'react';
import { FaEdit, FaTrash } from "react-icons/fa";
import { Admin } from '../../../types/types';
import { sortByKey } from '../../../utils/SortingUtils';


interface AdminTableProps {
  admins: Admin[];
  onEdit: (admin: Admin) => void;
  onDelete: (admin: Admin) => void;
  editIcon: any;
  deleteIcon: any;
}


const AdminTable: React.FC<AdminTableProps> = ({ admins, onEdit, onDelete }) => {
  const sortedAdmins = sortByKey(admins, 'adminName', "asc");

  if (!Array.isArray(admins)) {
    return null; 
  }

  return (
    <div className="min-w-full border border-gray-300 overflow-hidden">
      <div className="max-h-[430px] overflow-y-auto">
        <table className="min-w-full">
          <thead className="bg-brandBlueDark text-white text-left sticky top-0 z-10">
            <tr className='normal-case'>
              <th className="px-4 py-3 text-left">Navn</th>
              <th className="px-4 py-3 text-left">Mail</th>
              <th className="px-4 py-3 text-center">Rediger</th>
              <th className="px-4 py-3 text-center">Slett</th>
            </tr>
          </thead>
          <tbody>
            {sortedAdmins.map((admin: Admin) => (
              <tr key={admin.adminId} className="odd:bg-gray-200">
                <td className="px-4 py-2 text-left">{admin.adminName}</td>
                <td className="px-4 py-2 text-left">{admin.adminEmail}</td>
                <td className="px-4 py-2 text-center">
                  <button onClick={() => onEdit(admin)}>
                    <FaEdit />
                  </button>
                </td>
                <td className="px-4 py-2 text-center text-[#D04646]">
                  <button onClick={() => onDelete(admin)}>
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminTable;