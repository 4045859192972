
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { ConsultantManageUsers } from "../../types/types";

interface SelectedConsultantState {
  selectedConsultant: ConsultantManageUsers | null;
}

const initialState: SelectedConsultantState = {
  selectedConsultant: null,
};

const selectedConsultantSlice = createSlice({
  name: "selectedConsultant",
  initialState,
  reducers: {
    selectConsultant: (state, action: PayloadAction<ConsultantManageUsers | null>) => {
      state.selectedConsultant = action.payload;
    },
  },
});

export const { selectConsultant } = selectedConsultantSlice.actions;

// Selectors
export const selectSelectedConsultant = (state: RootState) =>
  state.selectedConsultant.selectedConsultant;

export default selectedConsultantSlice.reducer;
