import React, { useEffect, useState } from 'react';
import { PropsAdminScheduleToSelectCustomerConsultants, SelectCustomerConsultantUnit as Unit } from '../../../types/types';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import BasicTooltip from '../../common/ToolTip';
interface AllocationRowProps {
  category: string;
  consultants: Unit[];
  customers: PropsAdminScheduleToSelectCustomerConsultants['Customers'];
  collapsed: Record<string, boolean>;
  onToggleCategory: (category: string) => void;
  onInterviewUpdate: (customerId: number, consultantId: number, checked: boolean) => void;
  allInterviews: { Customer: number; Candidates: number[] }[];
}

const AllocationRow: React.FC<AllocationRowProps> = ({
  category,
  consultants,
  customers,
  collapsed,
  onToggleCategory,
  onInterviewUpdate,
  allInterviews,

}) => {

  const [isChecked, setIsChecked] = useState<Map<number, Map<number, boolean>>>(
    new Map()
  );


  const updateCheckedValues = (checked: boolean, customerId: number, consultantId: number) => {
    setIsChecked((prev) => {
      const newIsChecked = new Map(prev);
      const innerMap = new Map(newIsChecked.get(customerId));
      innerMap.set(consultantId, checked);
      newIsChecked.set(customerId, innerMap);
      onInterviewUpdate(customerId, consultantId, checked)
      return newIsChecked;
    });
  };

  useEffect(() => {
    setIsChecked(new Map(customers.map((customer) => [customer.id, new Map(consultants.map((consultant) => [consultant.id, false]))])))
    let isEmpty : boolean = true;
    allInterviews.forEach((interview) => {
      if (interview.Candidates.length > 0) {
        isEmpty = false;
      }
    } 
    )
    if (!isEmpty) {
      allInterviews.forEach((interview) => {
        interview.Candidates.forEach((candidate) => {
          updateCheckedValues(true, interview.Customer, candidate)
        })
      })
    } else {

      customers.forEach((customer) => {
        if (customer.speciality === category) {
          consultants.forEach((consultant) => {
            updateCheckedValues(true, customer.id, consultant.id)
          })
        }
        
      });
    }
    }, [customers]);

  return (
    <>
      <tr className='p-3 w-full space-x-6 odd:bg-[#cecece] even:bg-[#cecece] rounded-md '>
        <td className='flex flex-row p-3 space-x-2 mr-6 w-full sticky left-0 bg-inherit rounded-md  '>
          
          <div onClick={() => onToggleCategory(category)}>
            {collapsed[category] 
            ? (<IoIosArrowDown className='w-6 h-6 cursor-pointer '></IoIosArrowDown>)
            : (<IoIosArrowUp className='w-6 h-6 cursor-pointer'></IoIosArrowUp>
            )} 
          </div>
            <strong className='font-montserrat text-xl text-brandBlueDark'>{category}</strong>
        </td >
        {
          customers.map((customer) => (
            <td key={customer.id}>
             <BasicTooltip text='Velg alle'>
                <input
                  type='checkbox'
                  checked={Array.from(isChecked.get(customer.id)?.values() || []).every(
                    (value) => {
                      return value
                    }
                  )}
                  onChange={(e) => {
                    consultants.forEach((consultant) => {
                      updateCheckedValues(e.target.checked, customer.id, consultant.id)
                    });
                  }}
                  className='h-4 w-full px-6 py-2 text-center cursor-pointer accent-[#2A8C08]'
                />
             </BasicTooltip>
            </td>
          ))
        }
      </tr >
      {!collapsed[category] &&
        consultants.map((consultant) => (
          <tr className={`pl-6 py-2 font-medium text-gray-900 whitespace-nowrap ${
            !collapsed[category] ? 'slideDownStagger' : ''
          } rounded-l-md sticky left-0 bg-inherit`} key={consultant.id} >

            <td className='pl-6 py-2 font-medium text-gray-900 whitespace-nowrap rounded-l-md sticky left-0 bg-inherit '>
              <p className='font-lato text-xs'>{consultant.name}</p>
            </td>
            {customers.map((customer) => {
              const checked = isChecked.get(customer.id)?.get(consultant.id) || false;
              return (
                <td key={customer.id} className='px-6 text-center last:rounded-r-md'>
                  <input
                    type='checkbox'
                    checked={checked}
                    onChange={(e) => updateCheckedValues(e.target.checked, customer.id, consultant.id)}
                    className='px-6 py-2 text-center w-full cursor-pointer accent-[#2A8C08]'
                  />
                </td>
              );
            })}
          </tr>
        ))}
    </>
  );
};

export default AllocationRow;
