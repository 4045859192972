import React from "react";
import {
  ScheduleInterview as Interview,
  ScheduleCustomer as Customer,
  Timeslot,
} from "../../../types/types";
import { ScheduleInterview } from "./ScheduleInterview";
import { ScheduleEmpty } from "./ScheduleEmpty";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

interface ComponentProps {
  Customers: Customer[];
  Interviews: Interview[];
  timeSlot: Timeslot;
  toogledConsultant: number;
  setToogledConsultant: Function;
  handleOnDrop: Function;
  handleDragOver: Function;
  openAddNewInterviewFunction: Function;
  handleSelectInterview: (interview: Interview | null) => void;
}
function handleDragOver(
  e: React.DragEvent,
  consultant_id: number,
  interview: Interview | null
) {
  e.preventDefault();

  // Check if there is a dragged interview
  if (interview && consultant_id === interview.consultantId) {
    e.dataTransfer.dropEffect = "move";
  }
}
export const ScheduleRow: React.FC<ComponentProps> = ({
  Customers,
  Interviews,
  timeSlot,
  toogledConsultant,
  setToogledConsultant,
  handleOnDrop,
  handleDragOver,
  openAddNewInterviewFunction,
  handleSelectInterview,
}) => {
  return (
    <tr>
      <td className="px-2 border-r border-brandBlueDark sticky left-0 bg-inherit z-30">
        {timeSlot.from}
      </td>
      {Customers.map((cust) => {
        const interview: Interview | null =
          Interviews.find((int) => int.customerId === cust.customerId) || null;

        return (
          <td
            key={cust.customerId}
            className="content-center"
            onDrop={(e) => handleOnDrop(e, timeSlot, cust.customerId)}
            onDragOver={(e) => handleDragOver(e, cust.customerId, interview)}
            onClick={() => handleSelectInterview(interview)}
          >
            {interview ? (
              <ScheduleInterview
                interview={interview}
                toogledConsultant={toogledConsultant}
                setToogledConsultant={setToogledConsultant}
              />
            ) : (
              <ScheduleEmpty
                onClick={() =>
                  openAddNewInterviewFunction(cust.customerId, timeSlot.from)
                }
                customerId={cust.customerId}
                time={timeSlot.from}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
};
