import React, { useState, useEffect } from "react";
import CustomInput from "../../common/InputField";

interface AddConsultantFormProps {
  onAddConsultant: (newConsultant: {
    consultantName: string;
    consultantEmail: string;
    specialization: string;
  }) => Promise<void>;
  onSave?: () => void;
}

const AddConsultantForm: React.FC<AddConsultantFormProps > = ({
  onAddConsultant,
  onSave,
}) => {
  const [consultantName, setConsultantName] = useState<string>("");
  const [consultantEmail, setConsultantEmail] = useState<string>("");
  const [specialization, setSpecialization] = useState<string>("");
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [specializationError, setSpecializationError] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);

  const handleSpecializationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpecialization(event.target.value);
  };

  
  const handleAddConsultant = async () => {
    try {
      if (requiredFieldsFilled) {
        const newConsultantData = {
          consultantName,
          consultantEmail,
          specialization,
        };
        await onAddConsultant(newConsultantData);
        onSave?.();
      }else if(consultantName === "") setNameError(true)
      if(consultantEmail === "") setEmailError(true)
      if(specialization === "") setSpecializationError(true)
    } catch (error) {
      setError("An error occurred while adding the consultant.");
    }
  }

  useEffect(() => {
    if (consultantName && consultantEmail && specialization) {
      setRequiredFieldsFilled(true)
      setNameError(false)
      setEmailError(false)
      setSpecializationError(false)
    } else(setRequiredFieldsFilled(false))
  }, [consultantName, consultantEmail, specialization]);

  return (
  <div className="w-96 h-auto bg-white p-4 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-6 flex justify-start">
        Legg til ny konsulent
      </h2>
        <div className="mb-4">
        <CustomInput
            value={consultantName}
            textRed={nameError}
            onChange={(e) => setConsultantName(e.target.value)}
          label="Navn"
        />
           <div className={`text-xs text-left ${(nameError && consultantName === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
     <br /> 
    
     </div>
     <div className="mb-4">
        <CustomInput
            value={consultantEmail}
            textRed={emailError}
            onChange={(e) => setConsultantEmail(e.target.value)}
          label="E-post"
        />
           <div className={`text-xs text-left ${(emailError && consultantEmail === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
     </div>

     
    <div className="mb-4">
    <label htmlFor="specialization" className={`text-left block mb-2 text-sm ${(specializationError && specialization === "") ? "text-red-700" : "text-black"}`}>Spesialisering:</label>
  <div className="flex items-center">
    <div className="flex items-center mr-4">
      <input
        type="radio"
        id="java"
        name="specialization"
        value="Java"
        checked={specialization === "Java"}
        onChange={handleSpecializationChange}
      />
      <label htmlFor="java" className="ml-2 text-sm">Java</label>
    </div>
    <div className="flex items-center">
      <input
        type="radio"
        id="dotnet"
        name="specialization"
        value=".NET"
        checked={specialization === ".NET"}
        onChange={handleSpecializationChange}
        className="ml-4 form-radio text-gray-400"
      />
      <label htmlFor="dotnet" className="ml-2 text-sm">.NET</label>
    </div>
  </div>
  <div className={`text-xs text-left ${(specializationError && specialization === "") ? "text-red-700" : "text-black"}`}>*Nødvendig</div>
</div>


    
        {/* Add similar structure for other input fields */}
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="flex justify-end my-9">
          <button onClick={handleAddConsultant}           
          className={`${requiredFieldsFilled ? "bg-brandBlueDark border-brandBlueDark hover:bg-brandWhite hover:text-brandBlueDark cursor-pointer" : "bg-[#696969] cursor-not-allowed"}  text-white border  w-24 px-4 py-2 rounded `}
          >
          Lagre
        </button>
      </div>
    </div>

  );
};

export default AddConsultantForm;
