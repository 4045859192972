import axios from "axios";

const authApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/auth`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const generateOTP = async (email: string) => {
  try {
    const response = await authApi.post("/generate-otp", { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOTP = async (email: string, otp: string) => {
  try {
    const response = await authApi.post("/verify-otp", {
      email,
      otp,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

  // Fetches SAS token url and sets the blobUrl state
 export const fetchSasTokenWithUrl = async (blobUrl: string) => {
    try {

      //const url = new URL(blobUrl);

      //const fileName = url.pathname.split('/').pop();

      const SasBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${SasBaseUrl}/Blob/${blobUrl}`);
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const sasTokenUrl = await response.json();
      return sasTokenUrl;
    } catch (error) {
      console.error('Error fetching SAS token URL:', error);
    }
  };