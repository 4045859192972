import * as React from "react";
import { useState, SetStateAction, Dispatch } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { customTheme } from "../../utils/customDrawer.js";
import {
  Divider,
  Radio,
  Drawer,
  FormControl,
  Button,
  RadioGroup,
  Textarea,
  Typography,
} from "@mui/joy";
import FileUpload from "./FileUpload";
import FileDropZoneCv from "./FileDropZoneCv";
import FileDropZoneImage from "./FileDropZoneImage";
import InputField from "../common/InputField";
import { ConsultantDetails } from "../../types/consultantTypes";
import { updateConsultantProfile } from "../../api/consultantApi";
import { Button as CommonButton } from "../common/Button";
import Modal from "../common/Modal";
import DegreeSelector from "./DegreeSelector";
import { Console } from "console";
import SnackBarCustom from "../common/SnackBarCustom";

interface EditProfileDrawerProps {
  consultant: ConsultantDetails;
  setConsultant: Dispatch<SetStateAction<ConsultantDetails>>;
}

const EditProfileDrawer: React.FC<EditProfileDrawerProps> = ({
  consultant,
  setConsultant,
}) => {
  const [consultantUpdates, setConsultantUpdates] = useState(consultant);
  const [open, setOpen] = React.useState(false);
  const [uploadProgressCv, setUploadProgressCv] = useState<number>(0);
  const [fileName, setFileName] = useState<string>("");
  const [uploadProgressProfileImage, setUploadProgressProfileImage] =
    useState<number>(0);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  React.useEffect(() => {
    setConsultantUpdates(consultant);
  }, [consultant]);

  const setConsultantProfileImage = (file: File | null) => {
    setUploadProgressProfileImage(0);
    setConsultantUpdates((prevConsultant) => ({
      ...prevConsultant,
      imageFile: file,
    }));
  };

  const setConsultantCv = (file: File | null) => {
    setUploadProgressCv(0);
    setConsultantUpdates((prevConsultant) => ({
      ...prevConsultant,
      cvFile: file,
    }));
  };

  const handleDegreeChange = (newValue: string | null) => {

    setConsultantUpdates((prevConsultant) => ({
      ...prevConsultant,
      degreeLevel: newValue,
    }));
  };

  const handleEducationNameChange = (newValue: string) => {
    setConsultantUpdates((prevConsultant) => ({
      ...prevConsultant,
      educationName: newValue,
    }));
  };

  const toggleDrawer =
    (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (checkUnsavedChanges()) {
        setWarningMessage("Det er ulagrede endringer. Vennligst lagre eller avbryt");
        console.log("Unsaved changes: ", showUnsavedChangesModal);
      } else {
        setOpen(inOpen);
      }
    };

  const checkUnsavedChanges = () => {
    // Compare the entire objects to check for changes
    console.log("Consultant: ", consultant);
    console.log("UpdatedConsultant: ", consultantUpdates);

    return JSON.stringify(consultant) !== JSON.stringify(consultantUpdates);
  };

  const handleChanges = async () => {
    try {
      consultantUpdates.education =
        consultantUpdates.degreeLevel + " " + consultantUpdates.educationName;
      const formData = new FormData();
      formData.append("consultantId", String(consultantUpdates.consultantId));
      formData.append("consultantName", consultantUpdates.consultantName || "");
      formData.append(
        "consultantEmail",
        consultantUpdates.consultantEmail || ""
      );
      formData.append("specialization", consultantUpdates.specialization || "");
      formData.append("description", consultantUpdates.description || "");

      if (!!consultantUpdates.cvFile) {
        console.log(
          "does consultant cvfile exist: " + !!consultantUpdates.cvFile
        );
        formData.append("cv", consultantUpdates.cvFile);
      }

      if (!!consultantUpdates.imageFile) {
        console.log(
          "does consultant imageFile exist: " + !!consultantUpdates.imageFile
        );
        formData.append("image", consultantUpdates.imageFile);
      }

      formData.append("education", consultantUpdates.education || "");
      console.log("education appended: " + consultantUpdates.education);
      formData.append("FunFact", consultantUpdates.funFact || "");

      await updateConsultantProfile(
        formData,
        setUploadProgressCv,
        setUploadProgressProfileImage
      );
      setConsultant(consultantUpdates);
    } catch (error) {
      console.error("Error updating existing consultant:", error);
    }
    setConfirmationMessage("Lagring vellykket.");
    setOpen(false);
  };

  return (
    <div className="flex">
      {confirmationMessage && (
        <SnackBarCustom
          color="success"
          message={confirmationMessage}
          align="bottom-center"
          onClose={() => setConfirmationMessage("")}
        />
      )}
      {errorMessage && (
        <SnackBarCustom
          color="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}

      <button
        className="uppercase font-semibold text-white text-xl border-2 rounded-md px-4 py-2 mt-4 w-full bg-brandBlueLight hover:shadow-md hover:border-brandBlueDark hover:bg-white hover:text-brandBlueDark"
        type="button"
        onClick={toggleDrawer(true)}
      >
        Rediger
      </button>
      <CssVarsProvider theme={customTheme}>
        <Drawer size="md" open={open} onClose={toggleDrawer(false)}>
          {warningMessage && (
            <div className="">
              <SnackBarCustom
                color="warning"
                message={warningMessage}
                onClose={() => setWarningMessage("")}
              />
            </div>
          )}
          <div className="flex justify-items-center p-6 flex-col space-y-2">
            <h2 className="font-bold">Rediger din brukerprofil</h2>
            <div className="flex justify-self-start flex-col">
              <div className="mb-2 mt-2">
                <InputField
                  label="Navn"
                  value={consultantUpdates.consultantName ?? ""}
                  onChange={(e) =>
                    setConsultantUpdates((prevConsultant) => ({
                      ...prevConsultant,
                      consultantName: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex justify-self-start flex-col">
              <Typography level="title-sm" noWrap>
                Spesialisering
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    className="space-x-4"
                    defaultValue="outlined"
                    name="radio-buttons-group"
                    orientation="horizontal"
                    value={consultantUpdates.specialization}
                    onChange={(e) =>
                      setConsultantUpdates((prevConsultant) => ({
                        ...prevConsultant,
                        specialization: e.target.value,
                      }))
                    }
                  >
                    <div className="flex items-center">
                      <span className="mr-2 text-xs">Java</span>
                      <Radio
                        size="sm"
                        value="Java"
                        variant="outlined"
                        checked={consultantUpdates.specialization === "Java"}
                      />
                    </div>
                    <div className="flex items-center">
                      <span className="mr-2 text-xs">.NET</span>
                      <Radio
                        size="sm"
                        value=".NET"
                        variant="outlined"
                        checked={consultantUpdates.specialization === ".NET"}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="flex justify-self-start flex-col">
              <div>
                <InputField
                  label="E-post"
                  type="email"
                  value={consultantUpdates.consultantEmail ?? ""}
                  onChange={(e) =>
                    setConsultantUpdates((prevConsultant) => ({
                      ...prevConsultant,
                      consultantEmail: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex justify-self-start flex-col space-y-2">
              <Typography level="title-sm" noWrap marginTop={2}>
                Last opp CV
              </Typography>
              <Divider />
              <FileDropZoneCv setCv={setConsultantCv} />
              {(!!consultantUpdates.cvUrl &&
                consultantUpdates.cvFile === null) ||
              consultantUpdates.cvFile === undefined ? (
                <div>
                  <FileUpload
                    fileUrlReceived={!!consultant.cvUrl}
                    className="space-y-2"
                    fileName={
                      consultantUpdates.cvFileName
                        ? consultantUpdates.cvFileName
                        : consultantUpdates.cvUrl
                        ? consultantUpdates.cvUrl
                        : "no file exists"
                    }
                  />
                </div>
              ) : (
                <div>
                  {consultantUpdates.cvFile !== null &&
                  consultantUpdates.cvFile !== undefined ? (
                    <div>
                      <FileUpload
                        fileUrlReceived={!!consultantUpdates.cvUrl}
                        className="space-y-2"
                        fileName={
                          consultantUpdates.cvFile?.name
                            ? consultantUpdates.cvFile.name
                            : consultantUpdates.cvUrl
                            ? consultantUpdates.cvUrl
                            : "no file choosen"
                        }
                        fileSize={
                          consultantUpdates.cvFile
                            ? `${(consultantUpdates.cvFile.size / 1024).toFixed(
                                2
                              )} KB`
                            : ""
                        }
                        progress={uploadProgressCv}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="flex justify-self-start flex-col space-y-2">
              <Typography level="title-sm" noWrap>
                Last opp profilbilde
              </Typography>
              <Divider />
              <div className="flex flex-col space-y-2">
                <FileDropZoneImage SetImage={setConsultantProfileImage} />
                {(!!consultantUpdates.imageUrl &&
                  consultantUpdates.imageFile === null) ||
                consultantUpdates.imageFile === undefined ? (
                  <div>
                    <FileUpload
                      fileUrlReceived={!!consultantUpdates.imageUrl}
                      className="space-y-2"
                      fileName={
                        consultantUpdates.imageFileName
                          ? consultantUpdates.imageFileName
                          : consultantUpdates.imageUrl
                          ? consultantUpdates.imageUrl
                          : "no file choosen"
                      }
                    />
                  </div>
                ) : (
                  <div>
                    {consultantUpdates.imageFile !== null &&
                    consultantUpdates.imageFile !== undefined ? (
                      <div>
                        <FileUpload
                          fileUrlReceived={!!consultant.imageUrl}
                          className="space-y-2"
                          fileName={
                            consultantUpdates.imageFile?.name
                              ? consultantUpdates.imageFile.name
                              : consultantUpdates.imageUrl
                              ? consultantUpdates.imageUrl
                              : "no file choosen"
                          }
                          fileSize={
                            consultantUpdates.imageFile
                              ? `${(
                                  consultantUpdates.imageFile.size / 1024
                                ).toFixed(2)} KB`
                              : ""
                          }
                          progress={uploadProgressProfileImage}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-self-start flex-col space-y-2">
                <Typography level="title-sm" noWrap>
                  Utdanning
                </Typography>
              </div>
              <div className=" flex flex-row justify-self-start space-x-2 mt-2">
                <div className="flex w-1/4 ">
                  <DegreeSelector
                    value={consultantUpdates.degreeLevel!}
                    onChange={handleDegreeChange}
                  />
                </div>
                <div className="flex w-full">
                  <InputField
                    label="Navn på grad"
                    value={consultantUpdates.educationName!}
                    onChange={(e) => handleEducationNameChange(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className=" flex flex-col justify-self-start">
              <div className="w-1/4 border rounded-tl-2xl rounded-tr-2xl">
                <Typography
                  level="title-sm"
                  noWrap
                  marginTop={2}
                  paddingLeft={2}
                  marginBottom={1}
                >
                  Sammendrag
                </Typography>
              </div>
              <div className="">
                <textarea
                  className="w-full border border-solid border-gray-300 rounded-md p-2 rounded-t-none"
                  rows={4}
                  maxLength={400}
                  value={consultantUpdates.description ?? ""}
                  onChange={(e) =>
                    setConsultantUpdates((prevConsultant) => ({
                      ...prevConsultant,
                      description: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className=" flex flex-col justify-self-start">
              <div className="w-1/4 border rounded-tl-2xl rounded-tr-2xl">
                <Typography
                  level="title-sm"
                  noWrap
                  marginTop={2}
                  paddingLeft={2}
                  marginBottom={1}
                >
                  Fun fact
                </Typography>
              </div>
              <div className="">
                <textarea
                  className="w-full border border-solid border-gray-300 rounded-md p-2 rounded-t-none"
                  rows={4}
                  maxLength={400}
                  value={consultantUpdates.funFact ?? ""}
                  onChange={(e) =>
                    setConsultantUpdates((prevConsultant) => ({
                      ...prevConsultant,
                      funFact: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <CommonButton text="Lagre" type="button" onClick={handleChanges} />
          </div>
        </Drawer>
      </CssVarsProvider>
    </div>
  );
};

export default EditProfileDrawer;
