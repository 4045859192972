import React, { ReactElement} from 'react';
import Tooltip from '@mui/material/Tooltip';

interface BasicTooltipProps {
    text: string;
    children: ReactElement;

    tooltipClassName?: string;
}

const BasicTooltip: React.FC<BasicTooltipProps> = ({ text, children, tooltipClassName = '' }) => {
    return (
        <Tooltip title={text} arrow>
            {children}
        </Tooltip>
    );
};

export default BasicTooltip;
