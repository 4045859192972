import React from "react";
import { MdAddCircleOutline } from "react-icons/md";
import Button from "@mui/material/Button";

interface ComponentProps {
  onClick: Function;
  customerId: number;
  time: string;
}

export const ScheduleEmpty: React.FC<ComponentProps> = ({
  onClick,
  customerId,
  time,
}) => {
  function handleOnDrag(e: React.DragEvent, interviewId: string) {
    e.dataTransfer.setData("interviewId", interviewId);
  }

  return (
    <div
      className={`w-36 h-9 relative rounded flex justify-center p-1 text-white bg-#2a8c08 mx-auto`}
    >
      <button onClick={() => onClick(customerId, time)}>
        <MdAddCircleOutline color="black" fontSize="medium" />
      </button>
    </div>
  );
};
