import {createConsultant, deleteConsultant, createCustomer, deleteCustomer, createAdmin, deleteAdmin, editCustomer, editAdmin, editConsultant  } from "../api/adminApi";

export const handleAddConsultant = async (newConsultant: {
  consultantName: string;
    consultantEmail: string; 
    specialization: string;
}) => {
  try {
     await createConsultant(newConsultant);
  } catch (error) {
    console.error("Error adding consultant:", error);
  }
};


export const handleAddCustomer = async (
  newCustomer: {
    customerName: string;
    customerEmail: string;
    desiredSpecialization: string;
    logoFile: File | null;
    customerContactPerson: string;
    responsibleAdmin: string;
  },
) => {
  try {
    await createCustomer(newCustomer); 
  } catch (error) {
    console.error("Error adding admin:", error);
  }
};


  export const handleEditCustomer = async (  customerData: {
    customerId: number;
    customerName: string;
    customerEmail: string;
    desiredSpecialization: string;
    customerContactPerson: string;
    responsibleAdmin: string;
    logoFile: File | null;
},
) => {
    try {
        editCustomer(customerData); 
    } catch (error) {
      console.error("Error adding admin:", error);
    }
  };
  

  export const handleEditConsultant = async (  editedConsultant: {
      consultantId: number;
      consultantName: string;
      description: string;
      consultantEmail: string;
      specialization: string;
      education: string;
      funFact: string;
      cvFile: File | null; 
      imageFile: File | null;
},
) => {
    try {
       await editConsultant(editedConsultant); 
    } catch (error) {
      console.error("Error editing consultant:", error);
    }
  };
  
  
  export const handleRemoveCustomer = async (customerId: number) => {
    try {
      await deleteCustomer(customerId);
    } catch (error) {
      console.error("Failed trying to remove customer:", error);
    }
  };
  

  
  export const handleRemoveConsultant = async (consultantId: number) => {
    try {
      await deleteConsultant(consultantId);
    } catch (error) {
      console.error("Error trying to remove candidate:", error);
    }
  };
  
  export const handleAddAdmin = async (
    newAdmin: {
      adminName: string;
      adminEmail: string;
    },
  ) => {
    try {
       await createAdmin(newAdmin);
    } catch (error) {
      console.error("Error adding admin:", error);
    }
  };

  export const handleEditAdmin = async (
    adminData: {
      adminId: number;
      adminName: string;
      adminEmail: string;
    },
  ) => {
    try {
      await editAdmin(adminData); 
    } catch (error) {
      console.error("Error editing admin:", error);
    }
  };
  
  
  
  export const handleRemoveAdmin = async (adminId: number
    ) => {
    try {
      await deleteAdmin(adminId);
  
    } catch (error) {
      console.error("Error adding admin:", error);
    }
  };

