import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import AuthGuard from "./utils/AuthGuard";
import { Navbar } from "./components/common/Navbar";
import SchedulePage from "./pages/admin/SchedulePage";
import "./App.css";
import ConsultantProfilePage from "./pages/consultant/ConsultantProfilePage";
import ManageUsersPage from "./pages/admin/ManageUsersPage";
import ScoreOverviewPage from "./pages/admin/ScoreOverviewPage";
import ConsultantOverviewPage from "./pages/ConsultantOverviewPage";
import AgendaPage from "./pages/customer/AgendaPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/reducers/rootReducer";
import { extractExpirationFromJWT } from "./utils/JwtExtraction";
import { logout } from "./redux/slices/authSlice";
import ConsentForm from "./components/common/consent/ConsentForm";
import { useCookies } from "react-cookie";
import ConsentPage from "./pages/consent/ConsentPage";


const App: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const token = useSelector((state: RootState) => state.auth.accessToken);

  /**
   * Checks if the token is expired and logs out the user if it is
   */
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = extractExpirationFromJWT(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken && decodedToken < currentTime) {
          console.log("token expired");
          dispatch(logout());
          navigate("/");
        }
      }
    };

    checkTokenExpiration();
  }, [dispatch, navigate, token]);
  const [cookies] = useCookies(["cookieConsent"]);

  return (
    <div className="flex flex-col h-screen w-full">
      {isAuthenticated && <Navbar />}
      {!cookies.cookieConsent && <ConsentForm />}
      <div
        className="fixed top-20 w-full h-full bottom-0"
        style={{ maxHeight: "calc(100vh - 47px)" }}
      >
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/agenda" replace={true} />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/konsulenter"
            element={
              <AuthGuard acceptedRoles={["Customer", "Admin"]}>
                <ConsultantOverviewPage />
              </AuthGuard>
            }
          />

          <Route
            path="/agenda"
            element={
              <AuthGuard acceptedRoles={["Customer"]}>
                <AgendaPage />
              </AuthGuard>
            }
          />

          <Route
            path="/scoringsskjema"
            element={
              <AuthGuard acceptedRoles={["Admin"]}>
                <ScoreOverviewPage />
              </AuthGuard>
            }
          />

          <Route
            path="/profil"
            element={
              // <AuthGuard acceptedRoles={["Admin"]}>
              //   <ConsultantProfilePage />
              // </AuthGuard>
              <AuthGuard acceptedRoles={["Consultant"]}>
                <ConsultantProfilePage />
              </AuthGuard>
            }
          />

          <Route
            path="/administrer-brukere"
            element={
              <AuthGuard acceptedRoles={["Admin"]}>
                <ManageUsersPage />
              </AuthGuard>
            }
          />
          <Route
            path="/tidsskjema"
            element={
              <AuthGuard acceptedRoles={["Admin"]}>
                <SchedulePage />
              </AuthGuard>
            }
          />

          <Route path="/personvern" element={<ConsentPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
