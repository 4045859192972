import * as React from "react";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { MdKeyboardArrowDown } from "react-icons/md";

interface DegreeSelectorProps {
  onChange: (newValue: string | null) => void;
  value: string;
}

const DegreeSelector: React.FC<DegreeSelectorProps> = ({ onChange, value }) => {
  const handleChange = (
    event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null,
    value: string | null
  ) => {
    onChange(value);
  };
  return (
    <Select
      placeholder={"Velg ..."}
      value={value}
      onChange={handleChange}
      indicator={<MdKeyboardArrowDown />}
      sx={{
        width: 240,
        [`& .${selectClasses.indicator}`]: {
          transition: "0.2s",
          [`&.${selectClasses.expanded}`]: {
            transform: "rotate(-180deg)",
          },
        },
      }}
    >
      {value === null && <Option value="" disabled>Select an option</Option>}
      <Option value="Bachelor">Bachelor</Option>
      <Option value="Master">Master</Option>
      <Option value="PhD">PhD</Option>
    </Select>
  );
};
export default DegreeSelector;
