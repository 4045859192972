import ConsultantProfileCard from "../../components/consultant/ConsultantProfileCard";

const ConsultantProfilePage = () => {

  return(
    <>
    <ConsultantProfileCard/>
    </>
    
  );
} 
export default ConsultantProfilePage;
