import React, { useState, useEffect } from 'react';
import { Slide } from '@mui/material';
import "../../App.css";
import { fetchSasTokenWithUrl } from "../../api/authApi";

interface CustomerCarouselProps {
    customerName: string; // Define the prop here
    logoUrls: string[] | undefined;
  }
  
const numberOffset = [4, 6, 8, 10];
const getRandomOffset = () => {
    // Choose a random index from the numberOffset array
    const randomIndex = Math.floor(Math.random() * numberOffset.length);
    // Get the random offset value from the array
    const randomOffset = numberOffset[randomIndex];    
    return randomOffset;
};

const CustomerCarousel: React.FC<CustomerCarouselProps> = ({ customerName, logoUrls }) => { 
    const [index, setIndex] = useState(0);
    const [sasTokenUrls, setSasTokenUrls] = useState<string[]>([]);

    // fetch blob urls
    useEffect(() => {
        const fetchBlobSasTokens = async () => {
            const tokenPromises = logoUrls?.map(async (blobUrl) => {
                if (blobUrl) {
                    const sasTokenUrl = await fetchSasTokenWithUrl(blobUrl);
                    return sasTokenUrl;
                }
            });
            const sasTokenUrlsArray = await Promise.all(tokenPromises || []);
            setSasTokenUrls(sasTokenUrlsArray.filter(url => url !== undefined));
        };
        fetchBlobSasTokens();
    }, [logoUrls]);

    // Split the array into two. 
    const firstHalf = sasTokenUrls.slice(0, Math.ceil(sasTokenUrls.length / 2));
    const secondHalf = sasTokenUrls.slice(Math.ceil(sasTokenUrls.length / 2));

    const handleExited = () => {
        setIndex((prevIndex) => (prevIndex + 1) % (sasTokenUrls.length));
    };

    return (
        <div className="overflow-hidden">
            <div className="ml-4 tracking-wider text-3xl" style={{ marginTop: '20px' }}> {/* Justert for padding og farge */}
                <p className="mb-1">Hei <b>{customerName}</b>, og velkommen til </p> 
                <p>speedintervjudagene hos Experis Academy</p>
            </div>
            <div className="pl-4 pt-4 mb-14"> 
                <p className="text-lg">Gå til det første intervjuet ved å klikke på kortet til venstre</p> {/* Justert tekststørrelse og farge */}
            </div>

            <div className="h-auto mb-10 max-h-72 space-x-8 carousel-container flex flex-row">
                {firstHalf.map((image, i) => (
                    <Slide
                        key={i}
                        direction="left"
                        in
                        timeout={2000}
                        onExited={handleExited}
                    >
                        <img
                            className={`animate-slide px-${getRandomOffset()} my-${getRandomOffset()} h-40 w-64 object-contain`} // Adjust height and width as needed
                            src={image}
                            alt={`Customer ${i + 1}`}
                        />
                    </Slide>
                ))}
            </div>
            <div className="h-auto max-h-72 space-x-8 carousel-container flex flex-row">
                {secondHalf.map((image, i) => (
                    <Slide
                        key={i}
                        direction="left" 
                        in   
                        timeout={2000}
                        onExited={handleExited}
                    >
                        <img
                            className={`animate-slide px-${getRandomOffset()} my-${getRandomOffset()} h-40 w-64 object-contain`} // Adjust height and width as needed
                            src={image}
                            alt={`Customer ${i + 1}`}
                        />
                    </Slide>
                ))}
            </div>
        </div>
      );
      
};

export default CustomerCarousel;