import React, { MouseEvent } from "react";
import { ScheduleInterview as Interview } from "../../../types/types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface ComponentProps {
  interview: Interview;
  toogledConsultant: number;
  setToogledConsultant: Function;
}

export const ScheduleInterview: React.FC<ComponentProps> = ({
  interview,
  toogledConsultant,
  setToogledConsultant,
}) => {
  function handleOnDrag(e: React.DragEvent) {
    e.dataTransfer.setData("interviewId", interview.interviewId.toString());
  }

  let bg: string = "transparent";

  function handleRightClick(e: MouseEvent) {
    e.preventDefault();
    if (toogledConsultant === interview.consultantId) {
      setToogledConsultant(-1);
    } else {
      setToogledConsultant(interview.consultantId);
    }
  }
  if (toogledConsultant === interview.consultantId) {
    bg = "brandOrange-regular";
  }
    return (
        <div
        className={`relative bg-${bg} rounded flex justify-center items-center text-black px-2 py-1 lg:px-4 lg:py-2 border-black border-[1px]`}
            draggable
            onDragStart={(e) => handleOnDrag(e)}
            onContextMenu={(e) => handleRightClick(e)}
        >
        <p className="!text-xs lg:!text-sm">
            {interview.consultantName}
        </p>
        </div>
    );
};
