import React, { ReactNode, useEffect, useState } from "react";
import { Button } from "../common/Button";
import CvDrawer from "./CvDrawer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { extractRoleFromJWT } from "../../utils/JwtExtraction";
import {
  ConsultantDetails as ConsultantDetail,
  ConsultantMeeting,
} from "../../types/consultantTypes";
import SnackBarCustom from "../common/SnackBarCustom";
import { useLocation } from "react-router";
import { fetchSasTokenWithUrl } from "../../api/authApi";
import { Divider } from "@mui/material";

interface ConsultantDetailsProps {
  consultant: ConsultantDetail | ConsultantMeeting;
  children?: ReactNode;
  cvWidth?: string;
  otherDesign?: boolean;
}

const ConsultantDetails: React.FC<ConsultantDetailsProps> = ({
  consultant,
  children,
  cvWidth = "670px",
  otherDesign = false,
}) => {
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const [role, setRole] = useState<string>("");
  const [isCvOpen, setIsCvOpen] = useState(false);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const location = useLocation().pathname;

  // For extracting and set user role from JWT token
  useEffect(() => {
    const role = extractRoleFromJWT(token);
    setRole(role);
  }, []);

  // To handle the closing of the CV viewer
  const handleCloseCv = () => {
    setIsCvOpen(false);
  };

  // Fetches SAS token url and sets the blobUrl state
  const fetchSasTokenUrl = async (blobName: string) => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${baseUrl}/Blob/${blobName}`);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const sasTokenUrl = await response.json();
      return sasTokenUrl;
    } catch (error) {
      console.error("Error fetching SAS token URL:", error);
    }
  };

  // Open and display CV from blob
  const handleOpenCvFromBlob = async () => {
    try {
      const blobName = consultant.cvUrl || "";
      const sasTokenUrl = await fetchSasTokenUrl(blobName);

      // If SAS token URL is available, it will get the actuabl blob using it's token, covert the respone, and create a slice for the mime type.
      if (sasTokenUrl) {
        const response = await fetch(sasTokenUrl);
        const blob = await response.blob();
        let test: Blob = blob.slice(0, blob.size, "application/pdf");
        const localUrl = URL.createObjectURL(test);
        setIsCvOpen(true);
        setBlobUrl(localUrl);
      }

      // If not, it handles the errors during the process
    } catch (error) {
      setErrorMessage("Error opening CV:");
    }
  };

  // For extracting degree and title from the "education" string
  const getTitleAndDegree = (education: string | null | undefined) => {
    education = education || "";
    let [degree, title] = education.includes("$$$")
      ? education.split("$$$")
      : ["", ""];

    if (!education.includes("$$$")) {
      degree = education.slice(0, education.indexOf(" ")) || "";
      title = education.slice(degree.length) || "";
    }

    return { degree, title };
  };

  // Placeholder text for consultants with empty text fields
  const getEmptyFieldText = (valueName: string) => {
    return `Ingen ${valueName}`;
  };

  // Fetch blob SAS tokens for consultant image
  useEffect(() => {
    const fetchBlobSasTokens = async () => {
      if (consultant.imageUrl) {
        const sasTokenUrl = await fetchSasTokenWithUrl(consultant.imageUrl);
        setImageUrl(sasTokenUrl);
      }
    };

    fetchBlobSasTokens();
  }, [consultant.imageUrl]);

  if (otherDesign) {
    return (
      <div
        className={`flex flex-col min-w-50 overflow-y-auto items-center w-full`}
      >
        {errorMessage && (
          <SnackBarCustom color="danger" message={errorMessage} />
        )}
        <div className="flex flex-row w-full space-x-10">
          {/* Consultant's image and open CV button */}
          {/* {!isCvOpen || !location.includes('/agenda') ? ( */}
          <div className="max-w-60 ">
            <img
              src={imageUrl || "/assets/person.svg"}
              alt={
                consultant.imageUrl
                  ? `Bilde av ${consultant.consultantName}`
                  : "PlaceholderImage"
              }
              className="rounded-md aspect-square object-cover max-w-[203px] "
            />
          </div>
          
          {/* Consultant details */}
          <div className={`flex flex-col w-full`}>
            {/* Basic information */}
            <div className=" space-y-8">
              <div className={`flex flex-col `}>
                <h1>{consultant.consultantName}</h1>
                <h2 className="font-normal">{consultant.specialization}</h2>
                <p className="normal-case italic">
                  <b className="not-italic capitalize">
                    {getTitleAndDegree(consultant.education).degree}
                  </b>
                  {getTitleAndDegree(consultant.education).title}
                </p>
              </div>

              <Button
                text="Åpne CV"
                width="w-[203px]"
                onClick={handleOpenCvFromBlob}
              />
            </div>

          </div>
        </div>
        <div className="py-6 w-full">
          {/* Consultant summary */}
          <h3 className="font-bold pt-3">Sammendrag</h3>
          <p>{consultant.description || getEmptyFieldText("sammendrag")}</p>
          {/* Fun fact */}
          <h3 className="font-bold pt-3">Fun fact</h3>
          <p>{consultant.funFact || getEmptyFieldText("fun fact")}</p>

          {location.includes("konsulenter") && <Divider className="pb-5" />}
        </div>
        {/* Additional children components */}
        {children}
        {/* CV Drawer component */}
        <CvDrawer
          consultant={consultant}
          blobUrl={blobUrl}
          onClose={handleCloseCv}
          isOpen={isCvOpen}
          width={cvWidth}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`flex flex-col ml-auto space-y-3 min-w-50 overflow-y-auto items-center w-full`}
      >
        {errorMessage && (
          <SnackBarCustom color="danger" message={errorMessage} />
        )}
        <div className="flex flex-row pb-6 w-full space-x-10">
          {/* Consultant's image and open CV button */}
          {/* {!isCvOpen || !location.includes('/agenda') ? ( */}
          <div className="max-w-60 space-y-3 px-5">
            <img
              src={imageUrl || "/assets/person.svg"}
              alt={
                consultant.imageUrl
                  ? `Bilde av ${consultant.consultantName}`
                  : "PlaceholderImage"
              }
              className="rounded-md aspect-square object-cover max-w-[203px] "
            />
            <Button
              text="Åpne CV"
              custom="bg-brandBlueLight ring-brandBlueLight"
              onClick={handleOpenCvFromBlob}
            />
          </div>
          {/* ) : null} */}
          {/* Consultant details */}
          <div className={`flex flex-col w-full`}>
            {/* Basic information */}
            <div className={`flex flex-col `}>
              <h1>{consultant.consultantName}</h1>
              <h2 className="font-normal">{consultant.specialization}</h2>
              <p className="normal-case italic">
                <b className="not-italic capitalize">
                  {getTitleAndDegree(consultant.education).degree}
                </b>
                {getTitleAndDegree(consultant.education).title}
              </p>
              {/* Consultant summary */}
              <h3 className="font-bold pt-3">Sammendrag</h3>
              <p>{consultant.description || getEmptyFieldText("sammendrag")}</p>
              {/* Fun fact */}
              <h3 className="font-bold pt-3">Fun fact</h3>
              <p>{consultant.funFact || getEmptyFieldText("fun fact")}</p>

              {location.includes("konsulenter") && <Divider className="pb-5" />}
            </div>
            {/* Additional children components */}
            <div className="py-6">{children}</div>
          </div>
        </div>

        {/* CV Drawer component */}
        <CvDrawer
          consultant={consultant}
          blobUrl={blobUrl}
          onClose={handleCloseCv}
          isOpen={isCvOpen}
          // width={location.includes('/agenda') ? '50vw' : '40vw'}
          width={cvWidth}
        />
      </div>
    );
  }
};

export default ConsultantDetails;
