import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../types/types";

/**
 * Authentication State Interface
 *
 * Represents the shape of the authentication state.
 *
 * @interface AuthState
 * @property {boolean} isAuthenticated - Indicates whether the user is authenticated.
 * @property {string | null} accessToken - The access token or `null` if not authenticated.
 */

/**
 * Initial Authentication State
 *
 * Represents the initial state of the authentication reducer.
 *
 * @constant
 * @type {AuthState}
 */
const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null, /*               <------------      Her puttes token*/
};

/**
 * Authentication Slice
 *
 * Uses createSlice from Redux Toolkit to define a slice of the Redux store for authentication.
 */
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /**
     * Login Reducer
     *
     * Handles the "login" action, updating the authentication state.
     *
     * @param {AuthState} state - Current state of the authentication slice.
     * @param {PayloadAction<string>} action - Payload contains the access token.
     */
    login: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload;
    },
    /**
     * Logout Reducer
     *
     * Handles the "logout" action, updating the authentication state.
     *
     * @param {AuthState} state - Current state of the authentication slice.
     * @param {PayloadAction<void>} action - Payload is void since it's a logout action.
     */
    logout: (state) => {
      state.isAuthenticated = false;
      state.accessToken = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
