import React, { useState } from "react";

interface ButtonProps {
  text: string;
  width?: string;
  type?: "button" | "submit" | "reset";
  customText?: string;
  custom?: string;
  customHover?: string;
  onClick?: () => void;
  disabled?: boolean;
  lowercase?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  width,
  type,
  customText,
  custom,
  customHover,
  onClick,
  disabled,
  lowercase,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonClass = `text-white ring ring-inset ring ring-brandBlueDark rounded-md px-2 py-2  ${width ? width : "w-full"
    } ${custom || "bg-brandBlueDark"}`;
  const hoverClass = `bg-white hover:text-brandBlueDark hover:shadow-md ${customHover
    }`;

  return (
    <button
      className={`${buttonClass} ${isHovered ? hoverClass : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      type={type ? type : "button"}
      onClick={onClick}
      disabled={disabled}
    >
      <p
        className={`${lowercase ? "" : "uppercase"} font-semibold ${isHovered ? customText || "hover:text-brandBlueDark" : "text-white "
          }`}
      >
        {text}
      </p>
    </button>
  );
};
