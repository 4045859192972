import React, { useEffect, useState } from "react";
import {
  ScheduleInterview as Interview,
  Timeslot,
  ScheduleCustomer as Customer,
} from "../../../types/types";
import { fetchAllInterviews } from "../../../api/adminApi";
import { removeDuplicates } from "../../../utils/ArrayHelper";
import { FilterSlider } from "../../common/FilterSlider";
import { Button } from "../../common/Button";
import SnackBarCustom from "../../common/SnackBarCustom";
import { LuPencilLine } from "react-icons/lu";
import LoadingSpinner from "../../common/LoadingSpinner";
import { exportScheduleToExcel } from "../../../utils/ExportSceduleToXLSX";

interface FinishedScheduleProps {
  newScheduleFunction?: ( step: number) => void;
  onHandleChange: Function;
  setHasSchedule: Function;
}
export const FinishedSchedule: React.FC<FinishedScheduleProps> = ({
  newScheduleFunction,
  onHandleChange,
  setHasSchedule
}) => {
  const [times, setTimes] = useState<Timeslot[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [interviews, setInterviews] = useState<Interview[]>([]);
  const [days, setDays] = useState<string[]>([]);
  const [currentDay, setCurrentDay] = useState<string>(days[0]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [exporting, setExporting] = useState<boolean>(false);

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await fetchAllInterviews();
      if (result) {
        const { responseInterviews, responseTimes } = result;

        const uniqueCustomers = removeDuplicates(
          responseInterviews,
          "customerId"
        );

        const uniqueDays = Array.from(
          new Set(responseInterviews.map((int) => int.date))
        ).filter((date) => date !== "");

        setCustomers(uniqueCustomers);
        setInterviews(responseInterviews);

        setDays(uniqueDays);
        setTimes(
          responseTimes.map((time) => ({
            from: time.time,
            to: "",
            type: time.infoslot,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentDay(days[0]);
  }, [days]);

  const changeDay = (newDay: string) => {
    setCurrentDay(newDay);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {errorMessage && (
        <SnackBarCustom
          color="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      <h1 className="text-center">Tidsskjema for speedintervjudagene</h1>
      <div className="flex items-center justify-between space-x-96">
        <div className="w-1/4">
          <FilterSlider
            filterOptions={days}
            onFilterChange={changeDay}
            sliderParentDivStyling="rounded-t-md"
          />
        </div>
        <div className="flex items-center">
          <button
            className="flex items-center"
            onClick={() => {
              onHandleChange();
              setHasSchedule(true);
            }}
          >
            <LuPencilLine size={45} />
            <span className="ml-2">Edit</span>
          </button>
        </div>
      </div>

      <div className="overflow-auto rounded-tr-md max-h-[57vh]">
        <table
          id="finished-schedule"
          className="table-auto w-full border-collapse text-center border"
        >
          <thead>
            <tr className="bg-brandBlueDark text-white ">
              <th
                className="sticky left-0 top-0 w-10 min-w-10 bg-brandBlueDark z-10 font-normal text-base"
                style={{ minWidth: "10rem" }}
              ></th>
              {customers.map((customer) => (
                <th
                  key={customer.customerId}
                  className="font-normal text-base sticky top-0"
                  style={{ minWidth: "8rem" }}
                >
                  {customer.customerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {times.map((timeSlot, index) => (
              <tr key={timeSlot.from + index}>
                <td
                  className={`sticky left-0 font-semibold border border-black  ${index % 2 === 0 ? "bg-brandGray-light" : "bg-brandBG"
                    }`}
                >
                  {timeSlot.from}
                </td>
                {customers.map((customer, key) => {
                  const interview = interviews.find(
                    (int) =>
                      int.date === currentDay &&
                      int.time === timeSlot.from &&
                      int.customerId === customer.customerId
                  );

                  return timeSlot.type === "pause" ? (
                    <td
                      key={key}
                      className="bg-brandOrange-regular border-b border-black"
                    >
                      <div className="w-fit px-3 rounded-md mx-auto">Pause</div>
                    </td>
                  ) : (
                    <td
                      key={key}
                      className={`border border-black ${interview ? "" : "bg-availableGreen"
                        }`}
                    >
                      <div
                        className={`w-fit px-3 rounded-md mx-auto  ${interview ? "" : "text-white"
                          } `}
                      >
                        {interview ? interview.consultantName : "Ledig tid"}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full flex flex-row ml-auto fixed bottom-5 right-10 justify-end">
        <div className="">
          <Button
            custom="bg-brandBlueLight"
            text="LAG NYTT SKJEMA"
            type="button"
            onClick={() => {
              if (newScheduleFunction) {
                newScheduleFunction(0);
              }
            }}
          />
        </div>
        <div>
          {exporting ? (
            <LoadingSpinner />
          ) : (
            <Button
              text="Eksporter til Excel"
              type="button"
              onClick={() => {
                exportScheduleToExcel({
                  customer: customers,
                  interviews: interviews,
                  timeSlots: times,
                  uniqueDays: days,
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
