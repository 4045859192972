// ConsultantListSection.tsx
import React from "react";
import {
  ConsultantDetails as ConsultantAdmin,
  ConsultantNoMeeting,
} from "../../types/consultantTypes";
import { ConsultantMeeting } from "../../types/consultantTypes";
import CardGroup from "./CardGroup";
import FilterBar from "./FilterBar";
import ConsultantRadioFilter from "../customer/ConsultantsRadioFilter";

type ConsultantListSectionProps = {
  role: string;
  customerId: number;
  consultants: ConsultantAdmin[] | (ConsultantMeeting | ConsultantNoMeeting)[];
  filteredConsultants:
    | ConsultantAdmin[]
    | (ConsultantMeeting | ConsultantNoMeeting)[];
  setFilteredConsultants: (
    filteredResults:
      | ConsultantAdmin[]
      | (ConsultantMeeting | ConsultantNoMeeting)[]
  ) => void;
  setActiveConsultant: (
    consultant: ConsultantAdmin | ConsultantMeeting | ConsultantNoMeeting
  ) => void;
  setConsultants: (
    consultants: (ConsultantMeeting | ConsultantNoMeeting)[]
  ) => void;
};

const ConsultantListSection: React.FC<ConsultantListSectionProps> = ({
  role,
  customerId,
  consultants,
  filteredConsultants,
  setFilteredConsultants,
  setActiveConsultant,
  setConsultants,
}) => {
  const handleCardClick = (activeConsultantId: number) => {
    let foundConsultant: any = null;

    if (role === "Admin") {
      foundConsultant = (consultants as ConsultantAdmin[]).find(
        (c: ConsultantAdmin) => c.consultantId === activeConsultantId
      );
    }
    if (role === "Customer") {
      foundConsultant = (consultants as ConsultantMeeting[]).find(
        (c: ConsultantMeeting) => c.consultantId === activeConsultantId
      );
    }
    if (foundConsultant) {
      setActiveConsultant(foundConsultant);
    }
  };

  return (
    <div
      className="bg-brandBG max-h-full w-4/6 h-full flex flex-col pt-12 px-10"
    >
      <h2 className=" text-base font-semibold">
        {role === "Admin"
          ? "Konsulentoversikt"
          : "Se oversikt over konsulenter"}
      </h2>
      <FilterBar
        role={role}
        consultants={consultants}
        setFilteredConsultants={setFilteredConsultants}
        filteredConsultants={filteredConsultants}
      ></FilterBar>

      {role === "Customer" && !("customers" in consultants) && (
        <ConsultantRadioFilter
          customerId={customerId}
          consultants={
            consultants as (ConsultantMeeting | ConsultantNoMeeting)[]
          }
          setConsultants={setConsultants}
        />
      )}

      {filteredConsultants && filteredConsultants.length > 0 && (
        <CardGroup
          consultants={filteredConsultants}
          onClick={(consultantId) => handleCardClick(consultantId)}
        />
      )}
    </div>
  );
};

export default ConsultantListSection;
