// InputFieldWithUpdate.tsx
import { QuillOptionsStatic } from "quill";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // include styles'

interface RichTextEditorProps extends QuillOptionsStatic {
  content: string;
  onUpdate?: (value: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  content,
  onUpdate,
  ...quillOptions
}) => {
  const [textEditor, setTextEditor] = useState("");

  useEffect(() => {
    setTextEditor(content || "");
  }, [content]);

  const handleTextChange = (html: string) => {
    setTextEditor(html);
    onUpdate && onUpdate(html);
  };

  const modules = {
    toolbar: [["bold", "italic", "strike"], [{ list: "bullet" }]],
  };

  const editorStyle = {
    border: "2px solid #ccc",
    borderRadius: "4px",
    minHeight: "200px",
  };

  return (
    <ReactQuill
      theme="snow"
      value={textEditor}
      onChange={handleTextChange}
      modules={modules}
      {...quillOptions}
    ></ReactQuill>
  );
};

export default RichTextEditor;
