import { Modal, ModalClose, Sheet, Typography } from "@mui/joy";
import { CiCircleInfo } from "react-icons/ci";
import { useState } from "react";
const ScoreInfo = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>

      <CiCircleInfo onClick={() => setOpen(true)} size={25} className="cursor-pointer"/>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            className="text-black text-3xl font-black leading-5 tracking-wider whitespace-nowrap mt-2.5 max-md:max-w-full max-md:text-4xl max-md:leading-7"
            mb={1}
          >
            Graderingskrav
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
            <div className="justify-center text-black text-sm leading-2 tracking-wider mt-5 mb-5 max-md:max-w-full">
              <span className="font-black">
                Gradering 5 <br />
              </span>
              <span className="">
                På en kandidat betyr at dere ønsker å ha dybdeintervju (gis til
                favoritten/-ene)​
                <br />​<br />
              </span>
              <span className="font-black">
                Gradering 4<br />
              </span>
              <span className="">
                Er også dybdeintervju, men kandidaten er ikke blant favorittene
                etter speedintervju​
                <br />​<br />
              </span>
              <span className="font-black">
                Gradering 3<br />
              </span>
              <span className="">
                Er ikke dybdeintervju, men det er muligheter for å revurdere.​
                Vi sjekker hva kandidatens tilbakemelding er, og om kandidaten
                har dere som favoritt gir vi deg denne informasjonen tilbake
                slik at dere kan vurdere et dybdeintervju. ​<br />​<br />
              </span>
              <span className="font-black">
                Gradering 2<br />
              </span>
              <span className="">
                Er ingen match, men kandidaten gjennomførte et bra
                speedintervju​
                <br />​<br />
              </span>
              <span className="font-black">
                Gradering 1<br />
              </span>
              <span className="">
                På en kandidat betyr at er ingen match, og kandidaten gjorde et
                «under par» speedintervju. ​<br />
                Vi bruker denne informasjonen til å tilrettelegge for mer
                intervjutrening til kandidaten. ​
              </span>
            </div>
          </Typography>
        </Sheet>
      </Modal>
    </div>
  );
};

export default ScoreInfo;
