import React, { useEffect, useState } from "react";

interface FilterSliderProps {
  filterOptions: string[];
  onFilterChange: (filter: string) => void;
  sliderParentDivStyling?: string;
  sliderPaddingClass?: string;
}

export const FilterSlider: React.FC<FilterSliderProps> = ({
  filterOptions,
  onFilterChange,
  sliderParentDivStyling = "h-10 rounded-md", // Default if not provided
  sliderPaddingClass = "py-2", // Default padding class if not provided
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(filterOptions[0]);

  useEffect(() => {
    // Set default only if selectedFilter is not already set
    if (!selectedFilter) {
      setSelectedFilter(filterOptions[0]);
      onFilterChange(filterOptions[0]);
    }
  }, [selectedFilter, filterOptions, onFilterChange]);


  const formatDateToNorwegian = (dateString: string): string => {

    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
    };

    let formattedDate = date.toLocaleDateString("nb-NO", options);
    formattedDate = formattedDate.split(' ').map((word, index) => (index === 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word)).join(' ');

    return formattedDate;
  };

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
    onFilterChange(filter);
  };

  return (
    <div className={`flex items-center overflow-hidden border h-fit border-brandBlueDark ${sliderParentDivStyling}`}>
      {filterOptions.map((option, index) => {
        const formattedOption =
          option.match(/^\d{2}\/\d{2}\/\d{4}$/) // Check if the option matches "dd/mm/yyyy" format
            ? formatDateToNorwegian(option)
            : option;
        return (
          <div
            key={index}
            className={`
              flex-1 w-fit  text-center ${sliderPaddingClass} cursor-pointer py-5
              ${index < filterOptions.length - 1 ? "border-r border-brandBlueDark" : ""}
              ${selectedFilter === option ? "bg-brandBlueDark text-white " : "bg-brandWhite text-brandBlueDark"}
            `}
            onClick={() => handleFilterClick(option)}
          >
            {formattedOption}
          </div>
        );
      })}
    </div>
  );
};