import React, { useState, useEffect } from "react";
import CustomInput from "../../common/InputField";
import FileUpload from "../../consultant/FileUpload";
import FileDropZoneImage from "../../consultant/FileDropZoneImage";
import ResponsiveScrollContainer from '../../common/ResponsiveScrollContainer';

interface AddCustomerProps {
  onAddCustomer: (newCustomer: {
    customerName: string;
    customerEmail: string;
    desiredSpecialization: string;
    logoFile: File | null;
    customerContactPerson: string;
    responsibleAdmin: string;
  }) => Promise<void>;
  onSave?: () => void;
}

const AddCustomerForm: React.FC<AddCustomerProps> = ({ onAddCustomer, onSave }) => {
  const [customerName, setCustomerName] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [desiredSpecialization, setDesiredSpecialization] = useState<string>("");
  const [responsibleAdmin, setResponsibleAdmin] = useState<string>("");
  const [error, setError] = useState<string | null | undefined>(null);
  const [customerContactPerson, setCustomerContactPerson] = useState<string>("");
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [specializationError, setSpecializationError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  let logoFileName = "";


  const handleSpecializationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDesiredSpecialization(event.target.value);
  };

  const handleAddCustomer = async () => {
    try {
      if (requiredFieldsFilled) {
        const customerData = {
          customerName,
          customerEmail,
          desiredSpecialization,
          customerContactPerson,
          logoFile,
          responsibleAdmin,
        };
        await onAddCustomer(customerData);
        onSave?.();
      } else if(customerName === "") setNameError(true)
       if(customerEmail === "") setEmailError(true)
       if(desiredSpecialization === "") setSpecializationError(true)
    } catch (error) {
      setError("An error occurred while adding the customer.");
    }
  };

  const setCostumerLogoImage = (file: File | null) => {
    if (file !== null) {
   setLogoFile(file)
    }
  };

  useEffect(() => {
    if (customerName && customerEmail && desiredSpecialization) {
      setRequiredFieldsFilled(true)
      setNameError(false)
      setEmailError(false)
      setSpecializationError(false)
    } else(setRequiredFieldsFilled(false))
  }, [customerName, customerEmail, desiredSpecialization]);

  useEffect(() => {
    if (!!logoUrl) {
      const logoFileUrlGroup = logoUrl.match(/\/([^\/?]+)\?/);
      logoFileName = logoFileUrlGroup ? logoFileUrlGroup[1] : "";
    }
  }, [logoUrl]);
  
  return (
    <ResponsiveScrollContainer>
    <div className="w-96 bg-white p-4 rounded-lg shadow-lg overflow-hidden">
    <h2 className="text-2xl font-semibold mb-4 flex justify-start">Legg til ny kunde</h2>
      <div className="mb-4">
        <CustomInput
          value={customerName}
          textRed={nameError}
          onChange={(e) => setCustomerName(e.target.value)}
          label="Firma"
        /> 
        <div className={`text-xs text-left ${(nameError && customerName === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
   
     <br />
        <div className="mb-4">
          <CustomInput
            value={customerEmail}
            textRed={emailError}
            onChange={(e) => setCustomerEmail(e.target.value)}
            label="E-post"
          />
                  <div className={`text-xs text-left ${(emailError && customerEmail === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
        </div>
      </div>

      <div className="mb-4">
      <label htmlFor="specialization" className={`text-left block mb-2 text-sm ${(specializationError && desiredSpecialization === "") ? "text-red-700" : "text-black"}`}>Spesialisering:</label>
  <div className="flex items-center">
    <div className={`flex items-center mr-4`}>
      <input
        type="radio"
        id="java"
        name="specialization"
        value="Java"
        checked={desiredSpecialization === "Java"}
        onChange={handleSpecializationChange}
      />
      <label htmlFor="java" className="ml-2 text-sm">Java</label>
    </div>
    <div className="flex items-center">
      <input
        type="radio"
        id="dotnet"
        name="specialization"
        value=".NET"
        checked={desiredSpecialization === ".NET"}
        onChange={handleSpecializationChange}
      />
      <label htmlFor="dotnet" className="ml-2 text-sm">.NET</label>
    </div>
  </div>
  <div className={`text-xs text-left ${(specializationError && desiredSpecialization === "") ? "text-red-700" : "text-black"}`}>*Nødvendig</div>


<br/>
  <div className="mb-4">
        <CustomInput
            value={customerContactPerson}
            onChange={(e) => setCustomerContactPerson(e.target.value)}
          label="Kontaktperson"
        />
    <br />
     <div className="mb-4">
              <CustomInput
                value={responsibleAdmin}
                onChange={(e) =>setResponsibleAdmin(e.target.value)}
                label="Ansvarlig Selger"
              />
              </div>
    
     </div>

    
     <label className="text-left block mb-2 text-sm">
  Last opp Logo
</label>
<FileDropZoneImage SetImage={setCostumerLogoImage} />

{logoUrl !== null ? (
  <div>
    <FileUpload
      fileUrlReceived={true}  
      className="space-y-2"
      fileName={logoFileName !== "" ? logoFileName : "no file exists"}
    />
  </div>
) : (
  <div>
    {logoFile !== null ? (
      <div>
        <FileUpload
          fileUrlReceived={true}  
          className="space-y-2"
          fileName={logoFile.name !== "" ? logoFile.name : "no file chosen"}
          fileSize={logoFile ? `${(logoFile.size / 1024).toFixed(2)} KB` : ""}
          progress={uploadProgress}
        />
      </div>
    ) : null}
  </div>
)}
      
               </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="flex justify-end my-9">
          <button onClick={handleAddCustomer}           
          className={`${requiredFieldsFilled ? "bg-brandBlueDark border-brandBlueDark hover:bg-brandWhite hover:text-brandBlueDark cursor-pointer" : "bg-[#696969] cursor-not-allowed"}  text-white border  w-24 px-4 py-2 rounded `}
>Lagre
</button>
      </div>
    </div>
    </ResponsiveScrollContainer>
  );
};

export default AddCustomerForm;
