import React, { useState, useEffect } from "react";
import CustomInput from "../../common/InputField";
import { useSelector } from "react-redux";
import { selectSelectedConsultant } from "../../../redux/slices/SelectedConsulentSlice";
import ResponsiveScrollContainer from '../../common/ResponsiveScrollContainer';
import FileDropZoneCv from "../../consultant/FileDropZoneCv";
import FileUpload from "../../consultant/FileUpload";
import FileDropZoneImage from "../../consultant/FileDropZoneImage";


interface EditConsultantFormProps {
  onEditConsultant: (newConsultant: {
    consultantId: number;
    consultantName: string;
    description: string;
    cvFile: File | null;
    imageFile: File | null;
    consultantEmail: string;
    specialization: string;
    education: string;
    funFact: string;
  }) => Promise<void>;
  onSave?: () => void;
}

const EditConsultantForm: React.FC<EditConsultantFormProps> = ({
  onEditConsultant, onSave
}) => {
  const selectedConsultant = useSelector(selectSelectedConsultant);
  const [consultantName, setConsultantName] = useState<string>(selectedConsultant?.consultantName || "");
  const [description, setConsultantDescription] = useState<string>(selectedConsultant?.description || "");
  const [cvFile, setConsultantCVFile] = useState<File | null>(selectedConsultant?.cvFile || null);
  const [imageFile, setConsultantImageFile] = useState<File | null>(selectedConsultant?.imageFile || null);
  const [consultantEmail, setEmail] = useState<string>(selectedConsultant?.consultantEmail || "");
  const [specialization, setSpecialization] = useState<string>(selectedConsultant?.specialization || "");
  const [education, setEducation] = useState<string>(selectedConsultant?.education || "");
  const [degree, setDegree] = useState<string>("");
  const [studyName, setStudyName] = useState<string>("");
  const [funFact, setFunFact] = useState<string>(selectedConsultant?.funFact || "");
  const [uploadProgressCv, setUploadProgressCv] = useState<number>(0);
  const [uploadProgressImage, setUploadProgressImage] = useState<number>(0);
  const [error, setError] = useState<string | null | undefined>(null);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const consultantId = selectedConsultant?.consultantId || 0;
  const imageUrl = selectedConsultant?.imageUrl || null;
  const cvUrl = selectedConsultant?.cvUrl || null;
  let cvFileName = "";
  let imageFileName = "";

  const urlToFileName = () => {
    const imagefileUrl = imageUrl;
    const cvfileUrl = cvUrl;
    if (!!imagefileUrl && !!cvfileUrl) {
      const imageFileUrlGroup = imagefileUrl.match(/\/([^\/?]+)\?/);
      const cvFileUrlpGroup = cvfileUrl.match(/\/([^\/?]+)\?/);

      cvFileName = cvFileUrlpGroup![1] 
      imageFileName = imageFileUrlGroup![1] 
 
  

      
      console.log("urlToFileName cvfileName: " + cvFileUrlpGroup![1]);
      console.log("urlToFileName ImageFileName: " + imageFileUrlGroup![1]);
    }
  };



  useEffect(() => {
    if (selectedConsultant?.education) {
      const [degree, studyName] = selectedConsultant.education.split(/\s+i\s+/);
      setDegree(degree || "");
      setStudyName(studyName || "");
    }
  }, [selectedConsultant]);


  useEffect(() => {
    setEducation(`${degree} i ${studyName}`);
  }, [degree, studyName]);

  useEffect(() => {
    if (consultantName && consultantEmail && specialization) {
      setRequiredFieldsFilled(true)
      setNameError(false)
      setEmailError(false)
    } else (setRequiredFieldsFilled(false))
  }, [consultantName, consultantEmail, specialization]);


  const handleEditConsultant = async () => {
    try {
      if (requiredFieldsFilled) {
        const consultantData = {
          consultantId,
          consultantName,
          description,
          cvFile,
          imageFile,
          consultantEmail,
          specialization,
          education,
          funFact,
        };
        await onEditConsultant(consultantData);
        onSave?.();
      } else {
        if (!consultantName) {
          setNameError(true)
        }
        if (!consultantEmail) {
          setEmailError(true)
        }
      }


    } catch (error) {
      setError("An error occurred while editing the consultant.");
    }
  };


  const setConsultantCv = (file: File | null) => {
    if (file !== null) {
      setConsultantCVFile(file);
    }
  };


  const setConsultantProfileImage = (file: File | null) => {
    if (file !== null) {
      setConsultantImageFile(file)
    }
  };

  

  return (
    <div className="bg-white shadow-md rounded w-full min-w-[35rem] pr-5">
      <ResponsiveScrollContainer>
        <div className="flex items-center justify-center">
          <div className="bg-white shadow-md rounded w-full min-w-[35rem] pr-5">
            <h2 className="text-2xl font-semibold text-left mb-6">Rediger konsulent</h2>
            <div className="mb-4">
              <CustomInput
                value={consultantName}
                textRed={nameError}
                onChange={(e) => setConsultantName(e.target.value)}
                label="Navn"
              />
              <div className={`text-xs text-left ${(nameError && consultantName !== selectedConsultant?.consultantName) ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
            </div>

            <div className="mb-4">
              <label className="text-left block mb-2 text-sm">Spesialisering
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="java"
                  name="specialization"
                  value="Java"
                  checked={specialization === "Java"}
                  onChange={(e) => setSpecialization(e.target.value)}
                  className="form-radio text-gray-400"
                />
                <label htmlFor="java" className="ml-2 text-sm">Java</label>

                <input
                  type="radio"
                  id="dotnet"
                  name="specialization"
                  value=".NET"
                  checked={specialization === ".NET"}
                  onChange={(e) => setSpecialization(e.target.value)}
                  className="ml-4 form-radio text-gray-400"
                />
                <label htmlFor="dotnet" className="ml-2 text-sm">.NET</label>
              </div>
            </div>
            <div className="mb-4">
              <CustomInput
                value={consultantEmail}
                textRed={emailError}
                onChange={(e) => setEmail(e.target.value)}
                label="E-post"
              />
              <div className={`text-xs text-left ${(emailError && consultantEmail !== selectedConsultant?.consultantEmail) ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>

            </div>
            <label className="text-left block mb-2 text-sm">Last opp CV
            </label>

            <FileDropZoneCv setCv={setConsultantCv} />
            {(!!cvUrl &&
                cvFile === null) ||
              cvFile === undefined ? (
                <div>
                  <FileUpload
                    fileUrlReceived={!!cvUrl}
                    className="space-y-2"
                    fileName={
                      cvFileName
                        ? cvFileName
                        : cvUrl
                        ? cvUrl
                        : "no file exists"
                    }
                  />
                </div>
              ) : (
                <div>
                  {cvFile !== null &&
                  cvFile !== undefined ? (
                    <div>
                      <FileUpload
                        fileUrlReceived={!!cvUrl}
                        className="space-y-2"
                        fileName={
                          cvFile?.name
                            ? cvFile.name
                            : cvUrl
                            ? cvUrl
                            : "no file choosen"
                        }
                        fileSize={
                          cvFile
                            ? `${(cvFile.size / 1024).toFixed(
                                2
                              )} KB`
                            : ""
                        }
                        progress={uploadProgressCv}
                      />
                    </div>
                  ) : null}
                </div>
              )}

            {/* {cvFile !== null ? (
              <div>
                <FileUpload
                  className="space-y-2"
                  fileName={cvFile ? cvFile.name : "No file chosen"}
                  fileSize={cvFile ? `${(cvFile.size / 1024).toFixed(2)} KB` : ""}
                  progress={uploadProgress}
                />
              </div>
            ) : null} */}
         <label className="text-left block mb-2 text-sm">
            Last opp profilbilde
          </label>
          <FileDropZoneImage
            SetImage={setConsultantImageFile}
          />
          {(!!imageUrl && imageFile  === null) || imageFile === undefined ? (
            <div>
              <FileUpload
                fileUrlReceived={!!imageUrl}
                className="space-y-2"
                fileName={imageFileName ? imageFileName : imageUrl ? imageUrl : "no file exists"}
              />
            </div>
          ) : (
            <div>
              {imageFile !== null && imageFile !== undefined ? (
                <div>
                  <FileUpload
                    fileUrlReceived={!!imageUrl}
                    className="space-y-2"
                    fileName={imageFile?.name ? imageFile.name : imageUrl ? imageUrl : "no file chosen"}
                    fileSize={imageFile ? `${(imageFile.size / 1024).toFixed(2)} KB` : ""}
                    progress={uploadProgressImage}
                  />
                </div>
              ) : null}
            </div>
          )}

            <div className="mb-4">
              <br />
              <label htmlFor="education" className="text-left block mb-2 text-sm">
                Utdanning
              </label>
              <div className="relative">
                <select
                  id="education"
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  className="border border-gray-300 rounded-lg shadow-sm py-2 px-4 w-full text-sm placeholder-gray-400 focus:outline-none focus:border-gray-400 focus:ring-1 focus:ring-gray-400 appearance-none"
                >
                  <option value="">Velg...</option>
                  <option value="bachelorgrad">Bachelorgrad</option>
                  <option value="mastergrad">Mastergrad</option>
                  <option value="doktorgrad">Doktorgrad</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
              <br />
              <CustomInput
                value={studyName}
                onChange={(e) => setStudyName(e.target.value)}
                label="Tittel på utdanningen" />
            </div>

            <div className="mb-4">
              <label htmlFor="candidateDescription" className="text-left block mb-2 text-sm">
                Sammendrag
              </label>
              <textarea
                id="candidateDescription"
                value={description}
                onChange={(e) => setConsultantDescription(e.target.value)}
                rows={4}
                placeholder="Kort sammendrag av konsulenten"
                className="border border-gray-300 rounded-lg shadow-sm py-2 px-4 w-full text-sm placeholder-gray-400 focus:outline-none focus:border-gray-400 focus:ring-1 focus:ring-gray-400"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="funFact" className="text-left block mb-2 text-sm">
                Fun fact
              </label>
              <textarea
                id="funFact"
                value={funFact}
                onChange={(e) => setFunFact(e.target.value)}
                rows={4}
                placeholder="F.eks: Svømt med hai i Cape Town"
                className="border border-gray-300 rounded-lg shadow-sm py-2 px-4 w-full text-sm placeholder-gray-400 focus:outline-none focus:border-gray-400 focus:ring-1 focus:ring-gray-400"
              />
            </div>

            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="flex justify-end my-6">
              <button onClick={handleEditConsultant}
                className={`${requiredFieldsFilled ? "bg-brandBlueDark border-brandBlueDark hover:bg-brandWhite hover:text-brandBlueDark cursor-pointer" : "bg-[#696969] cursor-not-allowed"}  text-white border  w-24 px-4 py-2 rounded `}
              > Lagre
              </button>
            </div>
          </div>
        </div>
      </ResponsiveScrollContainer>
    </div>
  );
};


export default EditConsultantForm;