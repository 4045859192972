// ConsultantListSection.tsx
import React, { useEffect, useRef, useState } from "react";
import InputField from "./InputField";
import FilterDropdownButton from "./FilterDropdownButton";
import Popup from "./Popup";
import ConsultantFilterAdmin from "../Admin/schedule/ConsultantFilterAdmin";
import ConsultantFilterCustomer from "../customer/ConsultantFilterCustomer";
import { FaSortAlphaUp } from "react-icons/fa";
import { ConsultantDetails as ConsultantAdmin } from "../../types/consultantTypes";
import { ConsultantMeeting as ConsultantCustomer } from "../../types/consultantTypes";
import ConsultantFilterPopup from "./ConsultantFilterPopup";
import { SortOrder, sortByKey, sortConsultantsByProgress, sortConsultantsByScore } from "../../utils/SortingUtils";
import { SpecializationFilter, specializationFilters } from "../../types/filterTypes";

interface FilterBarProps {
  role: string;
  consultants: ConsultantAdmin[] | ConsultantCustomer[];
  filteredConsultants: ConsultantAdmin[] | ConsultantCustomer[];
  setFilteredConsultants: (
    filteredResults: ConsultantAdmin[] | ConsultantCustomer[]
  ) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  role,
  consultants,
  filteredConsultants,
  setFilteredConsultants,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [sortKey, setSortKey] = useState<keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress">("consultantName");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [activeFilter, setActiveFilter] = useState<Record<string, boolean>>(
    specializationFilters.reduce(
      (acc, specialization) => ({
          ...acc,
          [specialization]: false,
      }),{}
    )
  );
 
  const [isSortPopupOpen, setIsSortPopupOpen] = useState(false);
  const sortPopupRef = useRef<HTMLDivElement>(null);
  const sortPopupButtonRef = useRef<HTMLDivElement>(null);

  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const filterPopupRef = useRef<HTMLDivElement>(null);
  const filterPopupButtonRef = useRef<HTMLDivElement>(null);

  

  const toggleSortPopup = () => {
    setIsSortPopupOpen(!isSortPopupOpen);
  };

  const closeSortPopup = () => {
    setIsSortPopupOpen(false);
  };

  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
  };

  const closeFilterPopup = () => {
    setIsFilterPopupOpen(false);
  };
  const handleSortChange = (_sortKey: keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress", _sortOrder: SortOrder) => {
    setSortKey(_sortKey);
    setSortOrder(_sortOrder);
  }
  const handleFilterChange = (specialization: SpecializationFilter) => {
    setActiveFilter((prevFilter) => ({
      ...prevFilter,
      [specialization]: !prevFilter[specialization]
    }))
  }

  /**
   * Close the popup when clicking outside of it
   */
  const handleClickOutsidePopup = (event: MouseEvent) => {
    if (
      isSortPopupOpen &&
      sortPopupRef.current &&
      !sortPopupRef.current.contains(event.target as Node) &&
      !sortPopupButtonRef.current?.contains(event.target as Node)
    ) {
      closeSortPopup();
    }
    if (
      isFilterPopupOpen &&
      filterPopupRef.current &&
      !filterPopupRef.current.contains(event.target as Node) &&
      !filterPopupButtonRef.current?.contains(event.target as Node)
    ) {
      closeFilterPopup();
    }
  };
  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutsidePopup);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePopup);
    };
  }, [isSortPopupOpen, isFilterPopupOpen]);

  /**
   * Filter the list of consultants based on the search filter
   */
  useEffect(() => {

    let foundConsultants: ConsultantAdmin[] | ConsultantCustomer[] = [];
    const allFalse: boolean = specializationFilters.map(specialization => activeFilter[specialization]).every(bool => bool === false)
    console.log(allFalse)
    if (role === "Admin") {
      foundConsultants = (consultants as ConsultantAdmin[]).filter(
        (consultant: ConsultantAdmin) => {
          if(activeFilter[consultant.specialization!] || allFalse){
            return consultant.consultantName
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase());
          }
        }
      );
    } else {
      foundConsultants = (consultants as ConsultantCustomer[]).filter(
        (consultant: ConsultantCustomer) => {
          if(activeFilter[consultant.specialization!]|| allFalse){
            return consultant.consultantName
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase());
          }
        }
      );
    }

    if(sortKey !== "score" && sortKey !== "progress"){
      foundConsultants = sortByKey<ConsultantAdmin | ConsultantCustomer>(foundConsultants, sortKey, sortOrder)
    } else if (sortKey === "score"){
      foundConsultants = sortConsultantsByScore(foundConsultants, sortOrder)
    } else if (sortKey === "progress"){
      foundConsultants = sortConsultantsByProgress(foundConsultants, sortOrder)
    }
    setFilteredConsultants(
      foundConsultants as ConsultantAdmin[] | ConsultantCustomer[]
    );
  }, [searchFilter, consultants, sortKey, sortOrder, activeFilter]);

  return (
    <div className="flex w-full gap-2 flex-col xl:flex-row justify-between pb-5 pt-2 max-w-[860px]">
      <div className="w-72 max-w-[450px]">
        <InputField
          value={searchFilter}
          label="Søk..."
          onChange={(event) => setSearchFilter(event.target.value)}
        />
      </div>
      <div className="flex gap-2">
        <FilterDropdownButton
          text="Sorter"
          onClick={toggleSortPopup}
          open={isSortPopupOpen}
          ref={sortPopupButtonRef}
          icon={<FaSortAlphaUp />}
          id="sortPopup"
        ></FilterDropdownButton>
        <Popup
          isOpen={isSortPopupOpen}
          onClose={closeSortPopup}
          ref={sortPopupRef}
          triggerId="sortPopup"
        >
          {role === "Admin" ? (
            <ConsultantFilterAdmin
              sortKey={sortKey}
              sortOrder={sortOrder}
              onFilterChange={handleSortChange}
            />
          ) : (
            <ConsultantFilterCustomer
            sortKey={sortKey}
            sortOrder={sortOrder}
              onFilterChange={handleSortChange}
            />
          )}
        </Popup>
        <FilterDropdownButton
          text="Filter"
          onClick={toggleFilterPopup}
          open={isFilterPopupOpen}
          ref={filterPopupButtonRef}
          id="filterPopup"
        ></FilterDropdownButton>
        <Popup
          isOpen={isFilterPopupOpen}
          onClose={closeFilterPopup}
          ref={filterPopupRef}
          triggerId="filterPopup"
        >
          <ConsultantFilterPopup
            activeFilter={activeFilter}
            onFilterChange={handleFilterChange}
          />
        </Popup>
      </div>
    </div>
  );
};

export default FilterBar;
