
import { ConsultantDetails as ConsultantAdmin } from "../types/consultantTypes";
import { ConsultantMeeting as ConsultantCustomer } from "../types/consultantTypes";

export type SortOrder = 'asc' | 'desc';

const instanceOfType = <T>(object: any): object is T => {
  return object
}

export const sortByKey = <T>(array: T[], key: keyof T, order: SortOrder = 'asc'): T[] =>
  [...array].sort((a, b) => {
    const valueA = String(a[key]);
    const valueB = String(b[key]);
    return order === 'asc' ? valueA.localeCompare(valueB, 'no') : valueB.localeCompare(valueA, 'no');
  });



export const sortConsultantsByScore = (array: ConsultantCustomer[] | ConsultantAdmin[], order: SortOrder = 'asc') => 
  [...array].sort((a, b) => {
    let toReturn = 0;
    let valueA: string = (order === "asc") ? "6" : "0";
    let valueB: string = (order === "asc") ? "6" : "0";

    //For Customer consultants
    if(instanceOfType<ConsultantCustomer>(a) && instanceOfType<ConsultantCustomer>(b)){
      if (a.interview && a.interview.score){
        valueA = String(a.interview.score);
      }
      if(b.interview && b.interview.score){
        valueB = String(b.interview.score);
      }
    }

    //For Admin consultants
    if(instanceOfType<ConsultantAdmin>(a) && instanceOfType<ConsultantAdmin>(b)){
      valueA = a.avgScore ? String(a.avgScore) : valueA ;
      valueB = b.avgScore ? String(b.avgScore) : valueB ;
    }
    
    toReturn = order === 'asc' ? valueA.localeCompare(valueB, 'no') : valueB.localeCompare(valueA, 'no');
    return toReturn;
  });
  

export const sortConsultantsByProgress = (array: ConsultantCustomer[] | ConsultantAdmin[], order: SortOrder = 'asc') => 
  [...array].sort((a, b) => {
    let toReturn = 0;
    let valueA: number = 0;
    let valueB: number = 0;

    //For Admin consultants
    if(instanceOfType<ConsultantAdmin>(a) && instanceOfType<ConsultantAdmin>(b)){
      valueA = a.progress ? a.progress : valueA ;
      valueB = b.progress ? b.progress : valueB ;
    }
    toReturn = order === 'asc' ? (valueA - valueB) : (valueB - valueA);
    return toReturn;
  });
