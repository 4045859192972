
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { CustomerManageUsers } from "../../types/types";

interface SelectedCustomerState {
  selectedCustomer: CustomerManageUsers | null;
}

const initialState: SelectedCustomerState = {
  selectedCustomer: null,
};

const selectedCustomerSlice = createSlice({
  name: "selectedCustomer",
  initialState,
  reducers: {
    selectCustomer: (state, action: PayloadAction<CustomerManageUsers | null>) => {
      state.selectedCustomer = action.payload;
    },
  },
});

export const { selectCustomer } = selectedCustomerSlice.actions;

// Selectors
export const selectSelectedCustomer = (state: RootState) =>
  state.selectedCustomer.selectedCustomer;

export default selectedCustomerSlice.reducer;
