import React, { useState } from "react";
import CustomerTable from "./CustomerTable";
import { FilterSlider } from "../../common/FilterSlider";
import { FaEdit, FaTrash } from "react-icons/fa";
import ConsultantTable from "./ConsultantTable";
import AdminTable from "./AdminTable";
import { ConsultantManageUsers, CustomerManageUsers, PageProps, Admin } from "../../../types/types";
import ManageUsersButton from "./ManageUsersButton";
import { useFetchData } from "../../../utils/FetchManageUsers";
import Modal from "../../common/Modal";
import { getDynamicLabelAddButton } from "../../../utils/LabelUtils";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useDispatch } from "react-redux";
import { selectCustomer } from "../../../redux/slices/SelectedCustomerSlice";
import { selectConsultant } from "../../../redux/slices/SelectedConsulentSlice";
import { selectAdmin } from "../../../redux/slices/SelectedAdminSlice";



const ManageUsersView: React.FC<PageProps> = (props) => {
  const [customers, setCustomers] = useState<CustomerManageUsers[]>([]);
  const [consultants, setConsultants] = useState<ConsultantManageUsers[]>([]);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [currentFilter, setCurrentFilter] = useState<string | null>("Kunder");
  const [modalContent, setModalContent] = useState<string>(""); 
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const tableHeader = `Administrer ${currentFilter?.toLocaleLowerCase() || "brukere"}`;

  const fetch = useFetchData({
    setAdmins,
    setCustomers,
    setConsultants,
    setLoading,
  });



  const handleUserClick = (actionType: string) => {
    setIsOpen(true);
    setModalContent(currentFilter + actionType);    
  };

  const customerActions = (customer: CustomerManageUsers, actionType: string) => {
    dispatch(selectCustomer(customer));
    handleUserClick(actionType);
  }

  const consultantActions = (consultant: ConsultantManageUsers, actionType: string) => {
    dispatch(selectConsultant(consultant));
    handleUserClick(actionType);
  }

  const adminActions = (admin: Admin, actionType: string) => {
    dispatch(selectAdmin(admin));
    handleUserClick(actionType);
  }


const selectedAdd = () => {
setIsOpen(true);
setModalContent(currentFilter + "Add");
}

const handleSave = () => {
  setIsOpen(false);
  setTimeout(() => {
    fetch();
      }, 100); 
};


  return (
    <div className="text-left pt-6 px-16">
      <div className="mb-3 w-1/3">
        <FilterSlider
          filterOptions={["Kunder", "Konsulenter", "Admin"]}
          onFilterChange={(filter) => {
            setCurrentFilter(filter);
          }}
          sliderParentDivStyling="h-10 rounded-md"
          sliderPaddingClass="py-3"
        />
      </div>

      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">{tableHeader}</h1>
        <div className="mr-2">   <ManageUsersButton
          label={getDynamicLabelAddButton(currentFilter)}
          onClick={() => selectedAdd()}
        /></div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-screen pb-80">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Modal
            isOpen={isOpen}
            modalContent={modalContent}
            closeModal={() => setIsOpen(false)}
            onSave={handleSave}
          />

          <div>

             <div className={`pb-1 ml-4 ${currentFilter === "Konsulenter" ? null : "text-transparent"}` }>{consultants.length} konsulenter</div>
          
            {/* Display table based on the current filter */}
            {currentFilter === "Kunder" && (
              <>
                <CustomerTable
                  customers={customers}
                  onEdit={(customer) => customerActions(customer, "Edit")}
                  onDelete={(customer) =>
                    customerActions(customer, "Delete")
                  }
                  editIcon={FaEdit}
                  deleteIcon={FaTrash}
                />
              </>
            )}
            {currentFilter === "Konsulenter" && (
              <>
                <ConsultantTable
                  consultants={consultants}
                  onEdit={(consultant) =>
                    consultantActions(consultant, "Edit")
                  }
                  onDelete={(consultant) =>
                    consultantActions(consultant, "Delete")
                    }
                  editIcon={FaEdit}
                  deleteIcon={FaTrash}
                />
              </>
            )}
            {currentFilter === "Admin" && (
              <>
                <AdminTable
                  admins={admins}
                  onEdit={(admin) => adminActions(admin, "Edit")}
                  onDelete={(admin) => 
                    adminActions(admin, "Delete")
                  }
                  editIcon={FaEdit}
                  deleteIcon={FaTrash}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ManageUsersView;