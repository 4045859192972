import React, { useState, useEffect } from "react";
import AllocationGrid from "./AllocationGrid";
import { ScheduleEditor } from "./ScheduleEditor";
import ScheduleCreationModal from "./ScheduleCreationModal";
import { SelectCustomerConsultantUnit } from "../../../types/types";
import { fetchUsersTimeschedule } from "../../../api/adminApi";
import { FinishedSchedule } from "./FinishedSchedule";
import CustomStepper from "../../common/CustomStepper";
import SnackBarCustom from "../../common/SnackBarCustom";
import LoadingSpinner from "../../common/LoadingSpinner";

const ScheduleContainer: React.FC = () => {
  const [customers, setCustomers] = useState<SelectCustomerConsultantUnit[]>([]);
  const [consultants, setConsultants] = useState<SelectCustomerConsultantUnit[]>([]);
  const [allInterviews, setAllInterviews] = useState<{ Customer: number; Candidates: number[] }[]>([]);
  const [stepNumber, setStepNumber] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasSchedule, setHasSchedule] = useState(false);
  const loadScheduleData = async () => {
    try {
      setLoading(true);
      const response = await fetchUsersTimeschedule();
      if (response) {
        const sortedCustomers = [...response.customers].sort((a, b) => {
          const specA = a.speciality || '';
          const specB = b.speciality || '';
          return specA.localeCompare(specB);
        });
        setCustomers(sortedCustomers);
        setConsultants(response.consultants);
        setError(null);
      }
      console.log("data fetched");
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(
        "Det oppstod en feil ved lasting av data. Om problemet vedvarer, kontakt support."
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadScheduleData();
  }, []);

  const handleStepChange = (stepNumber: number) => {
    if (stepNumber !== 4) {
      setStepNumber(stepNumber + 1);

    }

    else {
      stepNumber === 4 &&
        setStepNumber(stepNumber - 1);
    }
    console.log(stepNumber);

  };

  const updateInterviews = (
    customerId: number,
    consultantId: number,
    checked: boolean
  ) => {
    setAllInterviews((prev) => {
      let prev_currentcustomer = prev.find((p) => p.Customer === customerId);
      if (!checked) {
        const currentIndex = prev_currentcustomer?.Candidates.findIndex(
          (c) => c === consultantId
        );
        if (currentIndex !== undefined && currentIndex > -1) {
          prev_currentcustomer?.Candidates.splice(currentIndex, 1);
        }
      } else if (
        prev_currentcustomer &&
        !prev_currentcustomer.Candidates.includes(consultantId)
      ) {
        prev_currentcustomer.Candidates.push(consultantId);
      }
      return [...prev];
    });
  };

  useEffect(() => {
    loadScheduleData();
  }, []);

  useEffect(() => {
    const initValue = customers.map((customer) => {
      const customerId = customer.id;
      return { Customer: customerId, Candidates: [] };
    });
    setAllInterviews(initValue);
  }, [customers]);

  const components = [
    loading ? (
      <div className="flex justify-center items-center pt-20">
        <LoadingSpinner />
      </div>
    ) : (
      <AllocationGrid
        props={{
          Customers: customers,
          Consultants: consultants,
          onConfirm: () => setStepNumber(2),
          onInterviewUpdate: updateInterviews,
          allInterviews: allInterviews,
        }}
      />
    ),
    <ScheduleCreationModal
      // onSave={() => setStepNumber(3)}
      onCancel={() => setStepNumber(1)}
      allInterviews={allInterviews}
      customers={customers}
      onHandleChange={() => handleStepChange(stepNumber)}
    />,
    <ScheduleEditor onHandleChange={() => handleStepChange(stepNumber)} />,
    <FinishedSchedule onHandleChange={() => handleStepChange(stepNumber)}  newScheduleFunction={handleStepChange} setHasSchedule={setHasSchedule} />,
  ];

  const renderFooter = () => (
    <>{
      (stepNumber === 1 || stepNumber === 2) &&
      <button
        onClick={() => handleStepChange(stepNumber)}
        className="text-white bg-blue-600 hover:bg-blue-700 px-5 py-2 rounded-lg shadow-lg"
      >
        Fortsett
      </button>}
    </>
  );
  return (
    <div className="h-full ">
      {error && (
        <SnackBarCustom message={error} color="danger" align="top-center" />
      )}

      <CustomStepper stepNumber={stepNumber} onStepChange={setStepNumber} footer={renderFooter} hasSchedule={hasSchedule}>
        <>{components[stepNumber - 1]}
          </>
      </CustomStepper>

    </div>
  );
};

export default ScheduleContainer;
