import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Redux Store
 *
 * Creates the Redux store using the root reducer.
 *
 * @constant
 * @type {Store<RootState>}
 * @example
 * import store from './path-to-your-store';
 * // ...
 * <Provider store={store}>
      <App />
    </Provider>
 */
const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
