import React, { useState } from "react";
import {
  ScheduleConsultant as Consultant,
  NewInterviewSlot,
  ScheduleInterview as Interview,
} from "../../../types/types";
import { Button } from "../../common/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { MdClose } from "react-icons/md";

interface AddInterviewModalProps {
  active: string;
  consultants: Consultant[];
  newInterview: NewInterviewSlot;
  onNewInterview: (
    newInterview: NewInterviewSlot,
    selectedConsultant: Consultant
  ) => void;
  setVisibleModal: React.Dispatch<React.SetStateAction<string>>;
  currentDisplay: {
    date: string;
    interviews: Interview[];
  };
}

export const AddInterviewModal: React.FC<AddInterviewModalProps> = ({
  active,
  consultants,
  newInterview,
  onNewInterview,
  setVisibleModal,
  currentDisplay,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState<Consultant>();

  const filteredConsultants = consultants.filter((consultant) => {
    const searchTerm = searchInput.toLowerCase();
    const consultantName = consultant.consultantName.toLowerCase();

    return (
      consultantName.includes(searchTerm) &&
      !currentDisplay.interviews.some(
        (interview) =>
          interview.consultantId === consultant.consultantId &&
           (
            interview.customerId === (newInterview?.customerId ?? 0) ||
            interview.date === (newInterview?.date ?? "") &&
           interview.time === (newInterview?.time ?? ""))
          
      )
    );
  });

  const startTime = newInterview?.time;
  const endTime = newInterview?.date;

  const handleClose = () => {
    setVisibleModal("");
  };

  const handleAddInterview = () => {
    if (newInterview && selectedConsultant) {
      onNewInterview(newInterview, selectedConsultant);
      handleClose();
    } else {
      setVisibleModal("");
      alert(
        "En feil har oppstått, vennligst lagre endringene dine eller oppdater siden."
      );
    }
  };
  

  return (
    <Dialog open={active === "addNew"} onClose={handleClose}   >
      <DialogTitle
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <DialogTitle>Legg til konsulent</DialogTitle>
        <IconButton aria-label="delete" size="large">
          <MdClose fontSize="inherit" onClick={handleClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {startTime} - {endTime}
        </DialogContentText>
        <Autocomplete
        sx={{ height: "50vh", marginTop: 1}}
          disablePortal
          fullWidth
          options={filteredConsultants}
          getOptionLabel={(option) => option.consultantName}
          value={selectedConsultant}
          onChange={(_, value) => {
            setSelectedConsultant(value || undefined);
          }}
          PaperComponent={({ children }) => <Paper>{children}</Paper>}
          renderInput={(params) => (
            <TextField {...params} label="Velg konsulent" />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button width="w-fit" text="Legg til" onClick={handleAddInterview} />
      </DialogActions>
    </Dialog>
  );
};
