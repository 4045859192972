import React, { useState } from 'react';
import { FaEdit, FaTrash, FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import { CustomerManageUsers } from '../../../types/types';
import { sortByKey } from '../../../utils/SortingUtils';
import ResponsiveScrollContainer from '../../common/ResponsiveScrollContainer';


interface CustomerTableProps {
  customers: CustomerManageUsers[];
  onEdit: (customer: CustomerManageUsers) => void;
  onDelete: (customer: CustomerManageUsers) => void;
  editIcon: any;
  deleteIcon: any;
}

const CustomerTable: React.FC<CustomerTableProps> = ({ customers, onEdit, onDelete }) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const sortedCustomers = sortByKey(customers, 'customerName', sortOrder);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  }

  return (
    <ResponsiveScrollContainer>

    <div className="min-w-full border border-gray-300">
      <div className="max-h-[430px]">
        <table className="w-full">
          <thead className="bg-brandBlueDark text-white sticky top-0 z-10">
            <tr className='normal-case text-left'>
              <th className="px-4 py-3 text-left">
                <button onClick={toggleSortOrder} className="flex items-center">
                  <span className="mr-2">Navn</span>
                  {sortOrder === 'asc' ? <FaSortAlphaUp className='fill-white' /> : <FaSortAlphaDown className='fill-white' />}
                </button>
              </th>
              <th className="px-4 py-3 text-left">Spesialisering</th>
              <th className="px-4 py-3 text-left">E-post</th>
              <th className="px-4 py-3 text-left">Kontakt</th>
              <th className="px-4 py-3 text-left">Ansvarlig Selger</th>
              <th className="px-4 py-3 text-left">
                <button onClick={toggleSortOrder}>Utløper</button>
              </th>
              <th className="px-4 py-3 text-center">Rediger</th>
              <th className="px-4 py-3 text-center">Slett</th>
            </tr>
          </thead>
          <tbody>
            {sortedCustomers.map((customer: CustomerManageUsers) => (
              <tr key={customer.customerId} className="odd:bg-gray-200">
                <td className="px-4 py-2 text-left">{customer.customerName}</td>
                <td className="px-4 py-2 text-left">{customer.desiredSpecialization}</td>
                <td className="px-4 py-2 text-left">{customer.customerEmail}</td>
                <td className="px-4 py-2 text-left">{customer.customerContactPerson}</td>
                <td className="px-4 py-2 text-left">{customer.responsibleAdmin}</td>
                <td className="px-4 py-2 text-left">mangler</td>
                <td className="px-4 py-2 text-center">
                  <button onClick={() => onEdit(customer)}>
                    <FaEdit />
                  </button>
                </td>
                <td className="px-4 py-2 text-center text-[#D04646]">
                  <button onClick={() => onDelete(customer)}>
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </ResponsiveScrollContainer>
  );
};

export default CustomerTable;