import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Card, { CardProps } from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
 
import {
  MdInsertDriveFile,
  MdOutlineCheck,
  MdRemoveCircleOutline,
} from "react-icons/md";
 
export default function FileUpload(
  props: CardProps & {
    icon?: React.ReactElement;
    fileName: string;
    fileSize?: string;
    progress?: number;
    fileUrlReceived?: boolean;
  }
) {
  const { icon, fileName, fileSize, progress, fileUrlReceived, sx, ...other } =
    props;
  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      {...other}
      sx={[
        {
          gap: 1.5,
          alignItems: "flex-start",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AspectRatio
        ratio="1"
        variant="soft"
        color="neutral"
        sx={{
          minWidth: 32,
          borderRadius: "50%",
          "--Icon-fontSize": "16px",
        }}
      >
        <div>{icon ?? <MdInsertDriveFile />}</div>
      </AspectRatio>
      <CardContent>
        <Typography fontSize="xs">{fileName}</Typography>
        <Typography level="body-xs">{fileSize}</Typography>
        
        {progress! < 100 ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}> 
          {/* <Typography fontSize="xs">{progress}%</Typography>
          <LinearProgress
            color="neutral"
            value={progress}
            determinate
            sx={[
              {
                ...(progress! >= 100 && {
                  color: "var(--joy-palette-success-solidBg)",
                }),
              },
            ]}
          /> */}
          </Box>
          ) : null}
      </CardContent>
      {progress! >= 100 || fileUrlReceived === true ? (
        <AspectRatio
          ratio="1"
          variant="solid"
          color="success"
          sx={{
            minWidth: 20,
            borderRadius: "50%",
            "--Icon-fontSize": "14px",
          }}
        >
          <div>
            <MdOutlineCheck />
          </div>
        </AspectRatio>
      ) : (
        <IconButton
          variant="plain"
          color="danger"
          size="sm"
          sx={{ mt: -1, mr: -1 }}
        >
          <MdRemoveCircleOutline />
        </IconButton>
        // <IconButton
        //   variant="plain"
        //   color="danger"
        //   size="sm"
        //   sx={{ mt: -1, mr: -1 }}
        // >
        //   <MdRemoveCircleOutline />
        // </IconButton>
      )}
    </Card>
  );
}