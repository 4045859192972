import { ReactNode, useEffect, useState } from "react";
import { scoreLevels } from "../../types/ScoreLevelData";
import { CustomerAdminConsultants as Customer } from "../../types/customerTypes";
import { IoIosArrowDown } from "react-icons/io";

interface ConsultantScoreCardProps {
  customer: Customer;
  onClick?: () => void;
}

const ConsultantScoreCard: React.FC<ConsultantScoreCardProps> = ({
  customer,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /* Close card when selecting a new customer */
  useEffect(() => {
    setIsOpen(false);
  }, [customer]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    onClick && onClick();
  };

  const cardStyle = ` font-montserrat w-[315px] rounded-[10px] bg-brandGrayLight shadow-md min-h-[108px]
  ${customer.customerNotes && "cursor-pointer"}
  ${isOpen ? " " : " text-brandBlack"}`;

  const scoreStyle = `w-[71px] min-h-[108px] px-5 py-6 text-center ${
    customer.customerScore
      ? scoreLevels[customer.customerScore - 1].color
      : "bg-brandGray-light text-brandWhite"
  } ${isOpen ? "rounded-br-lg rounded-tl-lg" : "rounded-l-md"}`;

  const imgStyle = `my-auto ${isOpen && "rotate-180"}`;

  return (
    <div
      className={cardStyle}
      onClick={customer.customerNotes ? handleClick : undefined}
    >
      <div className="flex">
        <div className={scoreStyle}>
          {customer.customerScore && (
            <div>
              <p className=" text-[40px] font-medium leading-none">
                {customer.customerScore}
              </p>
              <p className=" text-xs font-normal">Score</p>
            </div>
          )}
        </div>

        <div className="flex">
          <div className="w-52 p-4 text-left break-words">
            <p className=" text-base font-bold">{customer.customerName}</p>
            <p className=" text-xs font-bold">{customer.customerContactPerson}</p>
            <p className=" text-xs font-normal">{customer.customerEmail}</p>
            <p className=" text-xs font-normal">{customer.phoneNumber}</p>
          </div>

          {customer.customerNotes && (
            <IoIosArrowDown className={imgStyle} size={25} />
          )}
        </div>
      </div>

      {isOpen && (
        <div
          className=" max-h-[100px] w-full p-4 text-xs font-normal overflow-auto"
          dangerouslySetInnerHTML={{ __html: customer.customerNotes }}
        />
      )}
    </div>
  );
};

export default ConsultantScoreCard;
