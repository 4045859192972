import * as ExcelJS from "exceljs";
import {
  Timeslot,
  ScheduleInterview as Interview,
  ScheduleCustomer as Customer,
} from "../types/types";

function generateExcelData(data: ScheduleData): any[][] {
  const rows: any[][] = [];

  data.uniqueDays.forEach((day) => {
    const dayRow = [
      {
        value: day,
        style: {
          font: { bold: true },
          alignment: { horizontal: "center" },
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFE6E6E6" },
          },
        },
      },
    ];
    rows.push(dayRow);

    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE6E6E6" },
      },
    };
    const headerRow = [
      {
        value: "",
        style: headerStyle,
      },
      ...data.customer.map((customer) => ({
        value: customer.customerName,
        style: headerStyle,
      })),
    ];
    rows.push(headerRow);

    data.timeSlots.map((timeSlot, index) => {
      const consultantRow: any = [];
      const rowColor = index % 2 === 0 ? "FFffffff" : "FFe9e9e9";
      const timeSlotRow = {
        value: timeSlot.from,
        style: {
          font: { bold: true },
          alignment: { horizontal: "center" },
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: rowColor },
          },
        },
      };
      consultantRow.push(timeSlotRow);

      data.customer.map((customer) => {
        const interview = data.interviews.find(
          (interview) =>
            interview.date === day &&
            interview.time === timeSlot.from &&
            interview.customerId === customer.customerId
        );
        if (timeSlot.type === "pause") {
          const cell = {
            value: "Pause",
            style: {
              alignment: { horizontal: "center" },
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFEA7E23" },
              },
            },
          };
          consultantRow.push(cell);
        } else if (interview) {
          const consultantNameCell = {
            value: interview.consultantName,
            style: {
              font: { bold: true },
              alignment: { horizontal: "center" },
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: rowColor },
              },
            },
          };
          consultantRow.push(consultantNameCell);
        } else {
          const cell = {
            value: "Ledig tid",
            style: {
              alignment: { horizontal: "center" },
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFd1ffbd" },
              },
            },
          };
          consultantRow.push(cell);
        }
      });
      rows.push(consultantRow);
    });

    rows.push([]);
  });

  return rows;
}
type ScheduleData = {
  customer: Customer[];
  timeSlots: Timeslot[];
  interviews: Interview[];
  uniqueDays: string[];
};
export function exportScheduleToExcel(data: ScheduleData): void {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("ScheduleData");
  const rows = generateExcelData(data);

  rows.forEach((row, rowIndex) => {
    row.forEach((cell, columnIndex) => {
      const excelCell = worksheet.getCell(rowIndex + 1, columnIndex + 1);
      if (typeof cell === "object" && cell.style) {
        excelCell.value = cell.value;
        excelCell.style = cell.style;
      } else {
        worksheet.getCell(rowIndex + 1, columnIndex + 1).value = cell;
      }
      if (cell.value) {
        excelCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Schedule.xlsx";
    link.click();
  });
}
