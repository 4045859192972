import React, { useState } from "react";
import Button from '@mui/joy/Button';
import { MdOutlineDeleteForever } from "react-icons/md";
interface ButtonProps {
    text: string;
    onClick?: () => void;
}

export const DeleteButton: React.FC<ButtonProps> = ({
    text,
    onClick
}) => {

    return (
        <Button
            variant="outlined"
            color="danger"
            endDecorator={<MdOutlineDeleteForever />}
            onClick={onClick}
        >
            {text}
        </Button >
    );
};
