import React, { useEffect } from "react";
import { useState } from "react";
import {
  ScheduleInterview as Interview,
  ScheduleConsultant as Consultant,
  ScheduleCustomer as Customer,
  NewInterviewSlot,
  Timeslot,
} from "../../../types/types";
import { InterviewModal } from "./InterviewModal";
import { FilterSlider } from "../../common/FilterSlider";
import { AddInterviewModal } from "./AddInterviewModal";
import { ScheduleRow } from "./ScheduleRow";
import { ScheduleBreak } from "./ScheduleBreak";
import {
  fetchAllInterviews,
  createInterview,
  deleteInterview,
  updateInterview,
} from "../../../api/adminApi";
import { removeDuplicates } from "../../../utils/ArrayHelper";
import { Button } from "../../common/Button";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SnackBarCustom from "../../common/SnackBarCustom";
import LoadingSpinner from "../../common/LoadingSpinner";
import zIndex from "@mui/material/styles/zIndex";

interface ScheduleEditorProps {
  onHandleChange: Function;
}

export const ScheduleEditor: React.FC<ScheduleEditorProps> = ({
  onHandleChange,
}) => {
  const [selectedInterview, setSelectedInterview] = useState<Interview | null>(
    null
  );
  const [modalVisible, setVisibleModal] = useState<string>("");
  const [toogledConsultant, setToogledConsultant] = useState<number>(-1);
  const [newInterview, setNewInterview] = useState<NewInterviewSlot>();
  const [consultants, setConsultants] = useState<Consultant[]>([]);
  const [days, setDays] = useState<string[]>([]);
  const [times, setTimes] = useState<Timeslot[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [currentDisplay, setCurrentDisplay] = useState<{
    date: string;
    interviews: Interview[];
  }>({ date: "", interviews: [] });
  const [interviewsInDb, setInterviewsInDb] = useState<Interview[]>([]);
  const [interviewsCurrent, setInterviewsCurrent] = useState<Interview[]>([]);
  const [nextInterviewId, setNextInterviewId] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(true);
  //Fetches all interviews
  const fetchData = async () => {
    try {
      setConfirmationMessage(null);
      setErrorMessage(null);

      const result = await fetchAllInterviews();

      if (result) {
        const { responseInterviews, responseConsultants, responseTimes } =
          result;
        setConsultants(responseConsultants);

        const uniqueCustomers = removeDuplicates(
          responseInterviews,
          "customerId"
        );
        /// Henter kun ut dager fra 2024 pga. en bug hvor intervjeur uten tid blir satt til år 1970 og tuller emd datoene som vises.
        const uniqueDays = Array.from(
          new Set(
            responseInterviews
              .filter((int) => int.date.split("/")[2] === "2024")
              .map((int) => int.date)
          )
        );
        /// Tar utgangspunkt i at alle dagene har samme oppsett. Ikke ideelt og bør forbedres på sikt.
        const firstDay = responseTimes[0].date;
        const uniqueTimes = responseTimes
          .filter((timeslot) => timeslot.date === firstDay)
          .map((time) => ({ from: time.time, to: "", type: time.infoslot }));

        setCustomers(uniqueCustomers);
        setDays(uniqueDays);

        setCurrentDisplay({ date: uniqueDays[0], interviews: [] });

        setTimes(
          uniqueTimes //responseTimes.map((time) => ({ from: time.time, to: "", type: time.infoslot }))
        );

        setInterviewsInDb(responseInterviews);
        setInterviewsCurrent(responseInterviews);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  function changeDay(newDay: string) {
    const newActiveInterviews: Interview[] = interviewsCurrent.filter(
      (int) => int.date === newDay
    );
    setCurrentDisplay({ date: newDay, interviews: newActiveInterviews });
  }

  // Sjekk om det er et intervju der den blir sluppet, og hvis det er bytt tid på de
  // Ingen av konsulenten har konflikt med nytt tidspunkt
  // Interjvuet blir sluppet på samme kunde som det tilhører

  function checkConsultantConflict(
    interviewId: number,
    consultantId: number,
    time: string
  ) {
    const conflict = currentDisplay.interviews.some(
      (interview) =>
        interview.interviewId !== interviewId &&
        interview.consultantId === consultantId &&
        interview.time === time
    );
    return conflict;
  }

  function handleOnDrop(
    e: React.DragEvent,
    timeSlot: Timeslot,
    customerId: number
  ) {
    e.preventDefault();
    const droppedInterviewId = parseInt(
      e.dataTransfer.getData("interviewId") as string
    );
    //Antar intervjuet kan bli sluppet
    let canDrop: boolean = true;

    const droppedInterview = currentDisplay.interviews.find(
      (int) => int.interviewId === droppedInterviewId
    );
    if (
      checkConsultantConflict(
        droppedInterview!.interviewId,
        droppedInterview!.consultantId,
        timeSlot.from
      )
    ) {
      setErrorMessage("konsulenten har allerede intervju på denne tiden");
      console.error(
        "dropped interview already have interview in this time slot"
      );
      canDrop = false;
    }

    const { date } = currentDisplay;

    const SwitchInterview = currentDisplay.interviews.find(
      (interview) =>
        interview.interviewId !== droppedInterviewId &&
        interview.time === timeSlot.from &&
        interview.customerId === customerId
    );
    if (droppedInterview?.customerId !== customerId) {
      setWarningMessage(
        "Konsulenten kan ikke overføres til en annen kunde, slett intervju, klikk på pluss ikonet og legg til der du vil legge til intervjuet"
      );
      canDrop = false;
    }

    if (SwitchInterview) {
      const canSwitch: boolean = !checkConsultantConflict(
        SwitchInterview.interviewId,
        SwitchInterview.consultantId,
        droppedInterview!.time
      );
      if (!canSwitch) {
        setErrorMessage(
          "intervjuene kan ikke bytte på grunn av konflikt med andre intervjuer"
        );
        canDrop = false;
      }
    }

    if (canDrop) {
      const updatedInterviews = currentDisplay.interviews.map((interview) => {
        if (interview.interviewId === droppedInterviewId) {
          return {
            ...interview,
            time: timeSlot.from,
            date,
            customerId,
          };
        } else if (
          SwitchInterview !== undefined &&
          interview.interviewId === SwitchInterview.interviewId
        ) {
          return {
            ...interview,
            time: droppedInterview!.time,
            date,
            customerId,
          };
        }
        if (
          SwitchInterview !== undefined &&
          interview.interviewId === SwitchInterview.interviewId
        ) {
          return {
            ...interview,
            time: droppedInterview!.time,
            date,
            customerId,
          };
        }
        return interview;
      });
      setCurrentDisplay({ ...currentDisplay, interviews: updatedInterviews });
      setInterviewsCurrent(updatedInterviews);
    } else {
      console.error("could not switch or update interview(s)");
    }
  }

  function handleDragOver(
    e: React.DragEvent,
    timeSlot: Timeslot,
    consultantId: number
  ) {
    e.preventDefault();
  }

  function handleSelectInterview(interview: Interview | null) {
    setSelectedInterview(interview);

    if (interview) {
      setVisibleModal("delete");
    } else {
      setVisibleModal("");
    }
  }

  /* Updates the state with interviews to be added on submit, 
     and temporarily adds the interview to the schedule */
  function handleNewInterview(
    newInterview: NewInterviewSlot,
    selectedConsultant: Consultant
  ) {
    const customer = customers.find(
      (c) => c.customerId === newInterview!.customerId
    );
    const customerName = customer ? customer.customerName : "";
    const interviewToAdd: Interview = {
      interviewId: nextInterviewId,
      date: currentDisplay.date,
      time: newInterview!.time,
      consultantId: selectedConsultant!.consultantId,
      consultantName: selectedConsultant!.consultantName,
      customerId: newInterview!.customerId,
      customerName: customerName,
    };
    setNextInterviewId((prev) => prev - 1);
    setInterviewsCurrent((prevInterviewsCurrent) => [
      ...prevInterviewsCurrent,
      interviewToAdd,
    ]);
  }

  /* Updates the state with interviews to be deleted on submit, 
     and temporarily removes the interview from the schedule. */
  function handleDeleteInterview(interviewId: number) {
    const interview: Interview | undefined = interviewsCurrent.find(
      (int) => int.interviewId === interviewId
    );
    if (interview !== undefined) {
      setInterviewsCurrent((prevInterviewsCurrent) =>
        prevInterviewsCurrent.filter(
          (interview) => interview.interviewId !== interviewId
        )
      );
      setVisibleModal("");
    }
  }

  /* Adds all consultant interviews to the state of interviews to be deleted,
     and temporarily removes them from the schedule. */
  function handleDeleteAllInterviews(
    consultantId: number,
    selectedDate: string
  ) {
    interviewsCurrent.forEach((interview) => {
      if (
        interview.consultantId === consultantId &&
        interview.date === selectedDate
      ) {
        handleDeleteInterview(interview.interviewId);
      }
    });
  }

  //Unødvendig komplisert? Kan vel bare sende customerId og Tid direkte til modalen sammen med aktiv dag
  //Sets the state of the new interview to be added, and opens the modal to choose consultant.
  async function openAddNewInterview(customerId: number, time: string) {
    await setNewInterview({
      customerId: customerId,
      time: time,
      date: currentDisplay.date,
    });

    setVisibleModal("addNew");
  }

  //Handles the submission of changes (added or removed interviews) by using the states
  const handleChanges = async () => {
    try {
      // Add new interviews
      interviewsCurrent.forEach((interview) => {
        if (interview.interviewId < 0) {
          createInterview({
            date: currentDisplay.date, //" 03/01/2024" Er en space foran på dato og tid i kallet
            time: interview.time, //" 10:20"
            customerId: interview.customerId,
            consultantId: interview.consultantId,
          });
        }
      });

      // Go through all interviews in database
      interviewsInDb.forEach((interviewInDb) => {
        const currentInterview: Interview | undefined = interviewsCurrent.find(
          (int) => int.interviewId === interviewInDb.interviewId
        );
        const interviewStillExists: boolean = currentInterview !== undefined;

        //Delete removed interviews
        if (!interviewStillExists) {
          deleteInterview(interviewInDb.interviewId);
        }

        //Update time if changed
        if (
          interviewStillExists &&
          currentInterview!.time !== interviewInDb.time
        ) {
          updateInterview({
            interviewId: interviewInDb.interviewId,
            newTime: currentInterview!.time,
          });
        }
      });

      setErrorMessage("");
      setConfirmationMessage(
        "Endringene dine er lagret. Genererer tidsskjema."
      );
      setInterviewsInDb(interviewsCurrent);
      setTimeout(() => {
        onHandleChange(4);
      }, 2000);
    } catch {
      setConfirmationMessage("");
      setErrorMessage("Ingen endringer er gjort.");
      setErrorMessage("Kunne ikke lagre endringene.");
    }
  };

  const headerCellStyle = {
    backgroundColor: "#1E3046",
    color: "#FFFFFF",
    overflow: "visible",
    padding: "0",
    zIndex: 31,
  };

  //Fetch data on render
  useEffect(() => {
    fetchData();
  }, []);

  //Update current display when currentInterviews is changed
  useEffect(() => {
    if (currentDisplay.date !== undefined) {
      const interviewsToDisplay: Interview[] = interviewsCurrent.filter(
        (int) => int.date === currentDisplay.date
      );
      setCurrentDisplay({
        ...currentDisplay,
        interviews: interviewsToDisplay,
      });
    }
  }, [interviewsCurrent]);

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {confirmationMessage && (
        <SnackBarCustom
          color="success"
          message={confirmationMessage}
          align="bottom-center"
        />
      )}
      {errorMessage && (
        <SnackBarCustom
          color="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      {warningMessage && (
        <SnackBarCustom
          color="warning"
          message={warningMessage}
          onClose={() => setWarningMessage("")}
        />
      )}
      <div className="w-1/5">
        <FilterSlider
          filterOptions={days}
          onFilterChange={changeDay}
          sliderParentDivStyling="rounded-t-md"
        />
      </div>
      <div className="border-x border-brandBlueDark rounded-tr-md">
        <TableContainer component={Paper} sx={{ maxHeight: "50vh" }}>
          <Table id="schedule" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{}}>
                <TableCell sx={headerCellStyle} />
                {customers.map((customer) => (
                  <TableCell key={customer.customerId} sx={headerCellStyle}>
                    <h3 className="font-normal text-base text-center ">
                      {customer.customerName}
                    </h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="overflow-x-auto">
              {times.map((timeSlot, index) => {
                let currentInterviews: Interview[] =
                  currentDisplay.interviews.filter(
                    (int) => int.time === timeSlot.from
                  );
                return timeSlot.type === "pause" ? (
                  <ScheduleBreak timeSlot={timeSlot} width={customers.length} />
                ) : (
                  <ScheduleRow
                    key={timeSlot.from + index}
                    Customers={customers}
                    Interviews={currentInterviews}
                    timeSlot={timeSlot}
                    toogledConsultant={toogledConsultant}
                    setToogledConsultant={setToogledConsultant}
                    handleOnDrop={handleOnDrop}
                    handleDragOver={handleDragOver}
                    handleSelectInterview={handleSelectInterview}
                    openAddNewInterviewFunction={openAddNewInterview}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="flex justify-end flex-col items-end bottom-5 end-10 z-10 fixed">
        <Button
          text="Lagre Skjema"
          type="button"
          onClick={handleChanges}
          width="fit"
        />
      </div>

      <InterviewModal
        active={modalVisible}
        consultants={consultants}
        selectedInterview={selectedInterview}
        setVisibleModal={setVisibleModal}
        onDeleteInterview={handleDeleteInterview}
        onDeleteAllInterviews={handleDeleteAllInterviews}
      />
      <AddInterviewModal
        active={modalVisible}
        consultants={consultants}
        newInterview={newInterview!}
        currentDisplay={currentDisplay}
        onNewInterview={handleNewInterview}
        setVisibleModal={setVisibleModal}
      />
    </>
  );
};

export default ScheduleEditor;
