import React, { useState } from 'react';
import { ConsultantManageUsers } from '../../../types/consultantTypes';
import { FaSortAlphaUp, FaSortAlphaDown, FaSortAmountDown, FaSortAmountUp, FaEdit, FaTrash } from "react-icons/fa";

interface ConsultantTableProps {
  consultants: ConsultantManageUsers[];
  onEdit: (consultant: ConsultantManageUsers) => void;
  onDelete: (consultant: ConsultantManageUsers) => void;
  editIcon: any;
  deleteIcon: any;
}

const ConsultantTable: React.FC<ConsultantTableProps> = ({ consultants, onEdit, onDelete }) => {
  const [sortOrderName, setSortOrderName] = useState<'asc' | 'desc'>('asc');
  const [sortOrderWithoutImage, setSortOrderWithoutImage] = useState<'asc' | 'desc'>('desc');
  const [sortOrderWithoutCv, setSortOrderWithoutCv] = useState<'asc' | 'desc'>('desc');
  const [sortOrderWithoutDescription, setSortOrderWithoutDescription] = useState<'asc' | 'desc'>('desc');
  const [lastClick, setLastClick] = useState<string>('name'); 

  const sortedConsultants = [...consultants].sort((a, b) => {
    if (lastClick === 'name') {
      // Split full names into an array of first and last names
      const splitA = a.consultantName.split(' ');
      const splitB = b.consultantName.split(' ');
  
      // Compare last names
      const lastNameComparison = splitA[splitA.length - 1].localeCompare(splitB[splitB.length - 1]);
  
      // If last names are equal, compare first names
      return lastNameComparison === 0
        ? splitA[0].localeCompare(splitB[0])
        : sortOrderName === 'asc'
          ? lastNameComparison
          : -lastNameComparison;
    } else if (lastClick === 'withoutImage') {
      return sortOrderWithoutImage === 'asc' ? (!a.imageUrl ? -1 : 1) : (!b.imageUrl ? -1 : 1);
    } else if (lastClick === 'withoutCv') {
      return sortOrderWithoutCv === 'asc' ? (!a.cvUrl ? -1 : 1) : (!b.cvUrl ? -1 : 1);
    } else if (lastClick === 'withoutDescription') {
      return sortOrderWithoutDescription === 'asc' ? (!a.description ? -1 : 1) : (!b.description ? -1 : 1);
    }
    return a.consultantName.localeCompare(b.consultantName);
  });

  const toggleSortOrder = (field: string, stateSetter: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>, otherSetters: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>[]) => {
    setLastClick(field);
    stateSetter((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    otherSetters.forEach(setter => setter('desc'));
  };  

  return (
    <div className="min-w-full border border-gray-300 overflow-hidden">
      <div className="max-h-[430px] overflow-y-auto">
        <table className="min-w-full">
          <thead className="bg-brandBlueDark text-white sticky top-0 z-10">
            <tr className='normal-case'>
              <th className="px-4 py-3 text-left">
                <button onClick={() => toggleSortOrder('name', setSortOrderName, [setSortOrderWithoutImage, setSortOrderWithoutCv, setSortOrderWithoutDescription])} className="flex items-center">
                  <span className={`mr-1 ${lastClick === 'name' ? "underline-offset-2 underline" : null}`}>Navn</span>
                  {lastClick === 'name' ? (sortOrderName === 'asc' ? <FaSortAlphaUp className='fill-white ml-1' /> : <FaSortAlphaDown className='fill-white ml-1' />) : <FaSortAlphaUp className='fill-gray-500 ml-1' />}
                </button>
              </th>
              <th className="px-4 py-3 text-left">Spesialisering</th>
              <th className="px-4 py-3 text-left">Mail</th>
              <th className="px-4 py-3 text-left">
                <button onClick={() => toggleSortOrder('withoutImage', setSortOrderWithoutImage, [setSortOrderName, setSortOrderWithoutCv, setSortOrderWithoutDescription])} className="flex items-center">
                  <span className={`mr-1 ${lastClick === 'withoutImage' ? "underline-offset-2 underline" : null}`}>Bilde</span>
                  {lastClick === 'withoutImage' ? (sortOrderWithoutImage === 'asc' ? <FaSortAmountUp className='fill-white ml-1' /> : <FaSortAmountDown className='fill-white ml-1' />) : <FaSortAmountUp className='fill-gray-500 ml-1' />}
                </button>
              </th>
              <th className="px-4 py-3 text-left">
                <button onClick={() => toggleSortOrder('withoutCv', setSortOrderWithoutCv, [setSortOrderName, setSortOrderWithoutImage, setSortOrderWithoutDescription])} className="flex items-center">
                  <span className={`mr-1 ${lastClick === 'withoutCv' ? "underline-offset-2 underline" : null}`}>CV</span>
                  {lastClick === 'withoutCv' ? (sortOrderWithoutCv === 'asc' ? <FaSortAmountUp className='fill-white ml-1' /> : <FaSortAmountDown className='fill-white ml-1' />) : <FaSortAmountUp className='fill-gray-500 ml-1' />}
                </button>
              </th>
              <th className="px-4 py-3 text-left">
                <button onClick={() => toggleSortOrder('withoutDescription', setSortOrderWithoutDescription, [setSortOrderName, setSortOrderWithoutImage, setSortOrderWithoutCv])} className="flex items-center">
                  <span className={`mr-1 ${lastClick === 'withoutDescription' ? "underline-offset-2 underline" : null}`}>Beskrivelse</span>
                  {lastClick === 'withoutDescription' ? (sortOrderWithoutDescription === 'asc' ? <FaSortAmountUp className='fill-white ml-1' /> : <FaSortAmountDown className='fill-white ml-1' />) : <FaSortAmountUp className='fill-gray-500 ml-1' />}
                </button>
              </th>
              <th className="px-4 py-3 text-center">Rediger</th>
              <th className="px-4 py-3 text-center">Slett</th>
            </tr>
          </thead>
          <tbody>
            {sortedConsultants.map((consultant: ConsultantManageUsers) => (
              <tr key={consultant.consultantId} className="odd:bg-gray-200">
                <td className="px-4 py-2 text-left">  {consultant.consultantName.split(' ').reverse().join(' ')}
                </td>
                <td className="px-4 py-2 text-left">{consultant.specialization}</td>
                <td className="px-4 py-2 text-left">{consultant.consultantEmail}</td>
                <td className="px-6 py-2">{consultant.imageUrl ? 'Ja' : 'Nei'}</td>
                <td className="px-6 py-2">{consultant.cvUrl ? 'Ja' : 'Nei'}</td>
                <td className="px-4 py-2">{consultant.description ? 'Ja' : 'Nei'}</td>
                <td className="px-4 py-2 text-center">
                  <button onClick={() => onEdit(consultant)}>
                    <FaEdit />
                  </button>
                </td>
                <td className="px-4 py-2 text-center text-[#D04646]">
                  <button onClick={() => onDelete(consultant)}>
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConsultantTable;