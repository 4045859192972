import React, { ChangeEvent, useEffect, useState } from "react";
import {
  SelectCustomerConsultantUnit,
  DayConfig,
  CustomerDays,
} from "../../../types/types";
import {
  AutoGenerateDay,
  AutoGenerate_Interview,
  AutoGenerate_Timeslot,
  BreakTime,
} from "../../../types/interviewTypes";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { FaTrash } from "react-icons/fa";
import { Button } from "../../common/Button";
import { autoGenerateInterview } from "../../../api/adminApi";
import LoadingSpinner from "../../common/LoadingSpinner";
import SnackBarCustom from "../../common/SnackBarCustom";

interface ScheduleCreationModalProps {
  allInterviews: { Customer: number; Candidates: number[] }[];
  // onSave: (config: any) => void;
  onCancel: () => void;
  customers: SelectCustomerConsultantUnit[];
  onHandleChange: Function;

}

const ScheduleCreationModal: React.FC<ScheduleCreationModalProps> = ({
  // onSave,
  onCancel,
  allInterviews,
  customers,
  onHandleChange,
}) => {
  // State variables
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [dayConfigs, setDayConfigs] = useState<Array<DayConfig>>([]);
  const [dayStartTime, setDayStartTime] = useState("09:00");
  const [dayEndTime, setDayEndTime] = useState("16:00");
  const [interviewDuration, setInterviewDuration] = useState("10");
  const [breaks, setBreaks] = useState<Array<{ start: string; end: string }>>([]);
  const [customerDays, setCustomerDays] = useState<CustomerDays>({});
  const [dateDayOne, setDateDayOne] = useState(new Date());
  const [dateDayTwo, setDateDayTwo] = useState(new Date());
  const [loading, setLoading] = useState<boolean>(false);

  // Handles change in the number of days input-field
  const handleNumberOfDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numDays = parseInt(e.target.value, 10);
    setNumberOfDays(numDays);
  };

  // Initializes day configurations based on number of days
  const initializeDayConfigs = (numDays: number) => {
    const configs = new Array(numDays).fill(null).map((_, index) => ({
      day: index + 1,
      startTime: dayStartTime,
      endTime: dayEndTime,
      breaks: [],
      customers: [],
    }));
    setDayConfigs(configs);
  };
  // Changes the start/end time for the day
  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    if (id === "dayStartTime") {
      setDayStartTime(value);
    } else if (id === "dayEndTime") {
      setDayEndTime(value);
    }
  };

  // Changes the interview duration input
  const handleInterviewDurationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInterviewDuration(event.target.value);
  };

  // For adding a break with default start and end time
  const handleAddBreak = () => {
    setBreaks([...breaks, { start: "12:00", end: "13:00" }]);
  };

  // Delete break at the specified index
  const handleDeleteBreak = (index: number) => {
    const updatedBreaks = breaks.filter((_, i) => i !== index);
    setBreaks(updatedBreaks);
  };

  // Changes the start/end time for a break
  const handleBreakTimeChange = (
    index: number,
    field: keyof BreakTime,
    value: string
  ) => {
    // Creating copy of the breaks-array to modify and updates.
    setBreaks((prevBreaks) => {
      const updatedBreaks = [...prevBreaks]; 
      updatedBreaks[index][field] = value;
      return updatedBreaks;
    });
  };

  // Render for breaks and input fields for start/end time
  const renderBreaksConfiguration = () => {
    return breaks.map((breakTime, index) => (
      <div key={index} className="pb-3 justify-center">
        {/* Label - start time */}
        <label htmlFor={`breakStart-${index}`} className="mr-2">
          Pause {index + 1} fra:
        </label>

        {/* Input field - start time */}
        <input
          type="time"
          id={`breakStart-${index}`}
          className="form-input border-b border-black bg-brandGray-light px-1"
          value={breakTime.start}
          onChange={(e) =>
            handleBreakTimeChange(index, "start", e.target.value)
          }
        />

        {/* Label - end time */}
        <label htmlFor={`breakEnd-${index}`} className="mx-2">
          til:
        </label>

        {/* Input field - end time */}
        <input
          type="time"
          id={`breakEnd-${index}`}
          className="form-input border-b border-black bg-brandGray-light px-1"
          value={breakTime.end}
          onChange={(e) => handleBreakTimeChange(index, "end", e.target.value)}
        />

        {/* Delete button for break */}
        <button className="ml-2" onClick={() => handleDeleteBreak(index)}>
          <FaTrash />
        </button>
      </div>
    ));
  };

  // Change the customer participation day selection
  const handleCustomerDayChange = (customerId: number, day: string) => {
    setCustomerDays({ ...customerDays, [customerId]: day });
  };

  // Render to participation days for each customer
  const renderCustomerDaySelection = () => {
    return customers.map((customer) => {
       // Finds the interview data
      const customerInterview = allInterviews.find(
        (interview) => interview.Customer === customer.id
      );

       // Checks if the customer has candidates selected for interviews
      if (customerInterview && customerInterview.Candidates.length > 0) {
        return (
          <div
            key={customer.id}
            className="flex justify-between items-center text-sm space-y-2"
          >
            {/* Label - customer day selection */}
            <label htmlFor={`customerDay-${customer.id}`} className="text-sm">
              {customer.id}. {customer.name}
            </label>

             {/* Dropdown - selecting participation day */}
            <select
              id={`customerDay-${customer.id}`}
              className=" w-50 form-select p-1 rounded border text-sm bg-white pl-2 pr-10"
              value={customerDays[customer.id] || ""}
              onChange={(e) =>
                handleCustomerDayChange(customer.id, e.target.value)
              }
            >
               {/* Option for Day 1, Day 2 if there are a 2nd day, or both if there will be 2 days */}
              <option value="Day 1">Dag 1</option>
              {numberOfDays === 2 && <option value="Day 2">Dag 2</option>}
              {numberOfDays === 2 && (
                <option value="Both">Dag 1 og Dag 2</option>
              )}
            </select>
          </div>
        );
      }

      // Returns null if no candidates are selected
      return null;
    });
  };

  // Splits and organizes interviews based on customer day
  const handleSplitInterviews = () => {
    const organizedInterviews: any = {};

    // Organize interviews based on customer days
    allInterviews.forEach((interview) => {  
      const customerDay = customerDays[interview.Customer];
      const dayKey =
        customerDay === "Both" ? ["Day 1", "Day 2"] : [customerDay];

      // Iterate through each day
      dayKey.forEach((day, index) => {
        if (customerDay !== undefined) {
          if (!organizedInterviews[day]) {
            organizedInterviews[day] = { Interviews: [] };
          }

           // Calculate sliceStart and sliceEnd based on customer day
          const sliceStart =
            customerDay === "Both"
              ? index * Math.ceil(interview.Candidates.length / 2)
              : 0;

          const sliceEnd =
            customerDay === "Both"
              ? (index + 1) * Math.ceil(interview.Candidates.length / 2)
              : interview.Candidates.length;

          // Slice candidates based on calculation
          const slicedCandidates = interview.Candidates.slice(
            sliceStart,
            sliceEnd
          );

          // Push the sliced candidates into the organized interviews
          organizedInterviews[day].Interviews.push({
            customerId: interview.Customer,
            consultantIds: slicedCandidates,
          });
        }
      });
    });

    // Returning the orgainzed interviews
    return organizedInterviews;
  };

  // Convert single-digit months to two digits - for example  9 to '09'
  function convertMonths(value: number) {
    return value < 10 ? `0${value}` : value;
  }

  // Convert day configurations into time slots
  function convertToTimeSlots(day: string) {
    const formatDayOne = `${convertMonths(
      dateDayOne.getDate()
    )}/${convertMonths(dateDayOne.getMonth() + 1)}/${dateDayOne.getFullYear()}`;
    const formatDayTwo = `${convertMonths(
      dateDayTwo.getDate()
    )}/${convertMonths(dateDayTwo.getMonth() + 1)}/${dateDayTwo.getFullYear()}`;

      // Find the configuration for the selected day
    const config = dayConfigs.find((d) => `Day ${d.day}` === day);

    // Check if the configuration is not found
    if (config === undefined) {
      console.error(`Config not found for day: ${day}`);
      return [];
    }

    // Array with formatted date strings for Day 1 and Day 2
    const dates: string[] = [formatDayOne, formatDayTwo];

    // Extract the selected date based on configuration
    const date: string = dates[config.day - 1];

     // Array to store generated time slots
    let timeslots: AutoGenerate_Timeslot[] = [];

    // Create start time slot for interview.
    const startSlot: AutoGenerate_Timeslot = {
      dateTime: `${date}T${config.startTime}`,
      infoSlot: "interview",
    };
    timeslots.push(startSlot);

    // Iterate through breaks and create corresponding time slots
    breaks.forEach((b) => {
      const startSlot: AutoGenerate_Timeslot = {
        dateTime: `${date}T${b.start}`,
        infoSlot: "pause",
      };

       // Create end time slot for the break.
      const endSlot: AutoGenerate_Timeslot = {
        dateTime: `${date}T${b.end}`,
        infoSlot: "interview",
      };
      // Add start/end time slots for the break
      timeslots.push(startSlot);
      timeslots.push(endSlot);
    });

    // Create end time slot for the interview
    const endSlot: AutoGenerate_Timeslot = {
      dateTime: `${date}T${config.endTime}`,
      infoSlot: "end",
    };
    timeslots.push(endSlot);

    // Return generated time slots
    return timeslots;
  }

  // Displaying messages and setting toast color
  const [message, setMessage] = useState<string | null>(null);
  const [toastColor, setToastColor] = useState<"danger" | "success" | "neutral" | "primary" | "warning">("danger");

  // Handle click event when user saves the configuration
  const handleSaveClick = () => {
    const config = {
      numberOfDays,
      dayConfigs,
      interviewDuration,
      breaks,
      customerDays,
    };

    // Split interviews based on customer day
    const interviewsPerDay = handleSplitInterviews();

    // Array to store selected days 
    const days: string[] = ["Day 1", "Day 2"].slice(0, numberOfDays);

    // Convert interviews and time slots for each selected day
    const toBackend: AutoGenerateDay[] = days.map((day) => {
      const interviewArrays: AutoGenerate_Interview[] =
        interviewsPerDay[day]?.Interviews;

      // Convert configuration into time slots
      const timeslots: AutoGenerate_Timeslot[] = convertToTimeSlots(day);

      // Create data structure sent to the backend for current day
      const toReturn: AutoGenerateDay = {
        interviews: interviewArrays,
        timeInfos: timeslots,
      };
      return toReturn;
    });
    // Uncomment this line to call your API with the generated data
     setLoading(true);

     // Call the API to generate interviews
     autoGenerateInterview(toBackend)
     .then((response) => {
       setLoading(false);

       // Check if response contains messages
       if (response && response.messages !== null) {
         setToastColor("warning");
         setMessage(response.messages.join('\n'));
       } else {
        // Display success toast
         setToastColor("success");
         setMessage("Intervjuer generert!")
       }
     })
     .catch((error) => {
      // Displaying error message
       console.error('Error:', error);
       setToastColor("danger");
       setMessage(error.message);
       setLoading(false);
     });

  };

  // Initialize customer days when component mounts
  useEffect(() => {
    customers.map((customer) => {
      const customerInterview = allInterviews.find(
        (interview) => interview.Customer === customer.id
      );

      // Set default day to "Day 1", if the customer has interviews
      if (customerInterview && customerInterview.Candidates.length > 0) {
        setCustomerDays((prev) => ({ ...prev, [customer.id]: "Day 1" }));
      }
    });
  }, []);

  // Initialize day configurations when dependent variable change
  useEffect(() => {
    initializeDayConfigs(numberOfDays);
  }, [numberOfDays, dayStartTime, dayEndTime, breaks]);

  // Style for card container
  const cardContainer =
    "bg-brandGray-light w-full xl:w-1/2  p-5 rounded-lg overflow-y-scroll h-full";

  return (
    <div
      id="config-schedule-modal"
      className="h-[61vh] overflow-auto xl:overflow-hidden "
    >
      {/* Snack-bar for displaying message */}
      {message && 
        <SnackBarCustom
          color={toastColor}
          message={message}
          onClose={() => setMessage(null)}/>
     }

     {/* Loading a spinner while data is loading */}
      {loading ? (
        <div className="bg-transparent fixed top-1/2 left-1/2"><LoadingSpinner/></div>
      ): ( 
      <div className="flex items-center gap-2 xl:items-stretch flex-col xl:flex-row m-2 h-fit xl:h-full ">
      {/* Left side */}
      <div className={cardContainer}>
        <div className="flex items-center mb-3">
          <div className="text-4xl font-bold"> 1 </div>
          <div className="ml-2 pt-3 text-lg font-semibold">
            {" "}
            Antall speedintervjudager{" "}
          </div>
        </div>

        {/* Radio buttons for selecting number of days */}
        <div className="pl-2 pt-3">
          <div className="flex items-start mb-2">
            <label className="flex items-center">
              <input
                type="radio"
                name="numberOfDays"
                value="1"
                checked={numberOfDays === 1}
                onChange={handleNumberOfDaysChange}
                className="form-radio text-blue-600"
              />
              <span className="pl-2">1</span>
            </label>
          </div>

           {/* Radio button for 2 days */}
          <div className="flex items-start">
            <label className="flex items-center">
              <input
                type="radio"
                name="numberOfDays"
                value="2"
                checked={numberOfDays === 2}
                onChange={handleNumberOfDaysChange}
                className="form-radio text-blue-600"
              />
              <span className="pl-2">2</span>
            </label>
          </div>

          {/* Date picker for Day 1 */}
          {numberOfDays === 1 && (
            <div className="text-left pt-2">
              <p className="my-4 font-semibold text-lg">
                Velg dato for Dag 1:
              </p>
              <div className="flex mb-5">
                <div className="mr-5">
                  <p>Dag 1:</p>
                </div>
                <div className="w-1/2">
                  <DatePicker
                    showIcon
                    selected={dateDayOne}
                    onChange={(newDate) => setDateDayOne(newDate as Date)}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Date pickers for Day 1 and Day 2 */}
          {numberOfDays === 2 && (
            <div className="text-left pt-2">
              <p className="my-4 font-semibold text-lg">
                Velg dato for Dag 1 og Dag 2:
              </p>
              <div className="flex mb-5">
                <div className="mr-5">
                  <p>Dag 1:</p>
                </div>
                <div className="w-1/2">
                  <DatePicker
                    showIcon
                    selected={dateDayOne}
                    onChange={(newDate) => setDateDayOne(newDate as Date)}
                  />
                </div>
              </div>
              <div className="flex my-5">
                <div className="mr-5">
                  <p>Dag 2:</p>
                </div>
                <div className="w-1/2">
                  <DatePicker
                    showIcon
                    selected={dateDayTwo}
                    onChange={(newDate) => setDateDayTwo(newDate as Date)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Middle */}
      <div className={cardContainer}>
        <div className="text-left">
          <div className="flex items-center mb-3">
            <div className="text-4xl font-bold"> 2 </div>
            <div className="ml-2 pt-3 text-lg font-semibold">
              {" "}
              Tidsramme for dagene{" "}
            </div>
          </div>

           {/* Time selection */}
          <div className="pl-2 pt-2">
            <div className="font-semibold mb-2">Varighet på dagen</div>
            <div className="flex space-x-4">
              <label htmlFor="dayStartTime" className="block text-sm">
                Fra:
              </label>
              <input
                type="time"
                id="dayStartTime"
                value={dayStartTime}
                onChange={handleTimeChange}
                className="form-input border-b border-black bg-brandGray-light px-1"
              />
              <label htmlFor="dayEndTime" className="block text-sm">
                Til:
              </label>
              <input
                type="time"
                id="dayEndTime"
                value={dayEndTime}
                onChange={handleTimeChange}
                className="form-input border-b border-black bg-brandGray-light px-1"
              />
            </div>

             {/* Interview duration input */}
            <div className="pt-8">
              <div className="font-semibold pb-2">Varighet på intervju:</div>
              <input
                type="number"
                className="form-input border-b border-black bg-brandGray-light text-center"
                value={interviewDuration}
                onChange={handleInterviewDurationChange}
                min="5"
                max="60"
              />
              <span className="ml-2">min</span>
            </div>

            {/* Breaks configuration */}
            <div className="pt-8 space-y-2">
              <div className="font-semibold mb-3">
                Legg til pauser{" "}
                <button
                  type="button"
                  onClick={handleAddBreak}
                  className="items-center rounded-full border border-black justify-center hover:bg-white ml-1.5 px-2 pb-1 transition duration-300"
                >
                  {" "}
                  +
                </button>
              </div>
              {renderBreaksConfiguration()}
            </div>
          </div>
        </div>
      </div>

      {/* Right Side */}
      <div className={cardContainer}>
        <div className="flex-1">
          {/* Customer List */}
          <div className="flex items-center mb-3">
            <div className="text-4xl font-bold"> 3 </div>
            <div className="ml-2 pt-3 text-lg font-semibold">
              {" "}
              Deltakelsesdager på kundene{" "}
            </div>
          </div>
          <div className="pl-2">{renderCustomerDaySelection()}</div>
        </div>
      </div>
    </div> )}
      
       {/* Save button */}
      <div className="fixed bottom-5 flex justify-end right-44 z-50">
        <Button onClick={handleSaveClick} text="Generer intervjuer" width="fit"></Button>
      </div>

    </div>
  );
};

export default ScheduleCreationModal;