import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { FaCheck } from 'react-icons/fa';

// Commentary to trigger re-deployment

// Interface for CustomStepperProps
interface CustomStepperProps {
  stepNumber: number;
  onStepChange: Dispatch<SetStateAction<number>>;
  children: ReactElement;
  footer: () => ReactElement;
  onClick?: () => void;
  hasSchedule: boolean;
}

const CustomStepper: React.FC<CustomStepperProps> = ({ stepNumber, onStepChange, children, onClick, footer, hasSchedule }) => {

  // Array of step labels
  const steps = ['Konsulent-kobling', 'Konfigurering av tidskjema', 'Redigering av tidskjema'];

  // Handler for step change
  const handleStepChange = (newStep: number) => {
    onStepChange(newStep);
    if (onClick) onClick();
  };

  // Styles for different circle states
  const circleStyle = " cursor-pointer group text-white rounded-full h-10 w-10 flex items-center justify-center text-center ";
  const hoverStyle = "bg-brandBlueDark hover:shadow-lg";
  const activeStyle = "border-2 rounded-full border-brandBlueDark ";
  const unactiveStyle = 'bg-brandGray-light';

  return (
    <div className="w-full mx-auto h-full my-auto"> {
      stepNumber <= 3 && !hasSchedule &&
      <div className="flex items-center my-4">
        <div className="flex-1">
          {stepNumber > 1 && (
            <button
              className="font-bold hover:underline text-sm px-6 rounded focus:outline-none transition duration-300 ease-in-out left absolute"
              onClick={() => handleStepChange(stepNumber - 1)}
            >
              &larr; Tilbake til Konsulent-kobling
            </button>
          )}
        </div>
        <div className="flex justify-center flex-grow">
          {steps.map((label, index) => (
            <React.Fragment key={label}>
              <div className='flex flex-col items-center'>
                <div className={`p-1 ${stepNumber === index + 1 ? activeStyle : ''}`}>
                  <div
                    className={`${circleStyle} ${stepNumber > index ? 'bg-brandBlueDark' : unactiveStyle} ${stepNumber === index + 1 ? hoverStyle : ''}`}
                    onClick={() => handleStepChange(index + 1)}
                  >
                    {stepNumber > index + 1 ? <FaCheck /> : index + 1}
                  </div>
                </div>
                <span className="text-xs">{label}</span>
              </div>
              {index < steps.length - 1 && (
                <div className={`flex-grow border-t-2 transition duration-300 ease-in-out mt-6 ${stepNumber > index + 1 ? 'border-brandBlueDark' : 'border-brandGray-light'}`} />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex-1" /> {/* Empty div for spacing */}
      </div>}

      {children}

      {footer && (
        <div className="flex justify-end flex-col items-end bottom-5 end-10 z-10 fixed">
          {footer()}
        </div>
      )}
    </div>
  );
};


export default CustomStepper;