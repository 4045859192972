import React from "react";
import { AddButtonProps } from "../../../types/types";


const ManageUserButton: React.FC<AddButtonProps> = ({ onClick, label }) => (
  <button
    className="bg-brandBlueDark text-white text-sm border border-brandBlueDark w-40 py-2 rounded hover:bg-brandWhite hover:text-brandBlueDark"
    onClick={onClick}
  >
    {label}
  </button>
);

export default ManageUserButton;
