export const getDynamicLabelAddButton = (currentFilter: string | null) => {
    switch (currentFilter) {
      case "Kunder":
        return "LEGG TIL KUNDE";
      case "Konsulenter":
        return "LEGG TIL KONSULENT";
      case "Admin":
        return "LEGG TIL ADMIN";
      default:
        return "Legg til bruker";
    }
  };


 
  