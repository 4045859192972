import React, { useState, useEffect } from "react";
import CustomInput from "../../common/InputField";


interface AddAdminProps {
  onAddAdmin: (newAdmin: { adminName: string; adminEmail: string }) => Promise<void>;
  onSave?: () => void; 
}


const AddAdminForm: React.FC<AddAdminProps> = ({ onAddAdmin, onSave }) => {
  const [adminName, setAdminName] = useState<string>("");
  const [adminEmail, setAdminEmail] = useState<string>("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const [error, setError] = useState<string | null | undefined>(null);


  const handleAddAdmin = async () => {
    try {
      if (requiredFieldsFilled) {
        const adminData = {
          adminName,
          adminEmail,
        };
        await onAddAdmin(adminData);
        onSave?.();
      }else if(adminName === "") setNameError(true)
      if(adminEmail === "") setEmailError(true)
    } catch (error) {
      setError("An error occurred while adding the admin.");
    }
  };

  useEffect(() => {
    if (adminName && adminEmail) {
      setRequiredFieldsFilled(true)
      setNameError(false)
      setEmailError(false)
    } else(setRequiredFieldsFilled(false))
  }, [adminName, adminEmail]);


  return (
    <div className="w-96 h-auto bg-white p-4 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6 flex justify-start">Legg til ny admin</h2>

        <div className="mb-4">
        <CustomInput
            value={adminName}
            textRed={nameError}
            onChange={(e) => setAdminName(e.target.value)}
          label="Navn"
        />
           <div className={`text-xs text-left ${(nameError && adminName === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
     <br />
     </div>


        <div className="mb-4">
        <CustomInput
            value={adminEmail}
            textRed={emailError}
            onChange={(e) => setAdminEmail(e.target.value)}
          label="E-post"
        />
           <div className={`text-xs text-left ${(emailError && adminEmail === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
     </div>

        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="flex justify-end my-9">
          <button onClick={handleAddAdmin}           
          className={`${requiredFieldsFilled ? "bg-brandBlueDark border-brandBlueDark hover:bg-brandWhite hover:text-brandBlueDark cursor-pointer" : "bg-[#696969] cursor-not-allowed"}  text-white border  w-24 px-4 py-2 rounded `}
          >Lagre
</button>
      </div>
      </div>
  );
};

export default AddAdminForm;