import React, { useEffect, useState } from "react";
import DayOverview from "../../components/customer/DayOverview";
import ConsultantDetails from "../../components/customer/ConsultantDetails";
import CustomerCarousel from "../../components/customer/customerCarousel";
import ConsultantFeedback from "../../components/customer/ConsultantFeedback";
import { GetConsultantsToMeet } from "../../api/customerApi";
import { ConsultantMeeting, ConsultantNoMeeting, Pause } from "../../types/consultantTypes";
import { CustomerAgenda } from '../../types/customerTypes';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { extractIdFromJWT } from "../../utils/JwtExtraction";


const AgendaPage: React.FC = () => {
  const [activeConsultant, setActiveConsultant] = useState<number | null>(null);
  const [loggedinCustomer, setLoggedinCustomer] = useState<CustomerAgenda | undefined>(undefined);
  const [agendaItems, setAgendaItems] = useState<(ConsultantMeeting | ConsultantNoMeeting | Pause)[]>([]);
  const [consultants, setConsultants] = useState<(ConsultantMeeting | ConsultantNoMeeting | Pause)[]>([]);
  const [pauses, setPauses] = useState<(Pause)[]>([]);
  const [interviewDate, setInterviewDate] = useState<string>('');
  const [consultantScores, setConsultantScores] = useState<{ [consultantId: number]: boolean }>({});


  const token = useSelector((state: RootState) => state.auth.accessToken);
  const customerIdString = extractIdFromJWT(token);
  const customerId = parseInt(customerIdString, 10);
  const handleScore = (consultantId: number, hasScore: boolean) => {
    setConsultantScores(prevScores => ({ ...prevScores, [consultantId]: hasScore }));
  };


  useEffect(() => {
    console.log("Extracted token: " + extractIdFromJWT(token));
  }, [token]);


  useEffect(() => {
    const today = new Date();
    const formattedToday = capitalizeFirstLetter(today.toLocaleDateString('no-NO', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    }));
    setInterviewDate(formattedToday); // Set today's date by default

    const fetchData = async () => {
      try {
        const response = await GetConsultantsToMeet(customerId); // Use the actual customer ID
        if (response?.consultants && response?.pauses) {
          //const combinedItems = sortAgendaItemsByTime([...response.consultants, ...response.pauses]);
          setPauses(response.pauses);
          setConsultants(response.consultants)
          //setAgendaItems(combinedItems);
        } else {
          // If there are no consultants or pauses, display "No interviews today"
          setInterviewDate("No interviews today");
        }
        setLoggedinCustomer(response);
      } catch (error) {
        console.error("Error fetching consultants:", error);
      }
    };

    fetchData();
  }, []);




  // Helper functions
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const sortAgendaItemsByTime = (items: (ConsultantMeeting | Pause)[]) => {
    return items.sort((a, b) => {
      const timeA = new Date('interview' in a ? a.interview.time : a.dateTime).getTime();
      const timeB = new Date('interview' in b ? b.interview.time : b.dateTime).getTime();
      return timeA - timeB;
    });
  };

  useEffect(() => {
    const items: (ConsultantMeeting | Pause)[] = []
    consultants.forEach((consultant) => {
      items.push(consultant as ConsultantMeeting)
    })
    pauses.forEach((pause) => {
      items.push(pause)
    })

    const combinedItems = sortAgendaItemsByTime(items);
    setAgendaItems(combinedItems)
    console.log(combinedItems)
  }, [consultants, pauses])

  return (
    <div className="flex w-full h-full">
      <DayOverview
        date={interviewDate}
        items={agendaItems as (ConsultantMeeting | Pause)[]}
        setActiveConsultant={setActiveConsultant}
        consultantScores={consultantScores}
      />
      <div className={`col-span-2 flex flex-row ml-10  w-9/12 aspect-square rounded-lg ${activeConsultant ? "mt-20 pr-10" : "mt-10"}`}>
        {activeConsultant === null &&
          <CustomerCarousel customerName={loggedinCustomer?.customerName || 'Kunde'} logoUrls={loggedinCustomer?.logoUrls || []} />
        }
        {activeConsultant !== null && (
          <ConsultantDetails
            consultant={agendaItems.find(item => 'consultantId' in item && item.consultantId === activeConsultant) as ConsultantMeeting}
            children={
              <ConsultantFeedback
                consultants={agendaItems.filter((item): item is ConsultantMeeting => 'consultantId' in item)}
                setConsultants={setConsultants}
                consultant={agendaItems.find(item => 'consultantId' in item && item.consultantId === activeConsultant) as ConsultantMeeting}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default AgendaPage;



