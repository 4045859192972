import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/joy/Snackbar';
import { Button } from '@mui/joy';
 
interface CustomAlertProps {
    onClose?: () => void;
    message?: string;
    color:
    'danger'
    | 'neutral'
    | 'primary'
    | 'success'
    | 'warning';
    align?:
    'bottom-center'
    | 'bottom-right'
    | 'bottom-left'
    | 'top-center'
    | 'top-right';
}
 
const mapAlignToSnackbarOrigin = (align: CustomAlertProps['align']): SnackbarOrigin => {
    switch (align) {
        case 'bottom-center':
            return { vertical: 'bottom', horizontal: 'center' };
        case 'bottom-right':
            return { vertical: 'bottom', horizontal: 'right' };
        case 'bottom-left':
            return { vertical: 'bottom', horizontal: 'left' };
        case 'top-center':
            return { vertical: 'top', horizontal: 'center' };
        case 'top-right':
            return { vertical: 'top', horizontal: 'right' };
        default:
            return { vertical: 'bottom', horizontal: 'right' };
    }
};
 
const SnackBarCustom: React.FC<CustomAlertProps> = ({ message, color, align, onClose }) => {
    const [open, setOpen] = React.useState(!!message);
    const [duration, setDuration] = React.useState(8000);
    const [left, setLeft] = React.useState<undefined | number>();
    const timer = React.useRef<undefined | number>();
 
    const countdown = () => {
        timer.current = window.setInterval(() => {
            setLeft((prev) => (prev === undefined ? prev : Math.max(0, prev - 100)));
        }, 100);
    };
 
    React.useEffect(() => {
        if (open && duration !== undefined && duration > 0) {
            setLeft(duration);
            countdown();
        } else {
            window.clearInterval(timer.current);
        }
 
        return () => {
            window.clearInterval(timer.current);
        };
    }, [open, duration]);
 
    const handlePause = () => {
        window.clearInterval(timer.current);
    };
 
    const handleResume = () => {
        countdown();
    };
 
    React.useEffect(() => {
        if (message) {
            setOpen(true);
            setLeft(duration);
        }
    }, [message]);
 
    const handleClose = () => {
        setOpen(false);
        if (onClose) {
          onClose();
        }
      };
 
    const anchorOrigin = React.useMemo(() => mapAlignToSnackbarOrigin(align), [align]);
 
    return (
        <Snackbar
            key={message}
            open={open}
            color={color}
            size="lg"
            variant="soft"
            anchorOrigin={anchorOrigin}
            endDecorator={
                <Button
                    onClick={handleClose}
                    size="sm"
                    variant="soft"
                    color={color}
                >
                    Dismiss
                </Button>
            }
            autoHideDuration={duration}
            resumeHideDuration={left}
            onMouseEnter={handlePause}
            onMouseLeave={handleResume}
            onFocus={handlePause}
            onBlur={handleResume}
            onUnmount={() => setLeft(undefined)}
            onClose={handleClose}
        >
            {message}
        </Snackbar>
    );
};
 
export default SnackBarCustom; 