import { AspectRatio, Card, CardContent, Link, Typography } from "@mui/joy";
import React, { useState } from "react";
import { MdFileUpload } from "react-icons/md";

interface FileDropZoneCustomProps {
  setCv: (file: File | null) => void;
}

const FileDropZoneCv: React.FC<FileDropZoneCustomProps> = ({ setCv }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    console.log("Selected file:", file?.name);
    setCv(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(false);

    const file = e.dataTransfer.files[0];
    console.log("Dropped file:", file?.name);
    setCv(file);
  };

  return (
    <div
      className={`custom-file-dropzone ${
        isHovered ? "border-blue-500 bg-blue-100" : "border-gray-300"
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <label htmlFor="fileInputForCv" className="cursor-pointer">
        <div className="text-center">
          <div>
            <Card
              variant="soft"
              sx={[
                {
                  borderRadius: "sm",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: "center",
                  px: 3,
                  boxShadow: "none",
                  alignContent: "center",
                  justifyContent: "center",
                },
              ]}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AspectRatio
                  ratio="1"
                  variant="solid"
                  color="primary"
                  sx={{
                    minWidth: 2,
                    borderRadius: "50%",
                    width: 35,
                  }}
                >
                  <div className="">
                    <MdFileUpload
                      style={{
                        fontSize: "100%",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                </AspectRatio>
                <Typography level="body-sm" textAlign="center">
                  <Link overlay>Trykk for å laste opp</Link>
                  , eller dropp filen her.
                  <br />
                  Akseptabelt filformat: PDF
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </label>

      <input
        type="file"
        id="fileInputForCv"
        onChange={handleFileChange}
        accept=".pdf"
        className="hidden"
        data-lpignore
      />
    </div>
  );
};

export default FileDropZoneCv;
