//https://www.material-tailwind.com/docs/html/input
//Material Tailwind is a free and open-source components library for ReactJS and Tailwind CSS inspired by Material Design.
//https://www.material-tailwind.com/docs/react/installation

import React, { ChangeEvent } from "react";

interface InputFieldProps {
  id?: string;
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder?: string;
  required?: boolean;
  type?: string;
  textRed?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  value,
  onChange,
  label,
  placeholder = " ",
  type,
  textRed,
}) => {
  return (
    <div className="w-full">
      <div className="relative w-full h-12">

      <input
    type={type}
    placeholder={placeholder}
    id={id}
    value={value}
    onChange={onChange}
      className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
      />
  <label
  className={`before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-${textRed && value === "" ? 'red' : 'blue-gray'}-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-${textRed && value === "" ? 'red' : 'blue-gray'}-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-${textRed && value === "" ? 'red' : 'gray-900'} peer-focus:leading-tight peer-focus:text-${textRed && value === "" ? 'red' : 'gray-900'} peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-${textRed && value === "" ? 'red' : 'blue-gray'}-500`}
>
  {label}
</label>

      </div>
    </div>
  );
};

export default InputField;











