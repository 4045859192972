import { useEffect } from 'react';
import { fetchAllUsers } from '../api/adminApi';
import { Admin, CustomerManageUsers, ConsultantManageUsers } from '../types/types';

interface FetchDataProps {
  setAdmins: React.Dispatch<React.SetStateAction<Admin[]>>;
  setCustomers: React.Dispatch<React.SetStateAction<CustomerManageUsers[]>>;
  setConsultants: React.Dispatch<React.SetStateAction<ConsultantManageUsers[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useFetchData = ({
  setAdmins,
  setCustomers,
  setConsultants,
  setLoading,
}: FetchDataProps) => {
  const fetch = async () => {
    setLoading(true);
    try {
      const response = await fetchAllUsers();
      setAdmins(response.admins);
      setCustomers(response.customers);
      setConsultants(response.consultants);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [setAdmins, setCustomers, setConsultants, setLoading]);

  return fetch; 
};
