import {  ConsultantFilterProps, specializationFilters} from "../../types/filterTypes";
import FilterField from "./FilterField";

const ConsultantFilterPopup: React.FC<ConsultantFilterProps> = ({
  activeFilter,
  onFilterChange,
}) => {
  return (
    <div className="flex flex-col ">
      {specializationFilters.map(specialization => 
        <FilterField 
          active={activeFilter[specialization]} 
          onClick={() => onFilterChange(specialization)}
          text={specialization}/>
      )}
    </div>
  );
};
export default ConsultantFilterPopup;