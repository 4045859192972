import React, { useEffect, useState } from "react";
import "../../index.css";
import { fetchScoreSheetData } from "../../api/adminApi";
import ScoreTable from "../../components/Admin/schedule/ScoreTable";
import { Candidate, Client } from "../../types/types";

const ScoreOverviewPage: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [consultants, setConsultants] = useState<Candidate[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchScoreSheetData();
        if (data.customers) {
          setClients(data.customers);
        }
        if (data.consultants) {
          setConsultants(data.consultants);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-wrap w-full justify-center text-center h-full px-20 overflow-x-auto">
      <ScoreTable clients={clients} consultants={consultants} />
    </div>
  );
};

export default ScoreOverviewPage;
