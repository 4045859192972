import React from "react";
import AddCustomerForm from "../Admin/forms/AddCustomerForm";
import AddAdminForm from "../Admin/forms/AddAdminForm";
import AddConsultantForm from "../Admin/forms/AddConsultantForm";
import EditCustomerForm from "../Admin/forms/EditCustomerForm";
import { handleAddCustomer, handleAddAdmin, handleAddConsultant, handleEditCustomer, handleEditConsultant, handleEditAdmin } from "../../utils/ManageUserHandlers";
import EditConsultantForm from "../Admin/forms/EditConsultantForm";
import EditAdminForm from "../Admin/forms/EditAdminForm";
import ConfirmDelete from "../Admin/forms/ConfirmDelete";

interface ModalContentProps { filter: string | null; }

interface FilterComponents {
  [key: string]: JSX.Element;
}

const filterComponents: FilterComponents = {
  KunderAdd: <AddCustomerForm onAddCustomer={handleAddCustomer} />,
  AdminAdd: <AddAdminForm onAddAdmin={handleAddAdmin} />,
  KonsulenterAdd: <AddConsultantForm onAddConsultant={handleAddConsultant} />,
  KunderEdit: <EditCustomerForm onEditCustomer={handleEditCustomer}  />,
  KonsulenterEdit: <EditConsultantForm onEditConsultant={handleEditConsultant} />,
  AdminEdit: <EditAdminForm onEditAdmin={handleEditAdmin}/>,
  KunderDelete: <ConfirmDelete filter={"customer"}/>,
  KonsulenterDelete: <ConfirmDelete filter={"consultant"}/>,
  AdminDelete: <ConfirmDelete filter={"admin"}/>,
  default: <div>Default Component</div>,
};

const ModalContent: React.FC<ModalContentProps & { onSave?: () => void }> = ({ filter, onSave }) => (
  (filter && filter in filterComponents) ? React.cloneElement(filterComponents[filter], { onSave }) : filterComponents.default
);

export default ModalContent;