export const removeDuplicates = <T>(array: T[], property: keyof T) => {
    const uniqueArray = [];
    const seenValues = new Set();
  
    for (const item of array) {
      const value = item[property];
  
      if (!seenValues.has(value)) {
        seenValues.add(value);
        uniqueArray.push(item);
      }
    }
  
    return uniqueArray;
  };