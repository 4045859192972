import React from "react";
import { Timeslot } from "../../../types/types";

interface ComponentProps {
  timeSlot: Timeslot;
  width: number;
}

export const ScheduleBreak: React.FC<ComponentProps> = ({
  timeSlot,
  width,
}) => {
  return (
    <tr>
      <td className="bg-brandOrange-regular border-r p-2 border-black ">
        {timeSlot.from}
      </td>
      <td
        colSpan={width}
        className={
          "w-full bg-brandOrange-regular text-brandBlueDark text-center text-base font-semibold"
        }
      >
        Pause
      </td>
    </tr>
  );
};
