import React, { ReactNode, useEffect, useState } from "react";
import {
  ConsultantDetails,
  ConsultantMeeting,
} from "../../types/consultantTypes";
import { fetchSasTokenWithUrl } from "../../api/authApi";

interface CardProps {
  consultant: ConsultantDetails | ConsultantMeeting;
  children?: ReactNode;
  onClick: () => void;
  isActive: boolean;
}

const Card: React.FC<CardProps> = ({
  consultant,
  onClick,
  isActive,
  children,
}) => {
  const handleClick = () => {
    onClick();
  };

  const [imageUrl, setImageUrl] = useState<string>("");
  const cardStyle = ` box-content w-[116px] min-h-[220px] p-3 rounded-md cursor-pointer mb-10 relative
  ${
    isActive
      ? "bg-brandBlueDark text-brandWhite"
      : "bg-brandWhite text-brandBlack hover:bg-brandGray-light hover:shadow-none shadow-md"
  }
`;

useEffect(() => {
  const fetchBlobSasTokens = async () => {
    if(consultant.imageUrl){
      const sasTokenUrl = await fetchSasTokenWithUrl(consultant.imageUrl);
      setImageUrl(sasTokenUrl)
    }
  };
  fetchBlobSasTokens();
}, [consultant.imageUrl]);
  return (
    <div
      key={consultant.consultantId}
      className={cardStyle}
      onClick={handleClick}
    >
      <img
        src={imageUrl || "/assets/person.svg"}
        alt="Not found"
        className="rounded-md w-[116px] h-[108px] object-cover"
      />

      <div className="text-xs py-3 font-normal break-words">
        <p>{consultant.consultantName}</p>
        <p>{consultant.specialization}</p>
      </div>

      {children}
    </div>
  );
};

export default Card;
