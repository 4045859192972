import { extendTheme } from "@mui/joy/styles";

export const customTheme = extendTheme({
  components: {
    JoyDrawer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "md" && {
            "--Drawer-horizontalSize": "clamp(45%, 30%, 100%)",
          }),
        }),
      },
    },
  },
});

