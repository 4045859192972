import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Button } from "../Button";

const ConsentForm = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="fixed bottom-0 right-0 z-20 shadow-md p-12 bg-slate-200">
      <p className="text-xl">
        Denne tjenesten levert av Experis Academy er designet for å forenkle
        prosessen under speedintervjudagene. På grunn av dette lagres noe av
        informasjonen du legger inn for potensielt fremtidig bruk, slik at
        videre samarbeid mellom oss og dere vil forenkles. Denne lagrede dataen
        er lagret i henhold til GDPR retningslinjene, og vil slettes etter en
        periode uten samarbeid, eller innen satt tidsfrist. Ved å bruke
        tjenesten godtar du at noe av informasjon du fyller inn i tjenesten blir
        lagret.
        <br />
        <br />
        <p>
          Har du noen spørsmål rundt hvilken data som blir lagret og hvorfor, se
          vår <a href="/personvern" className="text-blue-600">personvernserklæring</a>.
        </p>
      </p>


      <Button 
          text="Godta" 
          width="full" 
          custom="text-md px-8 py-2 mr-8 bg-brandBlueDark border-brandBlueDark float-right"
          onClick={giveCookieConsent} />
    </div>
  );
};

export default ConsentForm;
