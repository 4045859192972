import React, { useEffect, useState } from "react";
import ConsultantCard from "./ConsultantCard";
import {
  ConsultantDetails,
  ConsultantMeeting,
} from "../../types/consultantTypes";

interface CardGroupProps {
  consultants: ConsultantDetails[] | ConsultantMeeting[];
  onClick: (consultantId: number) => void;
}

const CardGroup: React.FC<CardGroupProps> = ({ consultants, onClick }) => {
  const [activeConsultantId, setActiveConsultantId] = useState<number | null>(
    consultants.length > 0 ? consultants[0].consultantId : null
  );

  useEffect(() => {
    activeConsultantId && onClick(activeConsultantId);
  });

  const handleCardClick = (consultantId: number) => {
    setActiveConsultantId(consultantId);
    onClick(consultantId);
  };

  return (
    <div className="flex flex-wrap justify-center items-start w-full gap-7 h-fit  max-h-fit overflow-y-scroll mb-10">
      {/*   <div className="flex flex-wrap justify-center w-full gap-7 h-fit min-h-48 max-h-52"> */}
      {consultants.map((consultant) => (
        <ConsultantCard
          key={consultant.consultantId}
          consultant={consultant}
          isActive={activeConsultantId === consultant.consultantId}
          onClick={() => handleCardClick(consultant.consultantId)}
        />
      ))}
    </div>
  );
};

export default CardGroup;
