
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { Admin } from "../../types/types";

interface SelectedAdminState {
  selectedAdmin: Admin | null;
}

const initialState: SelectedAdminState = {
  selectedAdmin: null,
};

const selectedAdminSlice = createSlice({
  name: "selectedAdmin",
  initialState,
  reducers: {
    selectAdmin: (state, action: PayloadAction<Admin | null>) => {
      state.selectedAdmin = action.payload;
    },
  },
});

export const { selectAdmin } = selectedAdminSlice.actions;

// Selectors
export const selectSelectedAdmin = (state: RootState) =>
  state.selectedAdmin.selectedAdmin;

export default selectedAdminSlice.reducer;
