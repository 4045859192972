import axios from "axios";
import { Consultant, ConsultantDetails } from "../types/consultantTypes";
import { GetToken } from "../utils/GetToken";
import { fetchSasTokenWithUrl } from "./authApi";


const consultantApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/Consultant`,
});

consultantApi.interceptors.request.use(
  (config) => {
    const token = GetToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchConsultantById = async (consultantId: number) => {
  try {
    const response = await consultantApi.get(`/${consultantId}`);
    const consultantData = response.data;
    if(consultantData.imageUrl) {
      consultantData.imageUrl = await fetchSasTokenWithUrl(consultantData.imageUrl);
    }

    if(consultantData.cvUrl) {
      consultantData.cvUrl= await fetchSasTokenWithUrl(consultantData.cvUrl);
    }

    const cvUrlWithSasToken = await fetchSasTokenWithUrl(consultantData.candidateCVUrl);
    consultantData.candidateCVUrl = cvUrlWithSasToken;

    return consultantData;

  } catch (error) {
    console.error("Error fetching consultant by ID:", error);
    throw error;
  }
};

export const updateConsultantProfile = async (
  formData: FormData,
  setUploadProgressCv: React.Dispatch<React.SetStateAction<number>>,
  setUploadProgressProfileImage: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const consultantId = formData.get("consultantId");
    const response = await consultantApi.put(
      `/${consultantId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded;
          const total = progressEvent.total;

          if (total) {
            if (formData.get("image")) {
              const progressProfileImage = Math.round((loaded * 100) / total);
              setUploadProgressProfileImage(progressProfileImage);
            }
            if (formData.get("cv")) {
              const progressCv = Math.round((loaded * 100) / total);
              setUploadProgressCv(progressCv);
            }
          }
        },
      }
    );
    console.log("Consultant edited successfully:", response.data);
  } catch (error) {
    console.error("Error updating existing consultant:", error);
  }
};

export const fetchconsultantsByCustomer = async (
  id: number
): Promise<Consultant[]> => {
  try {
    const response = await consultantApi.get(`/Customer/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllconsultants = async (): Promise<ConsultantDetails[]> => {
  try {
    const response = await consultantApi.get("/GetAll", {
      params: {
        fields:
          "consultantId,consultantName,email,specialization,consultantDescription,consultantCVUrl,consultantImageUrl,",
      },
    });
    console.log("API Response:", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createConsultant = async (
  newconsultantData: ConsultantDetails
): Promise<ConsultantDetails[]> => {
  try {
    await consultantApi.post("/", newconsultantData);
    const updatedconsultants = await fetchAllconsultants();
    return updatedconsultants;
  } catch (error) {
    throw error;
  }
};

export const deleteConsultant = async (
  consultantId: number
): Promise<ConsultantDetails[]> => {
  try {
    console.log("Deleting consultant with ID:", consultantId);
    await consultantApi.delete(`/${consultantId}`);
    const updatedconsultants = await fetchAllconsultants();
    return updatedconsultants;
  } catch (error) {
    throw error;
  }
};

//IKKE I BRUK

// export const fetchconsultantsByCustomer = async (customerId: number) => {
//     try {
//         const response = await consultantsApi.get(`/Customer/${customerId}`);
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching consultants by customer:', error);
//         throw error;
//     }
// };

// export const fetchconsultantById = async (consultantId: number) => {
//     try {
//         const response = await consultantsApi.get(`/${consultantId}`);
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching consultant by ID:', error);
//         throw error;
//     }
// };

// export const createconsultant = async (consultantData: number) => {
//     try {
//         const response = await consultantsApi.post("", consultantData);
//         return response.data;
//     } catch (error) {
//         console.error('Error creating consultant:', error);
//         throw error;
//     }
// };
