import React, { useState, useEffect } from "react";
import CustomInput from "../../common/InputField";
import { selectSelectedCustomer } from "../../../redux/slices/SelectedCustomerSlice";
import { useSelector } from "react-redux";
import FileUpload from "../../consultant/FileUpload";
import FileDropZoneImage from "../../consultant/FileDropZoneImage";
import ResponsiveScrollContainer from '../../common/ResponsiveScrollContainer';


interface EditCustomerProps {
  onEditCustomer: (editCustomer: {
    customerName: string;
    customerEmail: string;
    customerId: number;
    desiredSpecialization: string;
    customerContactPerson: string;
    responsibleAdmin: string;
    logoFile: File | null;
  }) => Promise<void>;
  onSave?: () => void;
}

const EditCustomerForm: React.FC<EditCustomerProps> = ({ onEditCustomer, onSave }) => {
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const [customerName, setCustomerName] = useState<string>(selectedCustomer?.customerName || "");
  const [customerEmail, setContactPersonEmail] = useState<string>(selectedCustomer?.customerEmail || "");
  const [desiredSpecialization, setDesiredSpecialization] = useState<string>(selectedCustomer?.desiredSpecialization || "");
  const [customerContactPerson, setCustomerContactPerson] = useState<string>(selectedCustomer?.customerContactPerson || "");
  const [responsibleAdmin, setResponsibleAdmin] = useState<string>(selectedCustomer?.responsibleAdmin || "");
  const [logoFile, setLogo] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null | undefined>(null);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const customerId = selectedCustomer?.customerId || 0;
  const logoUrl = selectedCustomer?.logoUrl || null;
  let logoFileName = "";

  const handleEditCustomer = async () => {
    try {
      if (requiredFieldsFilled) {
        const customerData = {
          customerName,
          customerId,
          customerEmail,
          desiredSpecialization,
          customerContactPerson,
          responsibleAdmin,
          logoFile
        };
        await onEditCustomer(customerData);
        onSave?.(); 
      }  else if(customerName === "") setNameError(true)
      if(customerEmail === "") setEmailError(true)
      } catch (error) {
        setError("An error occurred while editing the consultant.");
      }
    };

  const setCostumerLogoImage = (file: File | null) => {
    if (file !== null) {
      setLogo(file)
    }
  };

  useEffect(() => {
    if (customerName && customerEmail) {
      setRequiredFieldsFilled(true)
      setNameError(false)
      setEmailError(false)
    } else (setRequiredFieldsFilled(false))
  }, [customerName, customerEmail]);

  useEffect(() => {
    if (!!logoUrl) {
      const logoFileUrlGroup = logoUrl.match(/\/([^\/?]+)\?/);
      logoFileName = logoFileUrlGroup ? logoFileUrlGroup[1] : "";
    }
  }, [logoUrl]);



  return (
    <div className="bg-white shadow-md rounded w-full min-w-[35rem] pr-5">
              <ResponsiveScrollContainer>
      <h2 className="text-2xl font-semibold mb-6 flex  justify-start">Rediger kunde</h2>

          <div className="mb-4">
          <CustomInput
          value={customerName}
          textRed={nameError}
          onChange={(e) => setCustomerName(e.target.value)}
          label="Firma"
        /> 
        </div>
        <div className={`text-xs text-left mt-2 ${(nameError && customerName === "") ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
           
            <br />
            <div className="mb-4">
              <label className="text-left block mb-2 text-sm">
                Spesialisering
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="java"
                  name="specialization"
                  value="Java"
                  checked={desiredSpecialization === "Java"}
                  onChange={(e) => setDesiredSpecialization(e.target.value)}
                  className="form-radio text-gray-400"
                />
                <label htmlFor="java" className="ml-2 text-sm">Java</label> 
                <input
                  type="radio"
                  id="dotnet"
                  name="specialization"
                  value=".NET"
                  checked={desiredSpecialization === ".NET"}
                  onChange={(e) => setDesiredSpecialization(e.target.value)}
                  className="ml-4 form-radio text-gray-400"
                />
                <label htmlFor="dotnet" className="ml-2 text-sm">.NET</label> 
              </div>
            </div>
              <div className="mb-4">
            <CustomInput
              value={customerEmail}
              textRed={emailError}
              onChange={(e) => setContactPersonEmail(e.target.value)}
              label="E-post"
            />
            <div className={`text-xs text-left mt-2 ${(emailError && customerEmail !== selectedCustomer?.customerEmail) ? "text-red-700" : "text-black"}`}>*Nødvendig felt</div>
          </div>

          <div className="mb-4 mt-">
            <CustomInput
              id="customerContactPerson"
              value={customerContactPerson}
              onChange={(e) => setCustomerContactPerson(e.target.value)}
              label="Kontakt"
            />
          </div>


          <div className="mb-4">
            <CustomInput
              id="responsibleAdmin"
              value={responsibleAdmin}
              onChange={(e) => setResponsibleAdmin(e.target.value)}
              label="Ansvarlig Selger"
            />
          </div>

      
          <label className="text-left block mb-2 text-sm">
            Last opp Logo
          </label>
          <FileDropZoneImage
            SetImage={setCostumerLogoImage}
          />
          {(!!logoUrl && logoFile === null) || logoFile === undefined ? (
            <div>
              <FileUpload
                fileUrlReceived={!!logoUrl}
                className="space-y-2"
                fileName={logoFileName ? logoFileName : logoUrl ? logoUrl : "no file exists"}
              />
            </div>
          ) : (
            <div>
              {logoFile !== null && logoFile !== undefined ? (
                <div>
                  <FileUpload
                    fileUrlReceived={!!logoUrl}
                    className="space-y-2"
                    fileName={logoFile?.name ? logoFile.name : logoUrl ? logoUrl : "no file chosen"}
                    fileSize={logoFile ? `${(logoFile.size / 1024).toFixed(2)} KB` : ""}
                    progress={uploadProgress}
                  />
                </div>
              ) : null}
            </div>
          )}
        

        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="flex justify-end my-9">
          <button
            onClick={handleEditCustomer}
            className={`${requiredFieldsFilled ? "bg-brandBlueDark border-brandBlueDark hover:bg-brandWhite hover:text-brandBlueDark cursor-pointer" : "bg-[#696969] cursor-not-allowed"}  text-white border  w-24 px-4 py-2 rounded `}
          >Lagre
          </button>
        </div>
      </ResponsiveScrollContainer>
    </div>
  );
};

export default EditCustomerForm;
