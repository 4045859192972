import axios from "axios";
import {
  ScheduleInterview as Interview,
  ScheduleConsultant as Consultant,
  Admin,
} from "../types/types";
import { GetToken } from "../utils/GetToken";
import { CustomerManageUsers, ConsultantManageUsers } from "../types/types";
import {
  CustomerAdminConsultants,
  SelectCustomerConsultantUnit,
} from "../types/customerTypes";
import { ConsultantDetails } from "../types/consultantTypes";
import { AutoGenerateDay, Time } from "../types/interviewTypes";
import { Dispatch } from "redux";
import { SetStateAction } from "react";
import { fetchSasTokenWithUrl } from "./authApi";

const adminApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/Admin`,
});

adminApi.interceptors.request.use(
  (config) => {
    const token = GetToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchScoreSheetData = async () => {
  try {
    const response = await adminApi.get("scoring");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllAdmins = async () => {
  try {
    const response = await adminApi.get("/AllUsers");
    return response.data;
  } catch (error) {
    console.error("Error fetching admins:", error);
    throw error;
  }
};

export const fetchAllUsers = async () => {
  try {
    const response = await adminApi.get("/users");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const editCustomer = async (newCustomerData: CustomerManageUsers) => {
  try {
    const formData = new FormData();

    formData.append('customerName', newCustomerData.customerName);
    formData.append('customerId', String(newCustomerData.customerId));
    formData.append('customerEmail', newCustomerData.customerEmail);
    formData.append('desiredSpecialization', newCustomerData.desiredSpecialization);
    formData.append('customerContactPerson', newCustomerData.customerContactPerson);
    formData.append('logo', newCustomerData.logoFile || "");
    formData.append('responsibleAdmin', newCustomerData.responsibleAdmin);

    const response = await adminApi.put(
      `/customer/${newCustomerData.customerId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Consultant edited successfully:", response.data);
  } catch (error) {
    throw error;
  }
};

export const updateConsultantProfile = async (
  formData: FormData,
  setUploadProgressCv: React.Dispatch<React.SetStateAction<number>>,
  setUploadProgressProfileImage: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const consultantId = formData.get("consultantId");
    const response = await adminApi.put(
      `/consultant/${consultantId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded;
          const total = progressEvent.total;

          if (total) {
            if (formData.get("image")) {
              const progressProfileImage = Math.round((loaded * 100) / total);
              setUploadProgressProfileImage(progressProfileImage);
            }
            if (formData.get("cv")) {
              const progressCv = Math.round((loaded * 100) / total);
              setUploadProgressCv(progressCv);
            }
          }
        },
      }
    );
    console.log("Consultant edited successfully:", response.data);
  } catch (error) {
    console.error("Error updating existing consultant:", error);
  }
};

export const editConsultant = async (
  updatedConsultantData: ConsultantManageUsers
) => {
  try {
    const formData = new FormData();

    formData.append('consultantName', updatedConsultantData.consultantName);
    formData.append('consultantId', String(updatedConsultantData.consultantId));
    formData.append('consultantEmail', updatedConsultantData.consultantEmail);
    formData.append('specialization', updatedConsultantData.specialization);
    formData.append('description', updatedConsultantData.description);
    formData.append('cv', updatedConsultantData.cvFile || "");
    formData.append('image', updatedConsultantData.imageFile || "");
    formData.append('education', updatedConsultantData.education);
    formData.append('funFact', updatedConsultantData.funFact);


    const response = await adminApi.put(
      `/consultant/${updatedConsultantData.consultantId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Consultant edited successfully:", response.data);
  } catch (error) {
    throw error;
  }
};

export const editAdmin = async (adminData: Admin) => {
  try {
    const response = await adminApi.put(`/${adminData.adminId}`, adminData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAdmin = async (newAdmin: {
  adminName: string;
  adminEmail: string;
}) => {
  try {
    const response = await adminApi.post("/", newAdmin);
    return response.data;
  } catch (error) {
    console.error("Error creating admin:", error);
    throw error;
  }
};

export const deleteAdmin = async (adminId: number) => {
  try {
    const response = await adminApi.delete(`/${adminId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting admin:", error);
    throw error;
  }
};

//SCHEDULE EDITOR ENDPOINTS
export const fetchUsersTimeschedule = async () => {
  try {
    const response = await adminApi.get("/timeschedule/users");
    const responseCustomers = response.data.customers;
    const responseConsultants = response.data.consultants;

    const customers: SelectCustomerConsultantUnit[] = responseCustomers.map(
      (customer: any) => ({
        id: customer.customerId,
        name: customer.customerName,
        speciality: customer.desiredSpecialization,
      })
    );

    const consultants: SelectCustomerConsultantUnit[] = responseConsultants.map(
      (consultant: any) => ({
        id: consultant.consultantId,
        name: consultant.consultantName,
        speciality: consultant.specialization,
      })
    );

    return { customers: customers, consultants: consultants };
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchAllInterviews = async () => {
  try {
    const response = await adminApi.get("/timeschedule");
    const responseInterviews = response.data.interviews;
    const responseConsultants = response.data.consultants;
    const responseTimes = response.data.times;
 
    const interviews: Interview[] = responseInterviews.map((interview: { interviewId: number; date: string; time: string; customerId: number; customerName: string; consultantId: number; consultantName: string; }) => ({
      interviewId: interview.interviewId,
      date: interview.date,
      time: interview.time,
      customerId: interview.customerId,
      customerName: interview.customerName,
      consultantId: interview.consultantId,
      consultantName: interview.consultantName,
    }));
 
    const consultants: Consultant[] = responseConsultants.map((consultant: { consultantId: number; consultantName: string; imageUrl: string; }) => ({
      consultantId: consultant.consultantId,
      consultantName: consultant.consultantName,
      consultantImageUrl: consultant.imageUrl,
    }));
 
    const times: Time[] = responseTimes.map(({ date, time, infoslot }: { date: string; time: string; infoslot: string }) => ({ date, time, infoslot }));
 
    return await { responseInterviews: interviews, responseConsultants: consultants, responseTimes: times };
  } catch (error) {
    console.error("Error fetching interviews:", error);
  }
};

export const autoGenerateInterview = async (toBackend: AutoGenerateDay[]) => {
  try {
    const response = await adminApi.post(
      "/timeschedule/GenerateInterviews2",
      toBackend
    );
    return response.data;
  } catch (error) {
    console.error("Error creating interviews:", error);
    throw error;
  }
};

export const createInterview = async (newInterview: {
  date: string;
  time: string;
  customerId: number;
  consultantId: number;
}) => {
  try {
    const response = await adminApi.post(
      "/timeschedule/PostInterview",
      newInterview
    );
    return response.data;
  } catch (error) {
    console.error("Error creating new interview:", error);
  }
};

export const updateInterview = async (updatedInterview: {
  interviewId: number;
  newTime: string;
}) => {
  try {
    const response = await adminApi.put(
      `/timeschedule/PutInterview/${updatedInterview.interviewId}`,
      updatedInterview
    );
    return response.data;
  } catch (error) {
    console.error("Error updating existing interview:", error);
  }
};

export const deleteInterview = async (interviewId: number) => {
  try {
    const response = await adminApi.delete(
      `/timeschedule/DeleteInterview/${interviewId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting interview:", error);
    throw error;
  }
};

export const deleteConsultantInterviewsOnDay = async (
  consultantId: number,
  day: string
) => {
  try {
    const response = await adminApi.delete(
      `/timeschedule/DeleteInterviewMultiple/${consultantId}`,
      {
        data: {
          consultantId: consultantId,
          date: day,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting consultant interviews for the day:", error);
    throw error;
  }
};
export const GetAllConsultants = async () => {
  try {
    const response = await adminApi.get("/Consultants", {
      timeout: 5000,
    });
    const consultants: ConsultantDetails[] = response.data.map(
      (consultant: {
        consultantId: number;
        consultantName: string;
        imageUrl: string;
        specialization: string;
        description: string;
        cvUrl: string;
        funFact: string;
        education: string;
        customers: any[];
      }) => ({
        consultantId: consultant.consultantId,
        consultantName: consultant.consultantName,
        imageUrl:   consultant.imageUrl,
        specialization: consultant.specialization,
        description: consultant.description,
        cvUrl: consultant.cvUrl,
        funFact: consultant.funFact,
        education: consultant.education,
        customers: consultant.customers.map((customer) => {
          const toReturn: CustomerAdminConsultants = {
            customerContactPerson: customer.customer.customerContactPerson,
            customerEmail: customer.customer.customerEmail,
            customerName: customer.customer.customerName,
            phoneNumber: customer.customer.phoneNumber,
            customerScore: customer.score,
            customerNotes: customer.publicNotes,
          };
          return toReturn;
        }),
      })
    );

    //Calculate average score and progress
    consultants.forEach((consultant) => {
      //Average score
      let allScores: number[] | undefined = consultant.customers?.map(customer => customer.customerScore);
      allScores = allScores?.filter(Number)
      if(allScores !== undefined && allScores.length > 0){
        const avgScore: number = allScores.reduce((a,b) => a+b) / allScores.length;
        consultant.avgScore = avgScore;
      }else consultant.avgScore = null;

      //Progress
      const dependencies: string[] = [
        "imageUrl",
        "description",
        "cvUrl",
        "funFact",
        "education",
      ];
      //console.log(dependencies)
      let progress: number = 0;
      let property: keyof typeof consultant;
      for (property in consultant) {
        if (dependencies.includes(property.toString()))
          if (consultant[property]) progress++;
      }
      consultant.progress = (progress / dependencies.length) * 100;
    });

    return consultants;
  } catch (error) {
    console.error("Error fetching consultants:", error);
    throw error;
  }
};

export const createCustomer = async (newCustomer: {
  customerName: string;
  customerEmail: string;
  desiredSpecialization: string;
  customerContactPerson: string;
  responsibleAdmin: string;
  logoFile: File | null;
}) => {
  try {
    const formData = new FormData();
    Object.entries(newCustomer).forEach(([key, value]) => {
      formData.append(key, value as string | Blob);
    });

    formData.append('customerName', newCustomer.customerName);
    formData.append('customerEmail', newCustomer.customerEmail);
    formData.append('desiredSpecialization', newCustomer.desiredSpecialization);
    formData.append('customerContactPerson', newCustomer.customerContactPerson);
    formData.append('responsibleAdmin', newCustomer.responsibleAdmin);
    formData.append('logo', newCustomer.logoFile || "");


    const response = await adminApi.post("/customer", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error creating customer:", error);
    throw error;
  }
};


export const deleteCustomer = async (customerId: number) => {
  try {
    const formData = new FormData();
    formData.append('customerId', String(customerId));

    const response = await adminApi.delete(`/customer/${customerId}`, {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error deleting customer:", error);
    throw error;
  }
};

export const createConsultant = async (newConsultant: {
  consultantName: string;
  consultantEmail: string;
  specialization: string;
}) => {
  try {
    const response = await adminApi.post("/Consultant", newConsultant, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error creating consultant:", error);
    throw error;
  }
};

export const deleteConsultant = async (consultantId: number) => {
  try {
    const response = await adminApi.delete(`/Consultant/${consultantId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting consultant:", error);
    throw error;
  }
};
