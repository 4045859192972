import ManageUsersView from "../../components/Admin/manageUsers/ManageUsersView";

function ManageUsersPage() {
    return (
      <div style={{ zoom: '80%' }}>
        <ManageUsersView />
      </div>
    );
  }

export default ManageUsersPage