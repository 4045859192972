import {
  ConsultantFilterPropsCustomer, SortMethod
} from "../../types/filterTypes";
import FilterField from "../common/FilterField";

const ConsultantFilterCustomer: React.FC<ConsultantFilterPropsCustomer> = ({
  sortOrder,
  sortKey,
  onFilterChange,
}) => {
  const options: SortMethod[] = [
    {attribute: "consultantName", order: "asc", text: "Navn: A - Å" },
    {attribute: "consultantName", order: "desc", text: "Navn: Å - A" },
    {attribute: "score", order: "desc", text:"Score: Høyest - Lavest"},
    {attribute: "score", order: "asc", text:"Score: Lavest - Høyest"},
  ]

  return (
    <div className="flex flex-col ">
      {options.map(option => 
        <FilterField 
          active={option.attribute === sortKey && option.order === sortOrder} 
          onClick={() => onFilterChange(option.attribute, option.order)}
          text={option.text}/>
      )}
    </div>
  );
};

export default ConsultantFilterCustomer;
