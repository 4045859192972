import React, { forwardRef, ReactNode, ForwardedRef } from "react";
interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  triggerId: string;
}

/**
 * Popup is a popup that is to be used to display content as a popup overlaying the content on the page
 * @param isOpen - boolean that is true if the popup is open
 * @param onClose - function that is called when the popup is closed
 * @param children - the content of the popup
 * @Component
 */
const Popup = forwardRef<HTMLDivElement, PopupProps>((props, ref) => {
  const { isOpen, onClose, children } = props;
  if (!isOpen) return null;

  const triggerElement = document.getElementById(props.triggerId);

  if (!triggerElement) return null;

  const rect = triggerElement.getBoundingClientRect();
  const popupStyle: React.CSSProperties = {
    position: "absolute",
    //-80 to account for the height of the navbar
    top: rect.bottom + window.scrollY - 80 + "px",
    left: rect.left + window.scrollX + "px",
    minWidth: rect.width + "px",
  };

  return (
    <div
      style={popupStyle}
      className="z-10"
      ref={ref as ForwardedRef<HTMLDivElement>}
    >
      <div className="bg-white border border-black rounded-b-md flex flex-col relative overflow-hidden">
        {/* <CloseOutlined
          className=" absolute self-end cursor-pointer"
          onClick={onClose}
        /> */}
        {children}
      </div>
    </div>
  );
});

export default Popup;
