import React from "react";

const ConsentDoc = () => {
  return (
    <div className="p-10">
      <h1>
        Samtykkeerklæring for konsulenter - Speedintervjudagene – Experis
        Academy
      </h1>
      <br />
      <p>
        Velkommen til Speedintervjudagene hos Experis Academy! Som konsulent hos
        Experis er ditt personvern av største betydning for oss. Vi ønsker
        derfor å informere deg om vår behandling av dine personopplysninger.
        Informasjonssamling: For å kunne tilby denne tjenester, samler vi inn
        personopplysninger som ditt fulle navn, alder, utdanningsbakgrunn,
        arbeidserfaring, e-postadresse og telefonnummer. Dette gjør vi for å
        kunne identifisere deg, vurdere dine kvalifikasjoner, og forbedre
        tjenestene vi tilbyr. Formål med informasjonssamling: Dine opplysninger
        hjelper oss å matche deg med relevante prosjekter, koordinere dialog
        mellom deg og våre kunder, og øke kvaliteten på våre tjenester. Ditt
        samtykke til behandling: Ved å logge inn og bruke vår plattform
        samtykker du til behandlingen av dine personopplysninger i henhold til
        våre personvernregler. Du har rett til å trekke tilbake dette samtykket
        når som helst. Dine rettigheter: Du har rett til innsyn, rettelse,
        sletting, begrensning av behandling, dataportabilitet, og å gjøre
        innsigelser mot behandlingen. For å utøve disse rettighetene, kontakt
        oss gjerne. For mer detaljert informasjon, vennligst les vår
        fullstendige personvernrapport (Link her). Ved å trykke "bekreft",
        bekrefter du at du har lest, forstått og samtykker til våre
        personvernregler.
      </p>

      <br />
      <h1>
        Samtykkeerklæring for kunder - Speedintervjudagene – Experis Academy
      </h1>
      <br />
      <p>
        Velkommen til Speedintervjudagene hos Experis Academy! Som kunde hos
        Experis er ditt personvern viktig for oss. Vi ønsker derfor å informere
        deg om vår håndtering av dine personopplysninger. Informasjonssamling:
        For å kunne tilby deg våre tjenester, samler vi inn dine
        personopplysninger. Dette hjelper oss i å identifisere deg og forbedre
        våre tjenester. Formål med informasjonssamling: Dine opplysninger er
        nødvendige for å fasilitere dialog mellom deg og våre konsulenter, samt
        for å forbedre kvaliteten på tjenestene vi tilbyr. Ditt samtykke til
        behandling: Ved å logge inn og bruke vår plattform, samtykker du til
        behandlingen av dine personopplysninger i henhold til våre
        personvernregler. Dette samtykket kan trekkes tilbake når som helst.
        Dine rettigheter: Du har rett til innsyn, rettelse, sletting,
        begrensning av behandling, dataportabilitet, og å gjøre innsigelser mot
        behandlingen. Ta kontakt med oss for å utøve disse rettighetene. For
        ytterligere informasjon, vennligst se vår fullstendige personvernrapport
        (link her). Ved å trykke "bekreft", bekrefter du at du har lest,
        forstått og samtykker til våre personvernregler. Takk for at du deltar
        på Speedintervjudagene!
      </p>
    </div>
  );
};

export default ConsentDoc;
