// ScoreIndicator.tsx

import { RadioGroup } from "@mui/joy";
import { useState } from "react";
import { ScoreCircle } from "./ScoreCircle";
import { scoreLevels } from "../../../types/ScoreLevelData";
import ScoreInfo from "./ScoreInfo";
interface ScoreIndicatorProps {
  setScoreLevel: React.Dispatch<React.SetStateAction<number | null>>;
  scoreLevel: number | null;
}

const ScoreIndicator: React.FC<ScoreIndicatorProps> = ({
  setScoreLevel,
  scoreLevel,
}) => {
  const [scoreValue, setScoreValue] = useState<number | null>(scoreLevel);

  const handleButtonClick = (score: number) => {
    setScoreValue(score);
    setScoreLevel(score);
  };

  return (
    <div className="flex gap-4">
      <RadioGroup
        sx={{ gap: 2, mb: 2, flexWrap: "wrap", flexDirection: "row" }}
      >
        {scoreLevels.map((scoreData) => (
          <ScoreCircle
            key={scoreData.score}
            number={scoreData.score}
            text={scoreData.tooltipContent}
            clicked={scoreData.score === scoreLevel}
            onClick={() => handleButtonClick(scoreData.score)}
            customSize="w-16 h-16"
            textSize="text-[40px]"
          />
        ))}
      </RadioGroup>
      <ScoreInfo />
    </div>
  );
};

export default ScoreIndicator;
