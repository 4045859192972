// ConsultantOverviewPage.tsx
import React, { useEffect, useState } from "react";
import { extractIdFromJWT, extractRoleFromJWT } from "../utils/JwtExtraction";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { GetConsultantsToMeet } from "../api/customerApi";
import { GetAllConsultants } from "../api/adminApi";
import ConsultantFeedback from "../components/customer/ConsultantFeedback";
import {
  ConsultantMeeting,
  ConsultantNoMeeting,
} from "../types/consultantTypes";
import { ConsultantDetails as ConsultantAdmin } from "../types/consultantTypes";
import ConsultantDetails from "../components/customer/ConsultantDetails";
import ConsultantListSection from "../components/common/ConsultantListSection";
import ConsultantScoreCardGroup from "../components/Admin/ConsultantScoreCardGroup";
import { sortByKey } from "../utils/SortingUtils";

type ConsultantCustomer = ConsultantNoMeeting | ConsultantMeeting;
type Consultant = ConsultantAdmin | ConsultantCustomer;

const ConsultantOverviewPage: React.FC = () => {
  const [consultants, setConsultants] = useState<ConsultantAdmin[] | ConsultantCustomer[]>([]);
  const [filteredConsultants, setFilteredConsultants] = useState<ConsultantAdmin[] | ConsultantCustomer[]>([]);

  const [activeConsultant, setActiveConsultant] = useState<Consultant | null>(
    null
  );
  const [role, setRole] = useState<string>("");
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const customerIdString = extractIdFromJWT(token);
  const customerId = parseInt(customerIdString, 10);
  /**
   * Set the role of the user based on the JWT token when token is updated.
   */
  useEffect(() => {
    const role = extractRoleFromJWT(token);
    setRole(role);
  }, [token]);

  useEffect(() => {
    if (role.length > 1) FetchAllConsultants();
  }, [role]);

  const FetchAllConsultants = async () => {
    if (role === "Customer") {
      const response = await GetConsultantsToMeet(customerId);
      setConsultants(sortByKey<ConsultantCustomer>(response.consultants,"consultantName"));
    }
    if (role === "Admin") {
      const response = await GetAllConsultants();
      setConsultants(sortByKey<ConsultantAdmin>(response,"consultantName"));
    }
  };

  return (
    <div className="flex w-full h-full pb-4">
      <ConsultantListSection
        role={role}
        customerId={customerId}
        consultants={consultants}
        setActiveConsultant={setActiveConsultant}
        setFilteredConsultants={setFilteredConsultants}
        setConsultants={setConsultants}
        filteredConsultants={filteredConsultants}
      />

      {activeConsultant && (
        <div className=" py-24 px-14 w-full overflow-y-auto">
          <ConsultantDetails consultant={activeConsultant} 
          otherDesign={role === "Customer" ? true : false}
          cvWidth="41vw">
            {role === "Customer" && (
              <ConsultantFeedback
                customerId={customerId}
                viewModeAvailable={true}
                consultant={activeConsultant as ConsultantCustomer}
                consultants={consultants as ConsultantCustomer[]}
                setConsultants={setConsultants}
              />
            )}
          </ConsultantDetails>

          {role === "Admin" && (
            <ConsultantScoreCardGroup consultant={activeConsultant} />
          )}
        </div>
      )}
    </div>
  );
};

export default ConsultantOverviewPage;
