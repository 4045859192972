export const extractRoleFromJWT = (token: string | null): string => {
  if (!token) {
    return "";
  }

  // Decode the JWT token
  const decodedToken: any = JSON.parse(atob(token.split(".")[1]));
  // Extract the role from the "role" claim
  const role: string | undefined =
    decodedToken[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];

  return role || "";
};

export const extractIdFromJWT = (token: string | null): string => {
  if (!token) {
    return "";
  }

  const decodedToken: any = JSON.parse(atob(token.split(".")[1]));

  // Extract the id from the "id" claim
  const id: string | undefined = decodedToken.Id;

  return id || "";
};

export const extractExpirationFromJWT = (token: string | null): number => {
  if (!token) {
    return 0;
  }

  const decodedToken: any = JSON.parse(atob(token.split(".")[1]));

  // Extract the expiration from the "exp" claim
  const expiration: number | undefined = decodedToken.exp;

  return expiration || 0;
};
