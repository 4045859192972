import { useEffect, useState } from "react";
import { ConsultantDetails } from "../../types/consultantTypes";
import Card from "@mui/joy/Card";
import { Button, CardContent, CardOverflow, Typography } from "@mui/joy";
import EditProfileDrawer from "./EditProfileDrawer";
import { fetchConsultantById } from "../../api/consultantApi";
import { Button as CommonButton } from "../common/Button";
import { GetToken } from "../../utils/GetToken";
import {
  extractIdFromJWT,
  extractRoleFromJWT,
} from "../../utils/JwtExtraction";

const ConsultantProfileCard = () => {
  const [consultant, setConsultant] = useState<ConsultantDetails>({
    consultantId: 0,
  });
  const [blobCvUrl, setBlobCvUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const consultantId: number = parseInt(extractIdFromJWT(GetToken()));
    fetchDataById(consultantId);
    setBlob();
  }, [
    consultant.consultantEmail,
    consultant.consultantName,
    consultant.education,
    consultant.cvUrl,
    consultant.cvFile,
    consultant.imageFile,
    consultant.imageUrl,
    consultant.description,
    consultant.funFact,
  ]);

  const fetchDataById = async (consultantId: number) => {
    try {
      //use consultantId for production and a number for testing
      const result = await fetchConsultantById(consultantId);
      setConsultant(result);
      splitEducationToLevelAndName();
      urlToFileName();
    } catch (error) {
      console.error("Error fetching consultant data:", error);
    }
  };

  const urlToFileName = () => {
    const imagefileUrl = consultant.imageUrl;
    const cvfileUrl = consultant.cvUrl;
    if (!!imagefileUrl && !!cvfileUrl) {
      const imageFileUrlGroup = imagefileUrl.match(/\/([^\/?]+)\?/);
      const cvFileUrlpGroup = cvfileUrl.match(/\/([^\/?]+)\?/);

      setConsultant((prevConsultant) => ({
        ...prevConsultant,
        imageFileName: imageFileUrlGroup ? imageFileUrlGroup[1] : null,
        cvFileName: cvFileUrlpGroup ? cvFileUrlpGroup[1] : null,
      }));
      console.log("urlToFileName cvfileName: " + cvFileUrlpGroup![1]);
      console.log("urlToFileName ImageFileName: " + imageFileUrlGroup![1]);
    }
  };

  const splitEducationToLevelAndName = () => {
    if (consultant.education) {
      const educationString: string = consultant.education.trim();
      console.log(educationString)
      
      if (educationString.length > 0) {
        const words: string[] = educationString.split(" ");
  
        if (words.length > 0) {
          const newDegreeLevel: string = words[0];
          const nameOfDegree: string = words.slice(1).join(" ").trim();
  
          console.log("newDegreeLevel: " + newDegreeLevel);
          console.log("nameOfDegree: " + nameOfDegree);
  
          setConsultant((prevConsultant) => ({
            ...prevConsultant,
            degreeLevel: newDegreeLevel,
            educationName: nameOfDegree,
          }));
        } else {
          console.error("Education string is empty");
        }
      } else {
        console.error("Education string is undefined or empty");
      }
    } else {
      console.error("Education is undefined");
    }
  };
  
  const setBlob = async () => {
    try {
      if (consultant.cvUrl) {
        const response = await fetch(consultant.cvUrl);
        const blob = await response.blob();
        const blobCopy = blob.slice(0, blob.size, "application/pdf");
        console.log(`Blob MIME type: ${blob.type}`);
        const localUrl = URL.createObjectURL(blobCopy);
        setBlobCvUrl(localUrl);
        console.log("Blob cv url", blobCvUrl);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching CV blob:", error);
    }
  };

  const handleOpenCvDirectly = () => {
    if (blobCvUrl) {
      window.open(blobCvUrl, "_blank");
    } else {
      console.log("Error opening blob");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-2 mt-11 ml-24">
      <Card
        orientation="horizontal"
        variant="plain"
        sx={{ width: "80%", backgroundColor: "transparent", border: "none" }}
      >
        <CardOverflow sx={{ width: "16%", flexDirection: "column" }}>
          <div className="h-[70%] space-y-2">
            {consultant.imageUrl && typeof consultant.imageUrl === "string" ? (
              <img
                src={consultant.imageUrl}
                alt="Not Found"
                className="max-w-full w-full h-full object-cover rounded-lg"
              />
            ) : consultant.imageFile instanceof File ? (
              <img
                src={URL.createObjectURL(consultant.imageFile)}
                alt="Not Found"
                className="max-w-full w-full h-full object-cover rounded-lg"
              />
            ) : (
              <img
                src={"/assets/person.svg"}
                alt="Placeholder"
                className="max-w-full w-full h-full object-cover rounded-lg"
              />
            )}
          </div>
          <div className="flex flex-col mt-2">
            <CommonButton
              text="Din CV"
              custom={`bg-brandBlueDark text-xl py-2 ${
                !consultant.cvUrl ? "opacity-40" : ""
              }`}
              onClick={handleOpenCvDirectly}
              disabled={!blobCvUrl}
            />
            <EditProfileDrawer
              consultant={consultant}
              setConsultant={setConsultant}
            />
          </div>
        </CardOverflow>
        <CardContent
          sx={{
            marginLeft: "4%",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <Typography level="h1" textColor="#1E3046" mb={1}>
            {consultant.consultantName}
          </Typography>
          <Typography level="h4" mb={1}>
            {consultant.specialization}
          </Typography>
          <div className="flex">
            <Typography fontStyle="revert" fontWeight="bold" level="h4" mb={1}>
              {consultant.degreeLevel}
            </Typography>
            {/* this should work */}

            {!!consultant.degreeLevel && !!consultant.consultantName ? (
              <Typography fontStyle="italic" fontWeight="400" level="h4" mb={1}>
                &nbsp;i&nbsp;
              </Typography>
            ) : null}

            <Typography fontStyle="italic" fontWeight="400" level="h4" mb={1}>
              {consultant.educationName}
            </Typography>
          </div>

          <div></div>
          <Typography level="h4">Sammendrag</Typography>
          <Typography level="body-sm" mb={1}>
            {consultant.description}
          </Typography>
          <Typography level="h4">Fun fact</Typography>
          <Typography level="body-sm">{consultant.funFact}</Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ConsultantProfileCard;
