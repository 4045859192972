import React, { useEffect, useState } from "react";
import InterviewTile from "./InterviewTile";
import { ConsultantMeeting, Pause } from "../../types/consultantTypes";
import { FaClock } from "react-icons/fa";

interface DayOverviewProps {
  date: string;
  items: (ConsultantMeeting | Pause)[];
  setActiveConsultant: (consultantId: number) => void;
}

const DayOverview: React.FC<DayOverviewProps & { consultantScores: { [consultantId: number]: boolean } }> = ({
  date,
  items,
  setActiveConsultant,
  consultantScores,
}) => {
  const [activeConsultantId, setActiveConsultantId] = useState<number | null>(null);

  // Filter today's interviews and pauses
  const todaysItems = items.filter(item => {
    const itemDate = 'interview' in item ? item.interview.time : item.dateTime;
    return new Date(itemDate).toDateString() === new Date().toDateString();
  });

  const handleTileClick = (consultantId: number) => {
    setActiveConsultantId(consultantId);
    setActiveConsultant(consultantId);
  };

  const renderItems = todaysItems.map((item, index) => {
    if ('consultantId' in item) {
      // Render consultant tile
      return (
        <InterviewTile
          key={`consultant-${item.consultantId}`}
          onClick={() => handleTileClick(item.consultantId)}
          isActive={item.consultantId === activeConsultantId}
          consultant={item}
        />
      );
    } else {
      // Render pause
      const pauseTime = new Date(item.dateTime);
      return (
        <div
          key={`pause-${index}`}
          className="w-full bg-[#4C79AF] py-2 px-8 rounded-md shadow-lg mb-2 flex items-center justify-between text-white"
        >
          <time className="flex-1 text-left text-bold">
            {pauseTime.toLocaleTimeString('no-NO', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </time>
          <span className="flex-1 text-right">PAUSE</span>
        </div>
      );
    }
  });

  return (
    <div className="space-y-2 overflow-y-scroll max-h-screen px-10 pb-40 pt-5 bg-brandBG" style={{ maxHeight: "calc(100vh - 1rem)" }}>
      <h4 className="text-s text-left capitalize text-bold">{date}</h4>
      <h4 className="text-s text-left mt-5 mb-2">Dine intervjuer</h4>
      {todaysItems.length === 0 ? (
        <p className="text-center text-black mt-7">
          <FaClock className="inline mr-1" />
          Ingen intervjuer idag.
        </p>
      ) : (
        renderItems
      )}
    </div>
  );
};

export default DayOverview;




