import CircularProgress from "@mui/joy/CircularProgress";

interface ConsultantProfileProgressProps {
  progress: number;
}

const ConsultantProfileProgress: React.FC<ConsultantProfileProgressProps> = ({
  progress,
}) => {
  return (
    <div>
      {progress !== 100 && (
        <CircularProgress
          determinate
          variant="solid"
          value={progress}
          sx={{ '--CircularProgress-size': '32px' }}
          thickness={4}
          color="danger"
          className=" bg-brandBlueDark text-brandWhite rotate-180"
        >
          <p className=" text-brandWhite text-xs rotate-180">{progress}%</p>
        </CircularProgress>
      )}
    </div>
  );
};

export default ConsultantProfileProgress;
