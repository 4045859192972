import React from 'react'

function LoadingSpinner() {
  return (
    <svg className="animate-spin h-16 w-16 mr-3" viewBox="0 0 24 24">
<circle className="stroke fill-transparent" strokeWidth="2" stroke="#1E3046" cx="12" cy="12" r="10"></circle>
        <path
        fill="#4C79AF"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 0112"
    ></path>
  </svg>
  )
}

export default LoadingSpinner