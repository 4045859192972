import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";
import { generateOTP, verifyOTP } from "../../api/authApi";
import ExperisAcademyLogo from "../../assets/experis_academy_logo.png";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/authSlice";
import InputField from "./InputField";
import { MdMailOutline } from "react-icons/md";
import SnackBarCustom from "./SnackBarCustom";
import { Snackbar } from "@mui/joy";
interface LoginFormProps {
  // Additional props can be added as needed
}

const LoginForm: React.FC<LoginFormProps> = () => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState<string>("");
  const [sentPassword, setSentPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [mailError, setMailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState<boolean>(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsEmailLoading(true);
    setOpen(true);
    try {
      await generateOTP(email);
      setIsEmailSubmitted(true);
      setMailError(false);
      setConfirmationMessage("Passordet er blitt sendt på nytt.");
    } catch (error) {
      setErrorMessage("Email is invalid");
      setMailError(true);
    } finally {
      setIsEmailLoading(false);
    }
  };

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await verifyOTP(email, sentPassword);
      const { token } = response;

      dispatch(login(token));

      navigate("/agenda");
    } catch (error) {
      setErrorMessage("Invalid password");
      setPasswordError(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full grow">
      <div className="flex flex-col gap-2 w-2/3">
        <img
          src={ExperisAcademyLogo}
          alt="Experis Academy logo"
          className=" w-4/5 md:w-2/5"
        />
        <h1 className="text-4xl font-bold mb-4">
          Velkommen til
          <br /> speedintervjudagene!
        </h1>
        <p>Fyll inn e-postadressen din og få engangspassord på e-post. </p>

        <form
          className="w-full flex flex-col gap-5 "
          onSubmit={isEmailSubmitted ? handlePasswordSubmit : handleEmailSubmit}
        >
          <div className="w-full lg:w-3/5">
            <InputField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              label="E-post"
            />
            {mailError && (
              <div className="text-red-700">
                E-posten er ikke registrert i systemet. Pass på at den er
                skrevet riktig. <br />
                Snakk med administrator dersom problemet vedvarer
              </div>
            )}
          </div>
          <div className="w-full md:w-3/5">
            {isEmailSubmitted ? (
              <div>
                <div className="border-4 rounded-md border-brandGreen py-2 px-4 flex items-center gap-3">
                  <MdMailOutline size={"2rem"} />
                  <div className="flex gap-1 flex-col">
                    <p>Du vil nå motta et engangspassord på e-post</p>
                  </div>
                </div>
                <p
                  className="text-brandBlueLight underline cursor-pointer hover:text-brandBlueDark"
                  onClick={handleEmailSubmit}
                >
                  Send engangspassord på nytt
                </p>

                {confirmationMessage && (
                  <div>
                    <Snackbar
                      variant="soft"
                      color="success"
                      autoHideDuration={3000}
                      open={open}
                      onClose={() => setOpen(false)}
                    >
                      Passordet er blitt sendt
                    </Snackbar>
                  </div>
                )}
              </div>
            ) : (
              <Button
                type="submit"
                text="MOTTA ENGANGSPASSORD"
                disabled={isEmailLoading}
              />
            )}
          </div>

          {isEmailSubmitted ? (
            <div className="flex flex-col w-full items-center lg:items-end gap-2 lg:flex-row h-full ">
              <div className="w-full lg:w-3/5">
                <InputField
                  value={sentPassword}
                  onChange={(e) => setSentPassword(e.target.value)}
                  required
                  label="Passord fra e-post"
                  type="password"
                />
                {passwordError && (
                  <div className="text-red-700">Ugyldig Passord</div>
                )}
              </div>
              <div className="w-full lg:w-fit">
                <Button type="submit" text="LOGG INN" />
              </div>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
