interface FilterFieldProps{
    active: boolean,
    onClick: () => void,
    text: string
}

const FilterField: React.FC<FilterFieldProps> = ({
  active,
  onClick,
  text
}) => {
  return (
        <div className={`p-2 border-black border-b cursor-pointer  ${
            active ? "bg-brandBlueDark text-white" : ""
          }`}
          onClick={onClick}
        >
          {text}
        </div>
)};
export default FilterField;
