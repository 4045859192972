import axios from "axios";
import { Customer } from "../types/types";
import { GetToken } from "../utils/GetToken";
import { InterviewWithoutTime, UpdateInterviewScoreAndNotes } from "../types/interviewTypes";

const customerApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/Customer`,
});

customerApi.interceptors.request.use(
  (config) => {
    const token = GetToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchAllCustomers = async () => {
  try {
    const response = await customerApi.get("/GetAll", {
      params: {
        fields: "customerId,customerName,email,contactPersonEmail",
      },
    });

    console.log("API Response:", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCustomer = async (newCustomerData: Customer) => {
  try {
    await customerApi.post("/", newCustomerData);
    const updatedCustomers = await fetchAllCustomers();
    return updatedCustomers;
  } catch (error) {
    throw error;
  }
};



export const deleteCustomer = async (customerId: number) => {
  try {
    console.log("Deleting customer with ID:", customerId);
    await customerApi.delete(`/${customerId}`);
    const updatedCustomers = await fetchAllCustomers();
    return updatedCustomers;
  } catch (error) {
    throw error;
  }
};

export const GetConsultantsToMeet = async (customerId: number) => {
  try {
    const response = await customerApi.get(
      `/agenda/${customerId}`,
      {
        timeout: 5000,
      }
    );
    return response.data;
  } catch (error) {
    console.log("API Error:", error);
    throw error;
  }
};

export const GetConsultantsNoMeeting = async (customerId: number) => {
  try {
    const response = await customerApi.get(
      `/ConsultantsNoMeeting/${customerId}`,
      {
        timeout: 5000,
      }
    );
    return response.data;
  } catch (error) {
    console.log("API Error:", error);
    throw error;
  }
};

export const createInterviewWithoutTime = async (newInterviewWithoutTime: InterviewWithoutTime) => {
  try {
    await customerApi.post("/Consultant/interviewWithoutTime", newInterviewWithoutTime);
    const updatedConsultants = await GetConsultantsToMeet(newInterviewWithoutTime.customerId);
    return updatedConsultants.consultants;
  } catch (error) {
    throw error;
  }
};

export const updateInterview = async (
  interviewId: number,
  data: UpdateInterviewScoreAndNotes
) => {
  try {
    const response = await customerApi.put(
      `/Consultant/updateInterview/${interviewId}`,
      data,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
