import * as React from "react";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import Sheet from "@mui/joy/Sheet";
import { Drawer } from "@mui/material";

interface DrawerProps {
    title?: string;
    children?: React.ReactElement;
    onClose?: () => void;
    width?: string;
    isOpen: boolean;
    variant?: "temporary" | "persistent";
}
const BasicDrawer: React.FC<DrawerProps> = ({
    children,
    title,
    onClose,
    width = "50vw", // Optional prop for maximum width, default is 50vw(view-width)
    isOpen,
    variant = "persistent" // Default variant is persistent, set it to temporary for backdrop
}) => {

    return (
        <Drawer
            open={isOpen}
            onClose={onClose}
            hideBackdrop={!variant}
            variant={variant}
            sx={{
                '& .MuiDrawer-paper': {
                    overflow: "unset",
                    marginTop: 10
                },
            }}>
            <Sheet
                sx={{
                    height: "100%",
                    width: { width },
                }}
            > <ModalClose onClick={onClose} />
                <DialogTitle
                    sx={{
                        pt: 2,
                        pl: 2,
                        pb: 1
                    }}
                >{title}</DialogTitle>
                <Divider sx={{
                    mt: "auto"
                }} />
                {children}
            </Sheet>
        </Drawer >
    );
}
export default BasicDrawer;
