import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useRef } from "react";
import { IconButton } from "@mui/material";
import {
  ScheduleInterview as Interview,
  ScheduleConsultant as Consultant,
} from "../../../types/types";
import { MdClose } from "react-icons/md";
import DialogCustom from "../../common/DialogCustom";
import { DeleteButton } from "../../common/DeleteButton";

interface InterviewModalProps {
  active: string;
  consultants: Consultant[];
  selectedInterview: Interview | null;
  setVisibleModal: React.Dispatch<React.SetStateAction<string>>;
  onDeleteInterview: (interviewId: number) => void;
  onDeleteAllInterviews: (consultantId: number, selectedDate: string) => void;
}

export const InterviewModal: React.FC<InterviewModalProps> = ({
  active,
  consultants,
  selectedInterview,
  setVisibleModal,
  onDeleteInterview,
  onDeleteAllInterviews,
}) => {

  // If the modal is not for deletion or there is no selectedInterview, return null
  if (active !== "delete" || !selectedInterview) {
    return null;
  }
  // Find the selected consultant based on consultantId
  const selectedConsultant = consultants.find(
    (consultant) => consultant.consultantId === selectedInterview?.consultantId
  );


  return (
    <Dialog open={true} onClose={() => setVisibleModal("")} >
      <IconButton
        aria-label="delete"
        size="large"
        sx={{
          position: "absolute",
          right: 4,
          top: 8,
        }}
      >
        <MdClose fontSize="inherit" onClick={() => setVisibleModal("")} />
      </IconButton>
      <DialogContent className="flex flex-col content-center max-auto gap-3 m-8 h-full " >
        <img src={selectedConsultant?.consultantImageUrl} alt="Not found" className=" aspect-auto rounded-md h-44" />
        <div className="text-center"><b>{selectedInterview.consultantName}</b>
          <p>{selectedInterview.date} </p>
          <p>{selectedInterview.time}- Slutt tid</p></div>

        {/* DialogCustom for deleting individual interview */}
        <DialogCustom
          buttonText="Slett Intervju"
          dialogText="Er du sikker på at du vil slette intervjuet?"
          onConfirm={() => onDeleteInterview(selectedInterview.interviewId)}
          option="Ja, slett"
          triggerComponent={<DeleteButton text="Slett Intervju" ></DeleteButton>}
        />

        {/* DialogCustom for deleting all interviews */}
        <DialogCustom
          buttonText="Slett alle intervjuer"
          dialogText={`Er du sikker på at du vil slette alle intervjuene til ${selectedInterview.consultantName}?`}
          onConfirm={() =>
            onDeleteAllInterviews(
              selectedInterview.consultantId,
              selectedInterview.date
            )
          }
          option="Ja, slett alle intervjuer."
          triggerComponent={<DeleteButton text="Slett alle intervjuer" />}
        />

      </DialogContent>
    </Dialog >
  );
};
