import { MdOutlineFilterAlt, MdExpandLess, MdExpandMore } from "react-icons/md";
import { forwardRef } from "react";
import { Button } from "./Button";

interface FilterDropdownButtonProps {
  text: string;
  onClick: () => void;
  open: boolean;
  icon?: JSX.Element;
  id?: string;
}

/**
 * FilterDropdownButton is a button that is to be used to open a filter popup
 * @param onClick - function that is called when the button is clicked
 * @param open - boolean that is true if the popup is open
 * @Component
 */

const FilterDropdownButton = forwardRef<
  HTMLDivElement,
  FilterDropdownButtonProps
>((props, ref) => {
  const { onClick, open } = props;
  return (
    // TODO: fix min width to match search bar when searchbar is updated
    <button>
    <div
      className={`flex gap-3  border-black p-2  h-fit w-fit min-w-[120px] justify-between items-center ${
        open ? "rounded-t-md border-x border-t" : "rounded-md border"
      }`}
      id={props.id}
      onClick={onClick}
    >
      <div>{props.icon ? props.icon : <MdOutlineFilterAlt />}</div>
      {props.text}
      {open ? <MdExpandLess /> : <MdExpandMore />}
    </div>
    </button>
  );
});

export default FilterDropdownButton;
