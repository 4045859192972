import React, { useState } from "react";
import BasicTooltip from "../ToolTip";
import { scoreLevels } from "../../../types/ScoreLevelData";

interface ButtonProps {
  number: number;
  text?: string;
  clicked?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  customStyle?: string;
  customSize?: string;
  textSize?: string;
  floatNum?: true;
}

export const ScoreCircle: React.FC<ButtonProps> = ({
  number,
  text = '',
  clicked = true,
  textSize = "text-4xl",
  onClick,
  disabled,
  customStyle,
  floatNum,
  customSize,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const circleStyle = ` font-montserrat font-medium text-center border-4
    ${customStyle ? customStyle : "rounded-full aspect-square"}
    ${customSize ? customSize : "w-16 h-16"}
    ${textSize} 
    ${disabled ? scoreLevels[Math.round(number) - 1].color : `
      ${clicked ? "bg-brandBlueDark border-brandBlueDark text-brandWhite" : ""} 
      ${isHovered && !clicked ? "hover:shadow-md bg-white border-brandBlueDark text-brandBlack" : ""} 
      ${clicked && !isHovered ? "bg-brandBlueDark border-brandBlueDark text-brandWhite" : ""} 
      ${!isHovered && !clicked ? "bg-brandGrayLight text-brandBlack border-brandOrange-regular " : ""}
    `}`;

  return (
    <BasicTooltip text={text}>
      <button
        className={circleStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}
        disabled={disabled}
      >
        {floatNum ? number.toFixed(1) : Math.round(number)}
      </button>

    </BasicTooltip>
  );
};
