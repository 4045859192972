import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ScoreIndicator from "../common/score/ScoreIndicator";
import { Button } from "../common/Button";
import RichTextEditor from "../common/RichTextEditor";
import { ScoreCircle } from "../common/score/ScoreCircle";
import SnackBarCustom from "../common/SnackBarCustom";
import {
  createInterviewWithoutTime,
  updateInterview,
} from "../../api/customerApi";
import {
  ConsultantMeeting,
  ConsultantNoMeeting,
} from "../../types/consultantTypes";
import { removeDuplicates } from "../../utils/ArrayHelper";
import { sortByKey } from "../../utils/SortingUtils";

interface ConsultantFeedbackProps {
  viewModeAvailable?: boolean;
  consultant: ConsultantMeeting | ConsultantNoMeeting;
  consultants?: (ConsultantMeeting | ConsultantNoMeeting)[];
  setConsultants?: (
    consultants: (ConsultantMeeting | ConsultantNoMeeting)[]
  ) => void;
  customerId?: number;
}
const ConsultantFeedback: React.FC<ConsultantFeedbackProps> = ({
  viewModeAvailable = false,
  consultants,
  setConsultants,
  consultant,
  customerId,
}) => {
  const [publicNotes, setPublicNotes] = useState(
    "interview" in consultant ? consultant.interview.publicNotes : ""
  );
  const [privateNotes, setPrivateNotes] = useState(
    "interview" in consultant ? consultant.interview.privateNotes : ""
  );
  const [scoreLevel, setScoreLevel] = useState<number | null>(
    "interview" in consultant ? consultant.interview.score : null
  );
  const [previousConsultant, setPreviousConsultant] = useState<ConsultantMeeting | ConsultantNoMeeting | null>(null);
  const [editMode, setEditMode] = useState(!viewModeAvailable);
  const [message, setMessage] = useState<string | null>(null);
  const [toastColor, setToastColor] = useState<
    "danger" | "success" | "neutral" | "primary" | "warning"
  >("danger");
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  

  useEffect(() => {
    if(isUpdated && previousConsultant){
      handleClickSave(previousConsultant);
    }
    setIsUpdated(false)

    setEditMode(!viewModeAvailable);
    setPrivateNotes(
      "interview" in consultant ? consultant.interview.privateNotes : ""
    );
    setPublicNotes(
      "interview" in consultant ? consultant.interview.publicNotes : ""
    );
    setScoreLevel(
      "interview" in consultant ? consultant.interview.score : null
    );

    setPreviousConsultant(consultant);

  }, [consultant, viewModeAvailable]);

  
  useEffect(() => {
    const checkUpdate: boolean = "interview" in consultant 
      ? privateNotes !== consultant.interview.privateNotes ||
        publicNotes !== consultant.interview.publicNotes ||
        scoreLevel !== consultant.interview.score 
      : privateNotes !== "" ||
        publicNotes !== "" ||
        scoreLevel !== null;
    setIsUpdated(checkUpdate)
    
  }, [privateNotes, publicNotes, scoreLevel]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleClickSave = async (saveConsultant: ConsultantMeeting | ConsultantNoMeeting) => {
    if ("interview" in saveConsultant) {
      handleSave(saveConsultant);
    } else {
      createNewInterviewWithoutTime(saveConsultant);
    }
    if (viewModeAvailable) toggleEditMode();
    setIsUpdated(false);
  };

  function isConsultantMeeting(
    consultant: any
  ): consultant is ConsultantMeeting {
    return "interview" in consultant;
  }

  const handleSave = async (consultant: ConsultantMeeting | ConsultantNoMeeting) => {
    if ("interview" in consultant) {
      if (viewModeAvailable) toggleEditMode();

      const updatedInterview = {
        interviewId: consultant.interview.interviewId,
        publicNotes: publicNotes,
        privateNotes: privateNotes,
        score: scoreLevel,
      };

      if (setConsultants) {
        // Update the interview in the local state
        const updatedConsultants = consultants?.map((c) =>
          c.consultantId === consultant.consultantId && isConsultantMeeting(c)
            ? {
                ...c,
                interview: {
                  ...c.interview,
                  ...updatedInterview,
                },
              }
            : c
        );
        if (updatedConsultants) {
          setConsultants(updatedConsultants);
        }
        await updateInterview(
          consultant.interview.interviewId,
          updatedInterview
        )
          .then((response) => {
            setToastColor("success");
            setMessage("Endringene er lagret!");
          })
          .catch((error) => {
            console.error("Error:", error);
            setToastColor("danger");
            setMessage(error.message);
          });
      }
    }
  };

  const createNewInterviewWithoutTime = async (consultant: ConsultantNoMeeting) => {
    if (customerId) {
      const newInterviewWithoutTime = {
        score: scoreLevel,
        publicNotes: publicNotes,
        privateNotes: privateNotes,
        consultantId: consultant.consultantId,
        customerId: customerId,
      };

      const response = await createInterviewWithoutTime(
        newInterviewWithoutTime
      );

      if (setConsultants) {
        let allConsultants = consultants
          ? [...response, ...consultants]
          : response;
        allConsultants = sortByKey<ConsultantMeeting | ConsultantNoMeeting>(
          allConsultants,
          "consultantName"
        );
        setConsultants(
          removeDuplicates<ConsultantMeeting | ConsultantNoMeeting>(
            allConsultants,
            "consultantId"
          )
        );
      }
    }
  };

  const getStyledHeader = (text: string) => {
    return <h3 className="pb-2 font-bold">{text}</h3>;
  };

  return (
    <div className="flex flex-col space-y-4 w-full">
      <div className="flex flex-row justify-between w-full">
        {editMode ? (
          <div>
            {getStyledHeader("Gi din score til konsulenten")}
            <ScoreIndicator
              setScoreLevel={setScoreLevel}
              scoreLevel={scoreLevel}
            />
            <small>Du kan endre scoren senere</small>
          </div>
        ) : (
          <div>
            {getStyledHeader("Din score til konsulenten")}
            {scoreLevel && (
              <ScoreCircle
                number={scoreLevel}
                clicked={true}
                textSize="text-4xl font-normal"
                // customSize="w-14 h-14"
                disabled
              />
            )}
            {!scoreLevel && <p>Ingen score</p>}
          </div>
        )}
        <div className="w-1/6 h-5">
          <Button
            onClick={editMode ? () => handleClickSave(consultant) : toggleEditMode}
            text={editMode ? "Lagre" : "Rediger"}
            lowercase
          />
        </div>
      </div>

      <div>
        {getStyledHeader("Egne notater")}
        {editMode ? (
          <RichTextEditor
            key={consultant.consultantId}
            content={privateNotes}
            onUpdate={setPrivateNotes}
            placeholder="Skriv dine notater her.."
          ></RichTextEditor>
        ) : (
          <div
            className=" "
            dangerouslySetInnerHTML={{
              __html: privateNotes
                ? privateNotes === "<p><br></p>"
                  ? "Ingen notater"
                  : privateNotes
                : "Ingen notater",
            }}
          />
        )}
      </div>

      <div className="">
        {getStyledHeader("Notater til Experis")}
        {editMode ? (
          <RichTextEditor
            key={consultant.consultantId}
            content={publicNotes}
            onUpdate={setPublicNotes}
            placeholder="Skriv kommentarer til Experis her.."
          ></RichTextEditor>
        ) : (
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: publicNotes
                ? publicNotes === "<p><br></p>"
                  ? "Ingen notater"
                  : publicNotes
                : "Ingen notater",
            }}
          />
        )}
      </div>

      {message && (
        <SnackBarCustom
          color={toastColor}
          message={message}
          onClose={() => setMessage(null)}
          align="bottom-center"
        />
      )}
    </div>
  );
};
export default ConsultantFeedback;
