import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { IoIosWarning } from "react-icons/io";
import { MdOutlineDeleteForever } from "react-icons/md";

interface DialogProps {
    onConfirm: () => void;
    option: string;
    option2?: string;
    buttonText: string;
    dialogText: string;
    triggerComponent: React.ReactElement;
    children?: React.ReactElement;
}

const DialogCustom: React.FC<DialogProps> = ({ dialogText, buttonText, option, onConfirm, option2 = "Avbryt", triggerComponent, children }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            {React.cloneElement(triggerComponent, { onClick: () => setOpen(true) })}
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <IoIosWarning />
                        {buttonText}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        {children}
                        {dialogText}
                    </DialogContent>
                    {option &&
                        <DialogActions>
                            <Button variant="solid" color="danger" onClick={onConfirm}>
                                {option}
                            </Button>
                            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
                                {option2}
                            </Button>
                        </DialogActions>}
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}

export default DialogCustom;
