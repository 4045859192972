import React, { useState, useEffect } from "react";
import { handleRemoveAdmin, handleRemoveConsultant, handleRemoveCustomer } from "../../../utils/ManageUserHandlers";
import { selectSelectedAdmin } from "../../../redux/slices/SelectedAdminSlice";
import { selectSelectedCustomer } from "../../../redux/slices/SelectedCustomerSlice";
import { useSelector } from "react-redux";
import { selectSelectedConsultant } from "../../../redux/slices/SelectedConsulentSlice";

interface DeleteFormProps {
  onSave?: () => void;
  filter: string;
}

const ConfirmDelete: React.FC<DeleteFormProps> = ({ onSave, filter }) => {
  const selectedAdmin = useSelector(selectSelectedAdmin);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const selectedConsultant = useSelector(selectSelectedConsultant);
  const [userOutput, setUserOutput] = useState<string>("brukeren");
  const adminId = selectedAdmin?.adminId || 0;
  const customerId = selectedCustomer?.customerId || 0;
  const consultantId = selectedConsultant?.consultantId || 0;

  const handleConfirm = async () => {
    switch (filter) {
      case "customer": handleRemoveCustomer(customerId); break;
      case "admin": handleRemoveAdmin(adminId); break;
      case "consultant": handleRemoveConsultant(consultantId); break;
    }
    onSave?.();
  };

  useEffect(() => {
    switch (filter) {
      case "customer": setUserOutput("kunden " + selectedCustomer?.customerName); break;
      case "admin": setUserOutput("admin " + selectedAdmin?.adminName); break;
      case "consultant": setUserOutput("konsulenten " + selectedConsultant?.consultantName); break;
    }
  }, [selectedCustomer, selectedAdmin, selectedConsultant, filter]);

  return (
    <div>
    <div className="flex inset-0 z-50 sm:mx-8 overflow-y-auto items-end justify-center text-center sm:items-center">

        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
          <div className="bg-white px-4">
            <div className="sm:flex sm:items-start">
          
              <div className="mt-3 text-center sm:ml-4 -translate-y-3 sm:mt-0 sm:text-left">
            
                <div className="mt-2">
                  <p>
                    Vil du slette {userOutput}? 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-10">
            <button
              className="inline-flex w-full justify-center rounded-md bg-brandRed px-3 py-2 font-semibold text-white shadow-sm hover:bg-[#8b2c2cd0]"
              onClick={handleConfirm}
           >
              Slett
            </button>
       
          </div>
        </div>
    </div>
  </div>
  );
};

export default ConfirmDelete;







     


