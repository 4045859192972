import React, { useState } from 'react';
import { PropsAdminScheduleToSelectCustomerConsultants, SelectCustomerConsultantUnit as Unit } from '../../../types/adminTypes';
import AllocationRow from './AllocationGridRow';
import ResponsiveScrollContainer from '../../common/ResponsiveScrollContainer';

// Define the props for the AllocationGrid component
interface AllocationGridProps {
    props: PropsAdminScheduleToSelectCustomerConsultants;
}

// Main AllocationGrid component
const AllocationGrid: React.FC<AllocationGridProps> = ({ props }) => {
    //const categories = props.Consultants.map((consultant) => consultant.speciality);

    const categories = Array.from(new Set(props.Consultants.map((consultant) => consultant.speciality)))
                      .filter(category => category !== null && category.trim() !== '');
    
    // State to track collapsed categories
    const [collapsedCategories, setCollapsedCategories] = useState<Record<string, boolean>>(
        categories.reduce(
            (acc, category) => ({
                ...acc,
                [category]: true,
            }),
            {}
        )
    );

    // Map consultants to categories
    const consultantsByCategory: Record<string, Unit[]> = {};
    props.Consultants.forEach((consultant) => {
        const category = categories.find((cat) => consultant.speciality === cat);
        if (category) {
            // Initialize array if not present, then push the consultant
            consultantsByCategory[category] = consultantsByCategory[category] || [];
            consultantsByCategory[category].push(consultant);
        }
    });

    // Toggle function for collapsing/expanding categories
    const toggleCategory = (category: string) => {
        setCollapsedCategories((prevCollapsed) => ({
            ...prevCollapsed,
            [category]: !prevCollapsed[category],
        }));
    };

    const renderTableHeader = () => (
        <thead className='sticky top-0 z-20'>
            <tr>
                <th className='text-lg text-center capitalize font-lato rounded-tl-md border-b-2 border-b-white sticky left-0'>Firmaer</th>
                {props.Customers.map((customer, index) => (
                    <th key={index} scope='col' className='border-b-2 border-b-white last:rounded-tr-md'>
                        <p className=' capitalize font-lato text-sm font-normal text-justify'>{customer.name}</p>
                        {/* <p className=' font-lato'>({customer.speciality})</p> */}
                    </th>
                ))}
            </tr>
        </thead>
    );

    // Function to render the table body
    const renderTableBody = () => (
        <tbody className=' divide-y-4 divide-white '>
            {categories.map((category) => (
                <AllocationRow
                    key={category}
                    category={category}
                    consultants={consultantsByCategory[category] || []}
                    customers={props.Customers}
                    collapsed={collapsedCategories}
                    onToggleCategory={toggleCategory}
                    onInterviewUpdate={props.onInterviewUpdate}
                    allInterviews={props.allInterviews}
                />
            ))}
        </tbody>
    );

    const ScrollableBox = {
        mb: 2,
        display: "flex",
        flexDirection: "column",
        height: 550,
        overflow: "hidden",
        overflowY: "scroll",
        minWidth: "100%",
    };
    // Render the main component
    return (
        <div className='overflow-auto overflow-x-auto mt-4'>
            <ResponsiveScrollContainer>
                <table>
                    {renderTableHeader()}
                    {renderTableBody()}
                </table></ResponsiveScrollContainer>
        </div>
    );
};

export default AllocationGrid;
