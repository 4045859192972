import React from "react";
import { Button } from "../common/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import { customTheme } from "../../utils/customDrawer.js";
import Drawer from "@mui/joy/Drawer";
import BasicDrawer from "../common/BasicDrawer";
import { Sheet } from "@mui/joy";

interface CvDrawerProps {
  consultant: any;
  blobUrl: string | null;
  onClose: () => void;
  isOpen: boolean;
  width?: string;
}

const CvDrawer: React.FC<CvDrawerProps> = ({ consultant, blobUrl, onClose, isOpen, width }) => {
  return (

    <BasicDrawer title={consultant.consultantName + "'s CV"} onClose={onClose} isOpen={isOpen} width={width} >
      <>
        {blobUrl && <iframe src={`${blobUrl}#toolbar=0`} title="CV Viewer" width="100%" height="100%" />}
      </>
    </BasicDrawer>
  );
};

export default CvDrawer;
