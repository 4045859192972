import {
  ConsultantFilterPropsAdmin, SortMethod
} from "../../../types/filterTypes";
import FilterField from "../../common/FilterField";

const ConsultantFilterAdmin: React.FC<ConsultantFilterPropsAdmin> = ({
  sortOrder,
  sortKey,
  onFilterChange,
}) => {
  const options: SortMethod[] = [
    {attribute: "consultantName", order: "asc", text: "Navn: A - Å" },
    {attribute: "consultantName", order: "desc", text: "Navn: Å - A" },
    {attribute: "score", order: "desc", text:"Score: Høyest - Lavest"},
    {attribute: "score", order: "asc", text:"Score: Lavest - Høyest"},
    {attribute: "progress", order: "asc", text:"Profil: Ikke ferdig - Ferdig"}
  ]

  return (
    <div className="flex flex-col ">
      {options.map(option => 
        <FilterField 
          active={option.attribute === sortKey && option.order === sortOrder} 
          onClick={() => onFilterChange(option.attribute, option.order)}
          text={option.text}/>
      )}
    </div>
  );
};

export default ConsultantFilterAdmin;
