import React from "react";
import LoginForm from "../../components/common/LoginForm";
import ExperisLogoBlue from "../../assets/ExperisLogoBlue.svg";

/**
 * LoginPage Component
 *
 * This component renders a login page with a login form.
 * It consists of a flexible layout with a logo section and a login form section.
 *
 * @component
 */
const LoginPage: React.FC = () => {
  return (
    <div className="flex h-screen bottom-0 w-full">
      <div className="w-1/3 h-full hidden md:block ">
        <img
          src={ExperisLogoBlue}
          alt="Experis logo used for decoration"
          className="cover h-full"
        />
      </div>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
