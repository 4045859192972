import { Radio, RadioGroup } from "@mui/joy";
import {
  ConsultantMeeting,
  ConsultantNoMeeting,
} from "../../types/consultantTypes";
import { useEffect, useState } from "react";
import { GetConsultantsNoMeeting } from "../../api/customerApi";
import { removeDuplicates } from "../../utils/ArrayHelper";

interface ConsultantRadioFilterProps {
  setConsultants: (
    consultant: (ConsultantMeeting | ConsultantNoMeeting)[]
  ) => void;
  consultants: (ConsultantMeeting | ConsultantNoMeeting)[];
  customerId: number;
}

const ConsultantRadioFilter: React.FC<ConsultantRadioFilterProps> = ({
  setConsultants,
  consultants,
  customerId,
}) => {
  const [consultantsNoMeeting, setConsultantsNoMeeting] = useState<
    ConsultantNoMeeting[]
  >([]);

  useEffect(() => {
    FetchConsultantsNoMeeting(customerId);
  }, [customerId]);

  const FetchConsultantsNoMeeting = async (customerId: number) => {
    const responseNoMeeting = await GetConsultantsNoMeeting(customerId);
    setConsultantsNoMeeting(responseNoMeeting);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedRadio = event.target.value;
    if (selectedRadio === "AllConsultants") {
      setConsultants(removeDuplicates<(ConsultantMeeting|ConsultantNoMeeting)>([...consultants,...consultantsNoMeeting], "consultantId"));
    }
    if (selectedRadio === "ConsultantMeeting") {
      setConsultants(findConsultantsMeeting(consultants));
    }
  };

  const findConsultantsMeeting = (
    arr: (ConsultantMeeting | ConsultantNoMeeting)[]
  ): ConsultantMeeting[] => {
    return arr.filter((c): c is ConsultantMeeting => "interview" in c);
  };

  return (
    <RadioGroup
      defaultValue="ConsultantMeeting"
      onChange={handleRadioChange}
      orientation="horizontal"
      className=" text-base font-semibold font-lato gap-6 pb-8 break-all"
    >
      <Radio
        value="ConsultantMeeting"
        label="Vis dine intervjukandidater"
        color="neutral"
        variant="soft"
        size="md"
      />
      <Radio
        value="AllConsultants"
        label="Vis alle intervjukandidater"
        color="neutral"
        variant="soft"
        size="md"
      />
    </RadioGroup>
  );
};

export default ConsultantRadioFilter;
