import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { extractRoleFromJWT } from "./JwtExtraction";
import { RootState } from "../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
interface AuthGuardProps {
  children: ReactNode;
  acceptedRoles?: string[];
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children, acceptedRoles }) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const token = useSelector((state: RootState) => state.auth.accessToken);

  // Extract user roles
  const userRole: string = extractRoleFromJWT(token);

  useEffect(() => {
    // Additional logic to check roles
    const hasAcceptedRoles =
      acceptedRoles && acceptedRoles.some((role) => userRole.includes(role));
    if (!isAuthenticated || (acceptedRoles && !hasAcceptedRoles)) {
      // Redirect if not authenticated or doesn't have required roles
      if (userRole === "Admin") {
        navigate("/konsulenter");
      } else if (userRole === "Customer") {
        navigate("/agenda");
      } else if (userRole === "Consultant") {
        navigate("/profil");
      } else {
        navigate("/");
      }
      //redirect consultant to profile page
      //redirect admin to overview page
    }
  }, [isAuthenticated, acceptedRoles, userRole, navigate]);

  return <>{children}</>;
};

export default AuthGuard;
