import { SortOrder } from "../utils/SortingUtils";
import { ConsultantDetails as ConsultantAdmin } from "../types/consultantTypes";
import { ConsultantMeeting as ConsultantCustomer } from "../types/consultantTypes";

export interface SortMethod{
  attribute: keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress";
  order: SortOrder;
  text: string;
}

export interface ConsultantFilterPropsCustomer {
  sortKey: keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress";
  sortOrder: SortOrder;
  onFilterChange: (_sortKey: keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress", _sortOrder: SortOrder) => void;
}

export interface ConsultantFilterPropsAdmin {
  sortKey: keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress";
  sortOrder: SortOrder;
  onFilterChange: (_sortKey: keyof (ConsultantAdmin | ConsultantCustomer) | "score" | "progress", _sortOrder: SortOrder) => void;
}

export interface ConsultantFilterProps {
  activeFilter: Record<string, boolean>;
  onFilterChange: (specialization: SpecializationFilter) => void;
}
export const specializationFilters = [".NET", "Java", "UX/UI"] as const; //, "UX/UI"
export type SpecializationFilter = typeof specializationFilters[number];

