export interface ScoreLevel {
  score: number;
  tooltipContent: string;
  color: string;
}

export const scoreLevels: ScoreLevel[] = [
  {
    score: 1,
    tooltipContent:
      "Svakt intervju",
    color: "bg-score-one border-score-one text-brandWhite",
  },
  {
    score: 2,
    tooltipContent:
      "Dårlig match",
    color: "bg-score-two border-score-two text-brandBlack",
  },
  {
    score: 3,
    tooltipContent:
      "Ønsker ikke videre intervju",
    color: "bg-score-three border-score-three text-brandBlack",
  },
  {
    score: 4,
    tooltipContent:
      "Ønsker videre intervju",
    color: "bg-score-four border-score-four text-brandBlack",
  },
  {
    score: 5,
    tooltipContent:
      "En av favorittene",
    color: "bg-score-five border-score-five text-brandWhite",
  },
];
