import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/experis_academy_logo.png";
import { extractRoleFromJWT } from "../../utils/JwtExtraction";
import NavLink from "./NavLink";
import { MdMenu, MdMenuOpen } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";

export const Navbar: React.FC = (props) => {
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const navigate = useNavigate();
  const userRole: string = extractRoleFromJWT(token);
  const dispatch = useDispatch();

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    // Clear session storage and redirect to the login page
    dispatch(logout());
    sessionStorage.clear();
    navigate("/");
  };

  //   if (!userRole) {
  //     return null;
  //   }

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  //remove "dev" from roles before prod
  const links = [
    {
      path: "/agenda",
      text: "Agenda",
      roles: ["Customer", "dev"],
    },
    { path: "/profil", text: "Profil", roles: ["Consultant", "dev"] },
    {
      path: "/konsulenter",
      text: "Konsulenter",
      roles: ["Admin", "Customer", "dev"],
    },
    {
      path: "/scoringsskjema",
      text: "Scoringsskjema",
      roles: ["Admin", "dev"],
    },
    {
      path: "/administrer-brukere",
      text: "Administrer Brukere",
      roles: ["Admin", "dev"],
    },
    { path: "/tidsskjema", text: "Tidsskjema", roles: ["Admin", "dev"] },
    {
      path: "/personvern",
      text: "Personvern",
      roles: ["Customer", "Consultant", "Admin", "dev"],
    },
  ];

  // Replace this to view the navbar as a different user
  let devUserRole = "dev";

  // Replace devUserRole with userRole in prod
  const filteredLinks = links.filter((link) =>
    link.roles.includes(userRole)
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);


  return (
    <nav className="bg-brandBlueDark fixed inset-x-0 top-0 shadow-md text-white">
      <div className="flex items-center justify-between h-20">
        <Link to="/" className="flex items-center space-x-3 px-6">
          <img
            src={logo}
            className=" fixed px-6 h-[50px] w-auto"
            alt="Experis Logo"
          />
        </Link>

        {/* Navbar for larger screens */}
        <ul className="hidden md:flex space-x-4 px-6 slide-in-links">
          {filteredLinks.map((link) => (
            <li key={link.text}>
              <NavLink to={link.path} text={link.text} />
            </li>
          ))}
          <li>
            {token && (
              <button
              onClick={handleLogout}
              className="hover:opacity-100 opacity-75"
            >
              Logg ut
            </button>
            )}
          </li>
        </ul>

        {/* Burger menu button for smaller screens */}
        {token && (
          <button
          ref={buttonRef}
          onClick={handleToggleMenu}
          className=" md:hidden px-6 "
        >
          {isMenuOpen ? (
            <MdMenuOpen fontSize="large" />
          ) : (
            <MdMenu fontSize="large" />
          )}
        </button>
        )}
        
      </div>

      {/* Dropdown menu for smaller screens */}
      {isMenuOpen && (
        <div
          ref={menuRef}
          className={`animate-slideDown md:hidden absolute top-full right-0 bg-brandBlueDark py-2 rounded-b shadow-lg w-50`}
        >
          <ul className="flex flex-col text-center items-center space-y-4 p-6">
            {filteredLinks.map((link) => (
              <li onClick={handleToggleMenu} key={link.text}>
                <NavLink to={link.path} text={link.text} />
              </li>
            ))}
            <li onClick={handleToggleMenu}>
              <button
                onClick={handleLogout}
                className="hover:opacity-100 opacity-75"
              >
                Logg ut
              </button>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};
